/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import ReactDOM from "react-dom"
import { useTheme } from "../theme"

export default function ProcessingOverlay({
  title = "Processing your payment"
}) {
  const { theme } = useTheme()

  return ReactDOM.createPortal(
    <>
      <div
        css={{
          zIndex: 9000,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.9,
          backgroundColor: theme.backgroundColor
        }}
      />
      <div
        css={{
          zIndex: 9001,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
        <div
          css={{
            maxWidth: "34rem",
            padding: "2rem",
            borderRadius: 3,
            textAlign: "center",
            border: `1px solid ${theme.borderColor}`,
            backgroundColor: theme.foregroundColor
          }}>
          <h2>{title}</h2>
          <p>Please don’t leave or reload this page...</p>
        </div>
      </div>
    </>,
    document.body
  )
}
