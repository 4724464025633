/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import moment from "moment"
import uniq from "lodash/uniq"
import * as print from "print-html-element"
import Button from "../components/Button"
import { functions } from "../firebase"
import { getGroupInvoiceData } from "../utils/getGroupInvoiceData"
import {
  displayMoney,
  displayDateRange,
  displayAddress,
  displayFullName,
  displayDate,
  displayPaymentMethod,
  displayName
} from "../utils/format"
import { useStore } from "../store"
import { useTheme } from "../theme"

export function InvoiceLineItems({
  tour,
  registrant,
  addons = [],
  extensions = [],
  registrants = [],
  groupInvoiceData
}) {
  return (
    <>
      <tr>
        <td>
          <div className="num-lines-2">{tour.title}</div>
          <small>
            {displayDateRange(
              registrant.tour_start_date,
              registrant.tour_end_date
            )}
          </small>
        </td>
        <td>{Object.values(groupInvoiceData.tourItems).join("\n")}</td>
        <td>
          {Object.keys(groupInvoiceData.tourItems).map(displayMoney).join("\n")}
        </td>
        <td>{displayMoney(groupInvoiceData.tourTotal)}</td>
      </tr>
      {registrants.find(x => x.extension_id) ? (
        <tr>
          <td>
            {uniq(registrants.map(x => x.extension_id))
              .map(id => extensions.find(x => x.id == id)?.title || "Extension")
              .join(", ")}
          </td>
          <td>{Object.values(groupInvoiceData.extensionItems).join("\n")}</td>
          <td>
            {Object.keys(groupInvoiceData.extensionItems)
              .map(displayMoney)
              .join("\n")}
          </td>
          <td>{displayMoney(groupInvoiceData.extensionTotal)}</td>
        </tr>
      ) : null}
      {groupInvoiceData.earlyDiscount ? (
        <tr>
          <td>Early Registration Discount</td>
          <td>
            {Object.values(groupInvoiceData.earlyDiscountItems).join("\n")}
          </td>
          <td>
            -
            {Object.keys(groupInvoiceData.earlyDiscountItems)
              .map(displayMoney)
              .join("\n")}
          </td>
          <td>-{displayMoney(groupInvoiceData.earlyDiscount)}</td>
        </tr>
      ) : null}
      {registrants.some(x => x.supplement_yes) ? (
        <tr>
          <td>Single Room Supplement</td>
          <td>{Object.values(groupInvoiceData.supplementItems).join("\n")}</td>
          <td>
            {Object.keys(groupInvoiceData.supplementItems)
              .map(displayMoney)
              .join("\n")}
          </td>
          <td>{displayMoney(groupInvoiceData.supplementTotal)}</td>
        </tr>
      ) : null}
      {addons.map(addon => (
        <tr>
          <td colSpan={3}>
            {addon.description} for{" "}
            {displayName(registrants.find(x => x.id == addon.registrant_id))}
          </td>
          <td>{displayMoney(addon.price)}</td>
        </tr>
      ))}
      {registrants.some(x => x.insurance_yes) && tour.insurance_id ? (
        <tr>
          <td>
            Travel Insurance
            <br />
            <small>(on Tour, Extensions, and Additional Charges)</small>
          </td>
          <td>{Object.values(groupInvoiceData.insuranceItems).join("\n")}</td>
          <td>
            {Object.keys(groupInvoiceData.insuranceItems)
              .map(displayMoney)
              .join("\n")}
          </td>
          <td>{displayMoney(groupInvoiceData.insuranceTotal)}</td>
        </tr>
      ) : null}
    </>
  )
}

export default function InvoiceForm({
  tour,
  insurance,
  extensions,
  registrant,
  subregistrants,
  addons,
  payments,
  toPayment
}) {
  const { isAdmin } = useStore()
  const { theme } = useTheme()

  const [sendingEmail, setSendingEmail] = React.useState(false)
  const printRef = React.useRef()

  const registrants = [registrant, ...subregistrants]
  const groupInvoiceData = getGroupInvoiceData(
    registrants,
    tour,
    insurance,
    extensions,
    addons,
    payments
  )

  return (
    <>
      <header
        css={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between"
        }}>
        <h2>Invoice</h2>
        <div>
          <Button
            kind="outline"
            size="small"
            type="button"
            onClick={async () => {
              // prettier-ignore
              if (!window.confirm(`Are you sure you want to send this invoice as an email reminder to ${registrant.email}?`)) {
                return
              }
              try {
                setSendingEmail(true)
                const sendEmail = functions.httpsCallable("sendInvoiceEmail")
                await sendEmail({ id: registrant.id })
                window.alert(`Invoice email was sent to ${registrant.email}!`)
              } catch (error) {
                window.alert("Unable to send email")
              } finally {
                setSendingEmail(false)
              }
            }}>
            {sendingEmail ? "Sending..." : "Email"}
          </Button>
          &nbsp;
          <Button
            kind="outline"
            size="small"
            onClick={() => {
              print.printElement(printRef.current)
            }}>
            Print
          </Button>
          &nbsp;
          <Button size="small" kind="fill" to={toPayment}>
            {isAdmin ? "Log Payment" : "Make Payment"}
          </Button>
        </div>
      </header>
      <div
        ref={printRef}
        css={{
          borderRadius: 3,
          border: "1px solid " + theme.accentText,
          backgroundColor: theme.foregroundColor
        }}>
        <div
          css={{
            zoom: 0.8,
            "@media (max-width: 800px)": {
              zoom: 0.5
            },
            "@media print": {
              zoom: 1
            }
          }}>
          <header
            css={{
              padding: "1rem 1.5rem",
              backgroundImage: `url(${theme.patternImage})`,
              backgroundSize: "cover",
              borderBottom: `1px solid ${theme.borderColor}`
            }}>
            <br />
            <h2>Invoice from Faith Based Expeditions</h2>
            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
                maxWidth: 800
              }}>
              <div>
                <h6 css={{ margin: 0 }}>Due</h6>
                <small>{displayDate(groupInvoiceData.dueDate)}</small>
              </div>
              <div>
                <h6 css={{ margin: 0 }}>Billed To</h6>
                <small css={{ whiteSpace: "pre" }}>
                  {displayFullName(registrant)}
                  <br />
                  {displayAddress(registrant)}
                </small>
              </div>
              <div>
                <h6 css={{ margin: 0 }}>Send Checks To</h6>
                <small css={{ whiteSpace: "nowrap" }}>
                  Faith Based Expeditions
                  <br />
                  1740 Hudson Bridge Road, PMB 1053
                  <br />
                  Stockbridge, GA 30281
                </small>
              </div>
            </div>
          </header>
          <table
            css={{
              margin: 0,
              width: "100%",
              padding: "1rem"
            }}>
            <thead>
              <tr>
                <th>Description</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody
              css={{
                "td:not(:first-of-type)": {
                  whiteSpace: "pre"
                }
              }}>
              <InvoiceLineItems
                tour={tour}
                addons={addons}
                extensions={extensions}
                registrant={registrant}
                registrants={registrants}
                groupInvoiceData={groupInvoiceData}
              />
              <tr>
                <td colSpan={4}>
                  <hr />
                </td>
              </tr>
              {groupInvoiceData.processingFee ? (
                <tr>
                  <td colSpan={3}>
                    <h6 css={{ margin: 0, textAlign: "right" }}>
                      Processing Fees
                    </h6>
                  </td>
                  <td>{displayMoney(groupInvoiceData.processingFee)}</td>
                </tr>
              ) : null}
              {groupInvoiceData.lateFee ? (
                <tr>
                  <td colSpan={3}>
                    <h6 css={{ margin: 0, textAlign: "right" }}>Late Fees</h6>
                  </td>
                  <td>{displayMoney(groupInvoiceData.lateFee)}</td>
                </tr>
              ) : null}
              <tr>
                <td colSpan={3}>
                  <h6 css={{ margin: 0, textAlign: "right" }}>Total</h6>
                </td>
                <td>{displayMoney(groupInvoiceData.totalDue)}</td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <hr />
                </td>
              </tr>
              <tr>
                <th colSpan={4}>Payments</th>
              </tr>
              {payments.map(payment => (
                <tr key={payment.id}>
                  <td colSpan={3} css={{ textTransform: "capitalize" }}>
                    {displayPaymentMethod(payment.method)} Payment&mdash;
                    {moment.utc(payment.created).format("lll")}
                  </td>
                  <td>{displayMoney(payment.amount)}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={4}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td colSpan={2} />
                <td>
                  <h6 css={{ margin: 0 }}>Paid</h6>
                </td>
                <td>{displayMoney(groupInvoiceData.totalPaid)}</td>
              </tr>
              <tr>
                <td colSpan={2} />
                <td>
                  <h6 css={{ margin: 0 }}>Due</h6>
                </td>
                <td>{displayMoney(groupInvoiceData.totalRemaining)}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4}></td>
              </tr>
              <tr>
                <td>
                  <small>
                    Tour balance is due in our office no later than{" "}
                    {displayDate(groupInvoiceData.dueDate)} to avoid a late
                    payment fee of $95 per person. Payments can be made via -
                    personal check, money order, bank draft, direct bank wire
                    (please add $20 to any direct wires), or by credit card
                    {tour.processing_fee_disabled
                      ? "."
                      : "(a 2.5% Processing Fee will be applied to card payments)."}
                  </small>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  )
}
