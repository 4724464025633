/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "../theme"

export default function Radio({ children, ...props }) {
  const { theme } = useTheme()

  return (
    <label
      css={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        margin: "0.5rem 0",
        opacity: props.disabled ? 0.5 : 1
      }}>
      <input
        {...props}
        type="radio"
        css={{
          position: "absolute",
          opacity: 0.00000001
        }}
      />
      <div
        css={{
          flexGrow: 0,
          flexShrink: 0,
          position: "relative",
          display: "inline-block",
          width: "1.25rem",
          height: "1.25rem",
          marginRight: "0.5rem",
          borderRadius: "50%",
          border: "1px solid " + theme.borderColor,
          "input:active + &": {
            background: theme.underlayColor
          },
          "input:checked + &::after": {
            position: "absolute",
            content: '""',
            top: "50%",
            left: "50%",
            width: "0.75rem",
            height: "0.75rem",
            borderRadius: "50%",
            transform: "translate3d(-50%,-50%,0)",
            background: theme.accentText
          }
        }}
      />
      <div>{children}</div>
    </label>
  )
}
