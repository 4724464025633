import React from "react"
import Input from "./Input"
import Field from "./Field"

export default function SignatureInput(props) {
  return (
    <Field label="Signature">
      <Input required name="terms_signature" {...props} />
      <small>
        Please enter your full name to indicate to indicate your agreement to
        the{" "}
        <a
          href="https://faithbasedexpeditions.com/terms"
          rel="noopener noreferrer"
          target="_blank">
          Terms and Cancelation Policies.
        </a>
      </small>
    </Field>
  )
}
