/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import TextareaAutosize from "react-textarea-autosize"
import { useTheme } from "../theme"

export default function Textarea(props) {
  const { theme } = useTheme()

  return (
    <TextareaAutosize
      minRows={2}
      css={{
        width: "100%",
        padding: "0.5rem",
        display: "block",
        borderRadius: 3,
        fontSize: "1rem",
        fontFamily: "inherit",
        color: theme.primaryText,
        caretColor: theme.accentText,
        backgroundColor: theme.foregroundColor,
        border: "1px solid " + theme.borderColor,
        resize: "none",
        ":focus": {
          borderColor: theme.accentText
        },
        "::placeholder": {
          color: theme.hintText
        }
      }}
      {...props}
    />
  )
}
