import "@reach/menu-button/styles.css"

import React from "react"
import { Global, css } from "@emotion/core"
import { useTheme } from "../theme"
import Helmet from "react-helmet"

export const fonts = {
  ideal: `"Ideal Sans SSm A", "Ideal Sans SSm B", sans-serif`,
  knockout: `"Knockout 27 A", "Knockout 27 B", sans-serif`,
  surveyor: `"Surveyor Display A", "Surveyor Display B", serif`
}

export default function Styles() {
  const { theme } = useTheme()
  return (
    <>
      <Helmet>
        <meta name="theme-color" content={theme.backgroundColor} />
      </Helmet>
      <Global
        styles={css`
          *,
          *::before,
          *::after {
            box-sizing: border-box;
          }

          html {
            font-size: 16px;
            font-family: ${fonts.ideal};
            font-style: normal;
            font-weight: 400;
            color: ${theme.secondaryText};
            background-color: ${theme.backgroundColor};
          }

          body {
            margin: 0;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${theme.primaryText};
            margin: 1rem 0;
            line-height: 1.15;
          }

          h1 {
            font-size: 3rem;
            font-family: ${fonts.surveyor};
            font-weight: 500;
            @media (max-width: 600px) {
              font-size: 2.5rem;
            }
          }

          h2 {
            font-size: 2.25rem;
            font-family: ${fonts.surveyor};
            font-weight: 400;
          }

          h3 {
            font-size: 1.5rem;
            font-style: normal;
            font-weight: normal;
            font-family: ${fonts.knockout};
            text-transform: uppercase;
          }

          h4 {
            font-size: 1.25rem;
            font-style: normal;
            font-weight: normal;
            font-family: ${fonts.knockout};
            letter-spacing: 0.1rem;
            text-transform: uppercase;
          }

          h5 {
            font-size: 1rem;
            font-family: ${fonts.ideal};
          }

          h6 {
            font-size: 0.875rem;
            font-family: ${fonts.ideal};
          }

          p {
            font-size: 1rem;
          }

          small {
            font-size: 0.875rem;
          }

          ul,
          ol {
            padding-inline-start: 1rem;
          }

          hr {
            margin: 1rem 0;
            height: 1px;
            border: none;
            background: ${theme.borderColor};
          }

          a {
            color: ${theme.accentText};
          }

          svg {
            display: inline-block;
          }

          button svg {
            position: relative;
            top: 1px;
          }

          button {
            padding: 0;
            border: none;
            appearance: none;
            background: none;
            font-size: 1rem;
            color: inherit;
            cursor: pointer;
          }

          /* forms */

          table {
            margin: -0.5rem;
            border-spacing: 0;
            border-color: none;
          }

          th {
            text-align: left;
            color: ${theme.primaryText};
            font-size: 0.875rem;
          }

          tr {
            vertical-align: baseline;
          }

          th,
          td {
            padding: 0.5rem;
          }

          fieldset {
            margin: 0;
            padding: 0;
            border: none;
          }

          /* helpers */

          .num-lines-1 {
            display: block;
            min-width: 1px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .num-lines-2 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          /* date picker styles */
          .DayPickerInput {
            display: block;
          }

          .DayPickerInput-OverlayWrapper {
            z-index: 10;
            @media (max-width: 600px) {
              z-index: 1000;
              position: fixed;
              top: 1rem;
              left: 4rem;
              right: 4rem;
            }
          }

          .DayPickerInput-Overlay {
            background-color: ${theme.foregroundColor};
            border: 1px solid ${theme.borderColor};
            @media (max-width: 600px) {
              width: 18rem;
              position: static;
              margin: 0 auto;
            }
          }

          .DayPicker-NavButton {
            width: 1rem;
            height: 1rem;
          }

          .DayPicker-Caption > div {
            font-size: 1rem;
            font-weight: bold;
            color: ${theme.primaryText};
          }

          .DayPicker-Weekday {
            color: ${theme.tertiaryText};
          }

          .DayPicker-Day {
            border-radius: 0;
          }

          .DayPicker-Day--today {
            color: ${theme.accentText};
          }

          .DayPicker:not(.DayPicker--interactionDisabled)
            .DayPicker-Day:not(.DayPicker-Day--disabled):not(
              .DayPicker-Day--selected
            ):not(.DayPicker-Day--outside):hover {
            background-color: ${theme.highlightColor};
            color: ${theme.primaryText};
          }

          .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
              .DayPicker-Day--outside
            ) {
            background-color: ${theme.accentText};
            color: ${theme.backgroundColor};
          }

          .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
              .DayPicker-Day--outside
            ):hover {
            background-color: ${theme.highlightColor};
            color: ${theme.primaryText};
          }

          [data-reach-menu-list] {
            margin-top: 1px;
            padding: 0.2rem 0;
            border-radius: 3px;
            border: 1px solid ${theme.accentText};
            background-color: ${theme.foregroundColor};
          }

          [data-reach-menu-item] {
            color: ${theme.primaryText};
            padding: 0.5rem 0.7rem;
          }

          [data-reach-menu-item][data-selected] {
            color: ${theme.backgroundColor};
            background-color: ${theme.accentText};
          }
        `}
      />
    </>
  )
}

export function getReactSelectStyles(theme) {
  return {
    styles: {
      control: provided => ({
        ...provided,
        backgroundColor: theme.foregroundColor,
        boxShadow: "none"
      }),
      placeholder: provided => ({
        ...provided,
        color: theme.borderColor
      }),
      dropdownIndicator: provided => ({
        ...provided,
        padding: "0.4rem"
      })
    },
    theme: provided => ({
      ...provided,
      borderRadius: 3,
      borderWidth: 1,
      colors: {
        ...provided.colors,
        // danger
        // dangerLight
        primary: theme.accentText,
        primary75: theme.accentText,
        primary50: theme.borderColor,
        primary25: theme.underlayColor,
        neutral0: theme.backgroundColor,
        neutral5: theme.foregroundColor,
        neutral10: theme.underlayColor,
        neutral20: theme.borderColor,
        neutral30: theme.borderColor,
        neutral40: theme.tertiaryText,
        neutral50: theme.tertiaryText,
        neutral60: theme.secondaryText,
        neutral70: theme.secondaryText,
        neutral80: theme.primaryText,
        neutral90: theme.primaryText
      }
    })
  }
}
