/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { useDropzone } from "react-dropzone"
import * as Icon from "react-feather"
import { useParams } from "react-router-dom"
import Button from "../components/Button"
import Tooltip from "../components/Tooltip"
import { storage } from "../firebase"
import { useStore } from "../store"
import { useTheme } from "../theme"

export default function TourDocuments({ tourId, showUpload }) {
  const { isAdmin } = useStore()
  const { theme } = useTheme()
  const params = useParams()

  if (!tourId) tourId = params.tourId

  const tourStorageRef = storage.ref(`/tours/${tourId}`)

  const [uploads, setUploads] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [uploading, setUploading] = React.useState(false)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: uploadDocument
  })

  React.useEffect(() => {
    loadDocuments()
  }, [])

  async function loadDocuments() {
    try {
      setLoading(true)
      const tourStorage = await tourStorageRef.listAll()
      for (const ref of tourStorage.items) {
        const url = await ref.getDownloadURL()
        setUploads(uploads => uploads.concat({ ref, url }))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function uploadDocument(files) {
    try {
      setUploading(true)
      for (const file of files) {
        const { ref } = await tourStorageRef.child(file.name).put(file)
        const url = await ref.getDownloadURL()
        setUploads(uploads => uploads.concat({ ref, url }))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setUploading(false)
    }
  }

  async function deleteDocument(ref) {
    // prettier-ignore
    if (!window.confirm(`Are you sure you want to remove this file?\n${ref.name}`)) {
      return
    }

    try {
      await ref.delete()
      setUploads(uploads => {
        return uploads.filter(x => x.ref !== ref)
      })
    } catch (error) {
      window.alert(error.message)
    }
  }

  if (loading) {
    return <p>Loading...</p>
  }

  return (
    <div css={{ maxWidth: "50rem" }}>
      {showUpload ? (
        <div
          {...getRootProps()}
          css={{
            margin: "1rem 0",
            padding: "2rem 1rem 1rem",
            textAlign: "center",
            borderRadius: 3,
            border: `1px dashed ${theme.borderColor}`
          }}>
          <input {...getInputProps()} />
          <Icon.FilePlus size="2rem" />
          {uploading ? (
            <p>Uploading ...</p>
          ) : isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag and drop files here, or click to select files.</p>
          )}
        </div>
      ) : null}
      <ul
        css={{
          margin: 0,
          padding: 0,
          listStyle: "none"
        }}>
        {uploads.map(upload => (
          <li
            key={upload.url}
            css={{
              position: "relative",
              padding: "0.5rem",
              display: "flex",
              alignItems: "center"
            }}>
            <a
              target="_blank"
              href={upload.url}
              download={upload.ref.name}
              css={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: 3,
                border: `1px solid ${theme.borderColor}`,
                "@media (hover: hover)": {
                  "&:not(:hover)": {
                    borderColor: "transparent"
                  }
                }
              }}
            />
            {upload.ref.name.match(/.png|.jpg|.jpeg|.gif/) ? (
              <img
                src={upload.url}
                style={{
                  flexShrink: 0,
                  width: "3rem",
                  height: "auto",
                  objectFit: "contain",
                  borderRadius: 2
                }}
              />
            ) : (
              <div
                css={{
                  flexShrink: 0,
                  width: "3rem",
                  height: "4rem",
                  display: "flex",
                  padding: "0.5rem",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: 2,
                  textTransform: "uppercase",
                  backgroundColor: theme.foregroundColor,
                  border: `1px solid ${theme.borderColor}`
                }}>
                <Icon.File size="1rem" />
                {upload.ref.name.includes(".") && upload.ref.name.length < 4 && (
                  <div
                    className="num-lines-1"
                    css={{ width: "100%", textAlign: "center" }}>
                    <small>{upload.ref.name.split(".").pop()}</small>
                  </div>
                )}
              </div>
            )}
            <div
              css={{
                flexGrow: 1,
                padding: "1rem",
                flexShrink: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                pointerEvents: "none"
              }}>
              {upload.ref.name}
            </div>
            {isAdmin && (
              <div
                css={{
                  flexShrink: 0,
                  position: "relative",
                  "@media (hover: hover)": {
                    "li:not(:hover) &": {
                      visibility: "hidden"
                    }
                  }
                }}>
                <Tooltip label="Download">
                  <Button
                    size="small"
                    kind="outline"
                    target="_blank"
                    href={upload.url}
                    download={upload.ref.name}>
                    <Icon.Download size="1rem" />
                  </Button>
                </Tooltip>
                &nbsp;
                <Tooltip label="Remove">
                  <Button
                    size="small"
                    kind="outline"
                    onClick={() => deleteDocument(upload.ref)}>
                    <Icon.Trash2 size="1rem" />
                  </Button>
                </Tooltip>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
