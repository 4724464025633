/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import clipboard from "copy-to-clipboard"
import pluralize from "pluralize"
import * as Icon from "react-feather"
import { Link } from "react-router-dom"
import Button from "../components/Button"
import Tag from "../components/Tag"
import Tooltip from "../components/Tooltip"
import { displayDateRange } from "../utils/format"

export default function TourMeta({
  tour,
  leaders = [],
  departures = [],
  tourDates = [],
  extensionDates = [],
  showRegister = false,
  showFront = false
}) {
  if (tour.inactive) {
    return <Tag disabled>Inactive</Tag>
  }

  return (
    <div
      css={{
        margin: "-0.5rem -1rem",
        display: "flex",
        flexWrap: "wrap",
        "> div": {
          margin: "0.5rem 1rem"
        },
        "h6, p": {
          margin: 0,
          whiteSpace: "nowrap"
        }
      }}>
      <div>
        <h6>Status</h6>
        <p>
          <Tag disabled={tour.inactive}>
            {tour.inactive ? "Inactive" : "Active"}
          </Tag>
        </p>
      </div>
      <div>
        <h6>Tour Dates</h6>
        {tourDates && tourDates.length ? (
          tourDates.map((d, index) => (
            <p key={index}>{displayDateRange(...d)}</p>
          ))
        ) : (
          <p>None</p>
        )}
      </div>
      {extensionDates && extensionDates.length ? (
        <div>
          <h6>Extension</h6>
          {extensionDates.map((d, index) => (
            <p key={index}>{displayDateRange(...d)}</p>
          ))}
        </div>
      ) : null}
      {leaders && leaders.length ? (
        <div>
          <h6>Tour {pluralize("Leaders", leaders.length)}</h6>
          {leaders.map(x => (
            <p key={x}>{x}</p>
          ))}
        </div>
      ) : null}
      {departures && departures.length ? (
        <div>
          <h6>Departs</h6>
          {departures.map(x => (
            <p key={x}>{x}</p>
          ))}
        </div>
      ) : null}
      {showRegister && tour.tour_code ? (
        <div>
          <h6>Register Page</h6>
          <p css={{ position: "relative" }}>
            <Link
              to={{
                pathname: `/register/${tour.tour_code}`,
                state: {
                  from: `/tours/${tour.id}`
                }
              }}>
              Link
            </Link>
            <Tooltip label="Copy registration page link to clipboard">
              <Button
                size="small"
                onClick={() => {
                  clipboard(
                    `${window.location.origin}/register/${tour.tour_code}`
                  )
                }}>
                <Icon.Link size="0.8rem" />
              </Button>
            </Tooltip>
          </p>
        </div>
      ) : null}
      {showFront && tour.tour_code ? (
        <div>
          <h6>Front Page</h6>
          <p css={{ position: "relative" }}>
            <a
              href={`https://faithbasedexpeditions.com/tour/${tour.tour_code}`}
              target="_blank">
              Link
            </a>
            <Tooltip label="Copy frontpage link to clipboard">
              <Button
                size="small"
                onClick={() => {
                  clipboard(
                    `https://faithbasedexpeditions.com/tour/${tour.tour_code}`
                  )
                }}>
                <Icon.Link size="0.8rem" />
              </Button>
            </Tooltip>
          </p>
        </div>
      ) : null}
      {tour.insurance_link ? (
        <div>
          <h6>Insurance Link</h6>
          <p css={{ position: "relative" }}>
            <a href={tour.insurance_link} target="_blank">
              Link
            </a>
            <Tooltip label="Copy insurance link to clipboard">
              <Button
                size="small"
                onClick={() => {
                  clipboard(tour.insurance_link)
                }}>
                <Icon.Link size="0.8rem" />
              </Button>
            </Tooltip>
          </p>
        </div>
      ) : null}
    </div>
  )
}
