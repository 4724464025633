/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import * as Icon from "react-feather"
import { Link } from "react-router-dom"
import Alert from "../components/Alert"
import Button from "../components/Button"
import Loading from "../components/Loading"
import DayPicker from "../components/DayPicker"
import RegistrantCard from "../components/RegistrantCard"
import Select from "../components/Select"
import TourCard from "../components/TourCard"
import { db } from "../firebase"
import { useTheme } from "../theme"
import {
  displayCalendarDay,
  displayFullName,
  displayLocalTime,
  displayMoney,
  displayName,
  displayPaymentMethod
} from "../utils/format"
import { serializeDate } from "../utils/form"
import { Helmet } from "react-helmet"

function ActivityEntry({ time, preview, children, Icon }) {
  const { theme } = useTheme()

  return (
    <div
      css={{
        position: "relative",
        padding: "1rem 0",
        maxWidth: "50rem"
      }}>
      <div
        css={{
          display: "flex",
          alignItems: "flex-start",
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
            marginLeft: "2rem"
          }
        }}>
        <small
          css={{
            width: "5rem",
            flexShrink: 0,
            color: theme.tertiaryText
          }}>
          {displayLocalTime(time)}
        </small>
        <div
          css={{
            flexShrink: 0,
            width: "2rem",
            height: "2rem",
            marginRight: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.accentText,
            borderRadius: "1rem",
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "2rem",
              left: 0,
              width: "1.5rem",
              height: "1.5rem",
              svg: {
                height: "0.875rem"
              }
            }
          }}>
          {Icon ? (
            <Icon size="1rem" style={{ color: theme.backgroundColor }} />
          ) : null}
        </div>
        <div css={{ flexGrow: 1, alignSelf: "center", "max-width": "100%" }}>
          <div>
            <p
              className="num-lines-2"
              css={{ marginTop: 0, marginBottom: "0.5rem" }}>
              {children}
            </p>
            {preview}
          </div>
        </div>
      </div>
    </div>
  )
}

function ActivitySwitch({ activity }) {
  const {
    type,
    time,
    tour_id,
    tour,
    extension,
    registrant,
    addon,
    payment
  } = activity

  switch (type) {
    case "tour_created":
      if (!tour) return null
      return (
        <ActivityEntry
          Icon={Icon.Map}
          time={time}
          preview={<TourCard tour={tour} />}>
          <a href="#">Admin</a> created a tour
        </ActivityEntry>
      )
    case "tour_updated":
      if (!tour) return null
      return (
        <ActivityEntry
          Icon={Icon.Map}
          time={time}
          preview={<TourCard tour={tour} />}>
          <a href="#">Admin</a> updated a tour
        </ActivityEntry>
      )
    case "extension_created":
      if (!tour || !extension) return null
      return (
        <ActivityEntry
          Icon={Icon.Map}
          time={time}
          preview={
            <Link to={`/tours/${extension.tour_id}/extensions/${extension.id}`}>
              {extension.title}
            </Link>
          }>
          <a href="#">Admin</a> created an extension for{" "}
          <Link>{tour.title}</Link>
        </ActivityEntry>
      )
    case "extension_updated":
      if (!extension || !tour) return null
      return (
        <ActivityEntry
          Icon={Icon.Map}
          time={time}
          preview={
            <Link to={`/tours/${extension.tour_id}/extensions/${extension.id}`}>
              {extension.title}
            </Link>
          }>
          <a href="#">Admin</a> updated an extension for{" "}
          <Link>{tour ? tour.title : "[Tour Not Found]"}</Link>
        </ActivityEntry>
      )
    case "registrant_created":
      if (!tour || !registrant) return null
      return (
        <ActivityEntry
          Icon={Icon.UserCheck}
          time={time}
          preview={<RegistrantCard registrant={registrant} />}>
          <Link to={`/registrations/${registrant.registrant_id}`}>
            {registrant.name_first}
          </Link>{" "}
          registered for <Link to={`/tours/${tour_id}`}>{tour.title}</Link>
        </ActivityEntry>
      )
    case "registrant_updated":
      if (!tour || !registrant) return null
      return (
        <ActivityEntry
          Icon={Icon.UserCheck}
          time={time}
          preview={<RegistrantCard registrant={registrant} />}>
          <Link
            to={`/registrations/${registrant.registrant_id || registrant.id} `}>
            {registrant.name_first}
          </Link>
          ’s registration for <Link to={`/tours/${tour_id}`}>{tour.title}</Link>{" "}
          was updated.
        </ActivityEntry>
      )
    case "addon_created":
      if (!addon || !registrant) return null
      return (
        <ActivityEntry
          Icon={Icon.UserPlus}
          time={time}
          preview={
            <h5>
              {displayMoney(addon.price)}&mdash;
              {addon.description}
            </h5>
          }>
          <a href="#">Admin</a> created an addon for{" "}
          <Link to={`/registrations/${addon.registrant_id}`}>
            {displayFullName(registrant)}
          </Link>
        </ActivityEntry>
      )
    case "addon_updated":
      if (!addon || !registrant) return null
      return (
        <ActivityEntry
          Icon={Icon.UserPlus}
          time={time}
          preview={
            <h5>
              {displayMoney(addon.price)}&mdash;
              {addon.description}
            </h5>
          }>
          <a href="#">Admin</a> updated an addon for{" "}
          <Link to={`/registrations/${addon.registrant_id}`}>
            {displayFullName(registrant)}
          </Link>
        </ActivityEntry>
      )
    case "payment_created":
      if (!tour || !payment || !registrant) return null
      return (
        <ActivityEntry
          Icon={Icon.DollarSign}
          time={time}
          preview={
            <h5 css={{ textTransform: "capitalize" }}>
              {displayMoney(payment.amount)}&mdash;
              {displayPaymentMethod(payment.method)} Payment
            </h5>
          }>
          <Link to={`/registrations/${registrant.id}`}>
            {displayName(registrant)}
          </Link>{" "}
          made a payment for <Link to={`/tours/${tour_id}`}>{tour.title}</Link>
        </ActivityEntry>
      )
    default:
      return null
  }
}

export function ActivityTimeline({ tourId, itemsPerPage = 20 }) {
  const { theme } = useTheme()

  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activities, setActivities] = React.useState([])
  const [activityType, setActivityType] = React.useState("")
  const [fromDate, setFromDate] = React.useState(null)
  const [toDate, setToDate] = React.useState(null)
  const [lastActivityRef, setLastActivityRef] = React.useState(null)

  const toRef = React.useRef()

  React.useEffect(() => {
    setActivities([])
    loadActivity()
  }, [tourId, activityType, toDate, fromDate, itemsPerPage])

  async function loadActivity(startAt) {
    try {
      setLoading(true)
      let activityRef = db.collection("activity").orderBy("time", "desc")
      if (tourId) {
        activityRef = activityRef.where("tour_id", "==", tourId)
      }
      if (activityType) {
        activityRef = activityRef.where("type", "in", activityType.split(","))
      }
      if (fromDate) {
        activityRef = activityRef.where("time", ">=", serializeDate(fromDate))
      }
      if (toDate) {
        activityRef = activityRef.where("time", "<=", serializeDate(toDate))
      }
      if (startAt) {
        activityRef = activityRef.startAt(startAt)
      }
      if (itemsPerPage) {
        activityRef = activityRef.limit(itemsPerPage)
      }
      const activityDocs = await activityRef.get()
      const activities = []
      activityDocs.forEach(activityDoc => {
        activities.push(activityDoc.data())
      })
      const lastActivityRef = activityDocs.docs[activityDocs.docs.length - 1]
      setActivities(x => x.concat(activities))
      setLastActivityRef(lastActivityRef)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  let activitiesByDay = []
  activities.forEach(activity => {
    const dayLabel = displayCalendarDay(activity.time)
    const prevDay = activitiesByDay[activitiesByDay.length - 1]
    if (prevDay && prevDay.label == dayLabel) {
      prevDay.activities.push(activity)
    } else {
      activitiesByDay.push({
        label: dayLabel,
        activities: [activity]
      })
    }
  })

  if (error) {
    return <Alert error={error} />
  }

  return (
    <div css={{ position: "relative", minHeight: 500 }}>
      <header css={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <div css={{ marginBottom: "0.5rem" }}>
          <Select
            value={activityType}
            onChange={e => setActivityType(e.target.value)}>
            <option value="">All activity</option>
            <option value="tour_created,tour_updated">Tour activity</option>
            <option value="registrant_created,registrant_updated">
              Registration activity
            </option>
            <option value="addon_created,addon_updated">Addon activity</option>
            <option value="payment_created,payment_updated">
              Payment activity
            </option>
          </Select>
        </div>
        &nbsp;
        <div
          css={{
            marginBottom: "0.5rem",
            display: "flex",
            alignItems: "center"
          }}>
          <DayPicker
            value={fromDate}
            onChange={setFromDate}
            placeholder="From"
            clickUnselectsDay={true}
            dayPickerProps={{
              selectedDays: [fromDate, { from: fromDate, to: toDate }],
              disabledDays: { after: toDate },
              toMonth: toDate,
              modifiers: { start: fromDate, end: toDate },
              onDayClick: () => {
                toRef.current.getInput().focus()
              }
            }}
          />
          &nbsp;
          <span>&mdash;</span>
          &nbsp;
          <DayPicker
            ref={toRef}
            value={toDate}
            onChange={setToDate}
            placeholder="To"
            clickUnselectsDay={true}
            dayPickerProps={{
              selectedDays: [fromDate, { from: fromDate, to: toDate }],
              disabledDays: { before: fromDate },
              modifiers: { start: fromDate, end: toDate },
              month: fromDate,
              fromMonth: fromDate
            }}
          />
        </div>
      </header>
      {activities.length ? (
        <ul
          css={{
            margin: 0,
            padding: 0,
            listStyle: "none"
          }}>
          {activitiesByDay.map(day => (
            <li key={day.label}>
              <div
                css={{
                  zIndex: 1,
                  position: "sticky",
                  top: 0,
                  left: 0,
                  padding: "0.5rem 0",
                  backgroundColor: theme.backgroundColor,
                  borderBottom: `1px solid ${theme.borderColor}`,
                  "@media (max-width: 1000px)": {
                    top: "3rem"
                  }
                }}>
                <h4 css={{ margin: 0 }}>{day.label}</h4>
              </div>
              <ul
                css={{
                  position: "relative",
                  margin: 0,
                  padding: 0,
                  listStyle: "none"
                }}>
                {day.activities.map(activity => (
                  <li key={activity.id}>
                    <ActivitySwitch activity={activity} />
                  </li>
                ))}
              </ul>
            </li>
          ))}
          {activities.length % itemsPerPage ? null : (
            <Button
              onClick={() => loadActivity(lastActivityRef)}
              size="large"
              kind="outline"
              disabled={loading}>
              {loading ? "Loading..." : "Load more activities"}
            </Button>
          )}
        </ul>
      ) : loading ? (
        <Loading />
      ) : (
        <p>No activities found.</p>
      )}
    </div>
  )
}

export default function Activity() {
  return (
    <>
      <Helmet>
        <title>Activity | Faith Based Expeditions</title>
      </Helmet>
      <br />
      <h1>Activity</h1>
      <ActivityTimeline />
    </>
  )
}
