import React from "react"
import Alert from "../components/Alert"
import Button from "../components/Button"
import Field from "../components/Field"
import FieldRow from "../components/FieldRow"
import Input from "../components/Input"
import Select from "../components/Select"
import Textarea from "../components/Textarea"
import { deserializeMoney, serializeMoney } from "../utils/form"

export default function AdminPaymentForm({
  minAmount = 0,
  maxAmount,
  prompt,
  onSubmit
}) {
  const [amount, setAmount] = React.useState(minAmount)
  const [submitting, setSubmitting] = React.useState(false)
  const [error, setError] = React.useState(null)

  async function handleSubmit(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const paymentData = {
      amount: serializeMoney(formData.get("amount")),
      method: formData.get("method"),
      note: formData.get("note")
    }
    try {
      setError(null)
      setSubmitting(true)
      if (!paymentData.amount) {
        throw new Error("Please enter a valid amount.")
      }
      if (!paymentData.note) {
        throw new Error("Please enter a valid description.")
      }
      await onSubmit(paymentData)
    } catch (error) {
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FieldRow>
        <Field label="Amount" tooltip="The amount you received">
          <Input
            name="amount"
            mask={Input.currencyMask}
            value={deserializeMoney(amount)}
            onChange={e => setAmount(serializeMoney(e.target.value))}
            required
          />
        </Field>
        <Field label="Method" tooltip="How you received the payment">
          <Select name="method" defaultValue="check" fullWidth required>
            <option value="check">Check</option>
            <option value="card">Card</option>
            <option value="cash">Cash</option>
            <option value="charged">Charged</option>
            <option value="refund">Refund</option>
          </Select>
        </Field>
      </FieldRow>
      <Field label="Description" tooltip="What the was payment for">
        <Textarea name="note" minRows={2} required />
      </Field>
      <Button size="large" kind="outline" disabled={submitting}>
        {submitting ? "Saving..." : prompt}
      </Button>
      {error && <Alert error={error} />}
    </form>
  )
}
