const moment = require("moment")
const numeral = require("numeral")
const phone = require("phone-formatter")

exports.displayName = registrant => {
  return `${registrant.name_first} ${registrant.name_last[0]}.`
}

exports.displayFullName = registrant => {
  return `${registrant.name_first} ${registrant.name_middle} ${registrant.name_last}`
}

exports.displayInitials = registrant => {
  return registrant.name_first[0] + registrant.name_last[0]
}

exports.displayAge = registrant => {
  return moment().diff(registrant.birthdate || 120, "years")
}

exports.displayAddress = registrant => {
  return `${registrant.mailing_address_street}\n${registrant.mailing_address_city} ${registrant.mailing_address_state}, ${registrant.mailing_address_zip}`
}

exports.displayMoney = num => {
  return numeral(num / 100).format("$0,0[.]00")
}

exports.displayPhone = num => {
  if (num) {
    return phone.format(num, "(NNN) NNN-NNNN")
  }
  return ""
}

exports.displayDate = num => {
  return moment.utc(num).format("MMM DD, YYYY")
}

exports.displayDateRange = (d1, d2) => {
  return `${moment.utc(d1).format("MMM DD")}–${moment
    .utc(d2)
    .format("MMM DD")}, ${moment.utc(d2).format("YYYY")}`
}

exports.displayDaytime = () => {
  const hr = moment().hour()
  return hr < 12 ? "morning" : hr <= 17 ? "afternoon" : "evening"
}

exports.displayCalendarDay = num => {
  return moment.utc(num).calendar(null, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "dddd, MMM D, YYYY",
    sameElse: "dddd, MMM D, YYYY"
  })
}

exports.displayLocalTime = num => {
  return moment(num).format("LT")
}

exports.displayPaymentMethod = str => {
  if (str === "ach_debit") return "online check"
  return str
}
