const sum = require("lodash/sum")
const countBy = require("lodash/countBy")
const { getInvoiceData } = require("./getInvoiceData")

exports.getGroupInvoiceData = (
  registrants,
  tour,
  insurance,
  extensions,
  addons = [],
  payments = []
) => {
  let dueDate = null
  let isLate = false
  let tourSubtotals = []
  let extensionSubtotals = []
  let supplementSubtotals = []
  let addonsSubtotals = []
  let earlyDiscountSubtotals = []
  let insuranceSubtotals = []
  let processingFee = 0
  let lateFee = 0
  let totalPaid = 0
  let insuredSubtotal = 0
  let subtotalDue = 0
  let depositTotal = 0

  registrants.forEach(registrant => {
    const invoiceData = getInvoiceData(
      registrant,
      tour,
      insurance,
      extensions,
      addons,
      payments
    )

    dueDate = invoiceData.dueDate

    insuredSubtotal += invoiceData.insuredSubtotal
    subtotalDue += invoiceData.subtotalDue
    depositTotal += invoiceData.depositTotal

    if (registrant.processing_fee) {
      processingFee += invoiceData.processingFee
    }

    if (invoiceData.lateFee) {
      isLate = true
    }

    if (invoiceData.totalPaid) {
      totalPaid += invoiceData.totalPaid
    }

    if (!registrant.inactive) {
      tourSubtotals.push(invoiceData.tourTotal)

      if (registrant.extension_id) {
        extensionSubtotals.push(invoiceData.extensionTotal)
      }

      if (registrant.supplement_yes) {
        supplementSubtotals.push(invoiceData.supplementTotal)
      }

      if (addons.length) {
        addonsSubtotals.push(invoiceData.addonsTotal)
      }

      if (invoiceData.earlyDiscount) {
        earlyDiscountSubtotals.push(invoiceData.earlyDiscount)
      }

      if (registrant.insurance_yes) {
        insuranceSubtotals.push(invoiceData.insuranceTotal)
      }
    }
  })

  let tourItems = countBy(tourSubtotals)
  let tourTotal = sum(tourSubtotals)

  let extensionItems = countBy(extensionSubtotals)
  let extensionTotal = sum(extensionSubtotals)

  let supplementTotal = sum(supplementSubtotals)
  let supplementItems = countBy(supplementSubtotals)

  let addonsTotal = sum(addonsSubtotals)

  let earlyDiscountItems = countBy(earlyDiscountSubtotals)
  let earlyDiscount = sum(earlyDiscountSubtotals)

  let insuranceItems = countBy(insuranceSubtotals)
  let insuranceTotal = sum(insuranceSubtotals)

  let totalDue =
    tourTotal +
    extensionTotal +
    supplementTotal +
    addonsTotal -
    earlyDiscount +
    insuranceTotal +
    processingFee

  let totalRemaining = totalDue - totalPaid

  // if (isLate) {
  //   lateFee = 9500 * registrants.length
  //   totalDue += lateFee
  //   totalRemaining += lateFee
  // }

  return {
    tourItems,
    extensionItems,
    supplementItems,
    earlyDiscountItems,
    insuranceItems,
    depositTotal,
    tourTotal,
    extensionTotal,
    supplementTotal,
    addonsTotal,
    earlyDiscount,
    subtotalDue,
    insuredSubtotal,
    insuranceTotal,
    processingFee,
    lateFee,
    totalDue,
    totalPaid,
    totalRemaining,
    dueDate
  }
}
