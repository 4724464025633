/** @jsx jsx */
import { jsx } from "@emotion/core"
import countryList from "country-list"
import Select from "../components/Select"

export default function CountrySelect({ defaultValue = "US", ...rest }) {
  return (
    <Select fullWidth defaultValue={defaultValue} {...rest}>
      {countryList.getCodes().map(countryCode => (
        <option key={countryCode} value={countryCode}>
          {countryList.getName(countryCode)}
        </option>
      ))}
    </Select>
  )
}
