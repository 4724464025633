import React from "react"
import Alert from "../components/Alert"
import Field from "../components/Field"
import Input from "../components/Input"
import DownloadManifestButton from "../components/DownloadManifestButton"

export default function AccessCodeForm() {
  const [error, setError] = React.useState(null)
  const [tourCode, setTourCode] = React.useState("")
  const [accessCode, setAccessCode] = React.useState("")

  return (
    <form>
      <p>
        If you have a tour code and tour leader access code you can use this
        form to download your tour manifest.
      </p>
      <Field label="Tour Code">
        <Input
          type="text"
          name="tour_code"
          onChange={e => setTourCode(e.target.value)}
          value={tourCode}
          required
        />
      </Field>
      <Field label="Tour Leader Access Code">
        <Input
          type="text"
          name="tour_leader_access_code"
          onChange={e => setAccessCode(e.target.value)}
          value={accessCode}
          required
        />
      </Field>
      <DownloadManifestButton
        tourCode={tourCode}
        accessCode={accessCode}
        type="button"
        size="large"
        kind="fill"
        onError={setError}
        onSuccess={() => {
          setTourCode("")
          setAccessCode("")
        }}
      />
      {error && <Alert error={error} />}
    </form>
  )
}
