/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { RED } from "../colors"

export default function Alert({ error, ...rest }) {
  if (!error) {
    return null
  }

  return (
    <small css={{ display: "block", color: RED }} {...rest}>
      {error.message
        ? error.message
        : typeof error === "string"
        ? error
        : "An unexpected error occurred."}
    </small>
  )
}
