/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import Alert from "../components/Alert"
import Button from "../components/Button"

export default class ErrorBoundary extends React.Component {
  state = {
    error: null
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
    this.setState({ error })
  }

  handleReload() {
    localStorage.clear()
    window.location.reload()
  }

  render() {
    if (this.state.error) {
      return (
        <div css={{ margin: "2rem auto", maxWidth: "30rem" }}>
          <h2>{this.props.title || "Unknown Error"}</h2>
          <p>An unexpected error occurred.</p>
          <details>
            <summary>Expand Error</summary>
            <br />
            <Alert error={this.state.error} />
          </details>
          <Button kind="outline" size="large" onClick={this.handleReload}>
            Reload Page
          </Button>
        </div>
      )
    }

    return this.props.children
  }
}
