/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"

export default function FieldRow({ children, breakpoint = 500, ...rest }) {
  return (
    <div
      css={{
        [`@media (min-width: ${breakpoint + 1}px)`]: {
          margin: "1rem 0",
          display: "flex",
          alignItems: "flex-start",
          "> div": {
            flex: 1,
            margin: 0,
            ":not(:last-child)": {
              marginRight: "0.5rem"
            }
          }
        }
      }}
      {...rest}>
      {children}
    </div>
  )
}
