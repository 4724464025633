import React from "react"
import queryString from "query-string"
import { useHistory } from "react-router-dom"

export default function useSyncSearchToURL(currentRefinement, refine) {
  const history = useHistory()

  // set default search query from url
  React.useEffect(() => {
    const { search } = queryString.parse(history.location.search)
    if (search) {
      refine(search)
    }
  }, [])

  // sync search query to url
  React.useEffect(() => {
    const pathname = history.location.pathname
    const search = queryString.stringify({ search: currentRefinement })
    if (currentRefinement) {
      history.replace({ pathname, search })
    } else {
      history.replace(pathname)
    }
  }, [currentRefinement])
}
