/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import Input from "../components/Input"
import Field from "../components/Field"
import Button from "../components/Button"
import Select from "../components/Select"
import Checkbox from "../components/Checkbox"
import FieldRow from "../components/FieldRow"
import {
  deserializeDate,
  deserializeMoney,
  serializeMoney,
  serializeDate
} from "../utils/form"
import { RED } from "../colors"

export default function ExtensionForm({
  extension,
  extensionCosts,
  prompt = "Submit",
  onSubmit
}) {
  const [error, setError] = React.useState(null)
  const [submitting, setSubmitting] = React.useState(false)
  const [price, setPrice] = React.useState(
    deserializeMoney(extension ? extension.price : null)
  )

  async function handleSubmit(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    // prettier-ignore
    const extensionData = {
      title: formData.get("extension.title") || "",
      start_date: serializeDate(formData.get("extension.start_date")),
      end_date: serializeDate(formData.get("extension.end_date")),
      timing: formData.get("extension.timing") || "pre",
      price: serializeMoney(formData.get("extension.price")),
      supplement_price: serializeMoney(formData.get("extension.supplement_price")),
      deposit_amount: serializeMoney(formData.get("extension.deposit_amount")),
      _update_existing_registrants: !!formData.get("_update_existing_registrants")
    }
    const extensionCostsData = {
      land: serializeMoney(formData.get("extensionCosts.land")),
      group_air: serializeMoney(formData.get("extensionCosts.group_air")),
      local_air: serializeMoney(formData.get("extensionCosts.local_air")),
      gratuity: serializeMoney(formData.get("extensionCosts.gratuity")),
      supplement: serializeMoney(formData.get("extensionCosts.supplement"))
    }
    try {
      setError(null)
      setSubmitting(true)
      await onSubmit(extensionData, extensionCostsData)
    } catch (error) {
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field
        label="Title"
        tooltip="Short description of the extension. Presented as option when someone registers for the tour.">
        <Input
          name="extension.title"
          defaultValue={extension ? extension.title : null}
          autoFocus
          required
        />
      </Field>
      <h4>Offering</h4>
      <FieldRow>
        <Field
          label="Start Date"
          tooltip="The date when extension begins. If this extension is post-tour the start date should match the tour end date.">
          <Input
            type="date"
            name="extension.start_date"
            defaultValue={deserializeDate(extension && extension.start_date)}
            required={true}
          />
        </Field>
        <Field
          label="End Date"
          tooltip="The date when the extension is over. If this extension is pre-tour the end date should match the tour start date.">
          <Input
            type="date"
            name="extension.end_date"
            defaultValue={deserializeDate(extension && extension.end_date)}
            required={true}
          />
        </Field>
        <Field
          label="Timing"
          tooltip="This helps with cost breakdown calculations.">
          <Select
            name="extension.timing"
            defaultValue={(extension && extension.timing) || "pre"}
            fullWidth
            required>
            <option value="pre">Pre-Tour</option>
            <option value="post">Post-Tour</option>
            <option value="both">Pre- and Post-Tour</option>
          </Select>
        </Field>
      </FieldRow>
      <FieldRow>
        <Field
          label="Extension Price"
          tooltip="The publicly visible price of the tour that is charged all registrants that select this extension.">
          <Input
            name="extension.price"
            mask={Input.currencyMask}
            value={price}
            onChange={e => setPrice(e.target.value)}
            required
          />
        </Field>
        <Field
          label="Single Supplement Price"
          tooltip="How much a registrant that is rooming alone on this extension should be charged extra.">
          <Input
            name="extension.supplement_price"
            mask={Input.currencyMask}
            defaultValue={deserializeMoney(
              extension ? extension.supplement_price : null
            )}
            required
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field
          label="Additional Deposit"
          tooltip="How much should the registrant be charged during registration in addition to the tour deposit.">
          <Input
            name="extension.deposit_amount"
            mask={Input.currencyMask}
            defaultValue={deserializeMoney(
              extension ? extension.deposit_amount : null
            )}
          />
        </Field>
        <div style={{ flex: 1 }} />
      </FieldRow>
      {extension && extension.price != serializeMoney(price) ? (
        <Field
          label="Update pricing for existing travelers?"
          tooltip="Whether or not this tour offers a discount for registering by some date">
          <Checkbox name="_update_existing_registrants">
            <small>
              Check this box if you want to update all <i>existing</i> invoices
              to use this new tour pricing.{" "}
              <span css={{ color: RED }}>
                Warning: only check this box if all registrants have been made
                aware of the new pricing.
              </span>
            </small>
          </Checkbox>
        </Field>
      ) : null}
      <h4>Costs</h4>
      <FieldRow>
        <Field
          label="Land Cost"
          tooltip="The internal land travel cost of providing this per registrant.">
          <Input
            name="extensionCosts.land"
            mask={Input.currencyMask}
            defaultValue={deserializeMoney(
              extensionCosts ? extensionCosts.land : null
            )}
            required
          />
        </Field>
        <Field
          label="Group Air Cost"
          tooltip="The internal cost of main tour air travel for each registrant that selects air travel and this extension.">
          <Input
            name="extensionCosts.group_air"
            mask={Input.currencyMask}
            defaultValue={deserializeMoney(
              extensionCosts ? extensionCosts.group_air : null
            )}
            required
          />
        </Field>
        <Field
          label="In-Country Air Cost"
          tooltip="The internal cost of local air travel in the destination country for each registrant that selects this extension.">
          <Input
            name="extensionCosts.local_air"
            mask={Input.currencyMask}
            defaultValue={deserializeMoney(
              extensionCosts ? extensionCosts.local_air : null
            )}
            required
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field
          label="Gratuity Cost"
          tooltip="The internal cost set aside for gratuity.">
          <Input
            name="extensionCosts.gratuity"
            mask={Input.currencyMask}
            defaultValue={deserializeMoney(
              extensionCosts ? extensionCosts.gratuity : null
            )}
            required
          />
        </Field>
        <Field
          label="Supplement Cost"
          tooltip="The internal additional cost of lodging during the extension for a registrant that wants to room alone.">
          <Input
            name="extensionCosts.supplement"
            mask={Input.currencyMask}
            defaultValue={deserializeMoney(
              extensionCosts ? extensionCosts.supplement : null
            )}
            required
          />
        </Field>
        <div />
      </FieldRow>
      <Button type="submit" size="large" kind="fill">
        {submitting ? "Submitting..." : prompt}
      </Button>
    </form>
  )
}
