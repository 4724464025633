/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import pluralize from "pluralize"
import { Link } from "react-router-dom"
import { useTheme } from "../theme"
import { displayDateRange } from "../utils/format"
import Tag from "../components/Tag"
import TourImage from "../components/TourImage"

export default function TourCard({ tour }) {
  const { theme } = useTheme()

  return (
    <div
      css={{
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        "> div": {
          padding: "0.5rem"
        }
      }}>
      <Link
        to={`/tours/${tour.id}`}
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 2,
          border: `1px solid ${theme.borderColor}`
        }}
      />
      <div css={{ width: 100, height: 72, flexShrink: 0 }}>
        <TourImage
          tour={tour}
          css={{
            display: "block",
            width: "100%",
            height: "auto",
            borderRadius: 3
          }}
        />
      </div>
      <div css={{ width: 1, flex: 1 }}>
        <h5
          className="num-lines-2"
          css={{
            minWidth: 1,
            margin: 0
          }}>
          {tour.title}
        </h5>
        <div css={{ display: "flex", alignItems: "center" }}>
          <Tag disabled={tour.inactive}>
            {tour.inactive ? "Inactive" : "Active"}
          </Tag>
          <small
            className="num-lines-1"
            css={{
              svg: {
                height: "1em",
                position: "relative",
                top: 1
              }
            }}>
            &nbsp;
            {pluralize("passengers", tour.tickets_sold, true)}
            &nbsp; &nbsp;
            {tour.tour_dates[0] && (
              <span>
                {displayDateRange(
                  tour.tour_dates[0].start,
                  tour.tour_dates[0].end
                )}
              </span>
            )}
            &nbsp; &nbsp;
            {tour.tour_leaders[0] && <span>{tour.tour_leaders[0]}</span>}
          </small>
        </div>
      </div>
    </div>
  )
}
