export const BLACK = "rgba(0,0,0,1)"
export const BLACK_80 = "rgba(0,0,0,0.8)"
export const BLACK_50 = "rgba(0,0,0,0.5)"
export const BLACK_30 = "rgba(0,0,0,0.3)"
export const BLACK_20 = "rgba(0,0,0,0.2)"
export const BLACK_10 = "rgba(0,0,0,0.1)"
export const BLACK_5 = "rgba(0,0,0,0.05)"

export const WHITE = "rgba(255,255,255,1)"
export const WHITE_80 = "rgba(255,255,255,0.8)"
export const WHITE_50 = "rgba(255,255,255,0.5)"
export const WHITE_30 = "rgba(255,255,255,0.3)"
export const WHITE_20 = "rgba(255,255,255,0.2)"
export const WHITE_10 = "rgba(255,255,255,0.1)"

export const CREAM = "rgba(252,252,250,1)"

export const GOLD = "rgba(249,215,143,1)"

export const GREEN = "rgba(23,49,46,1)"

export const OLIVE = "rgba(55,72,54,1)"

export const RED = "rgba(231,76,60,1)"
