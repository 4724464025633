import React from "react"

const imageGreece = require("../assets/cover-greece.jpg")
const imageIsrael = require("../assets/cover-israel.jpg")
const imageItaly = require("../assets/cover-italy.jpg")
const imageTurkey = require("../assets/cover-turkey.jpg")
const imageEgypt = require("../assets/cover-egypt.jpg")
const imageEurope = require("../assets/cover-europe.jpg")

export default function TourImage({ tour, ...rest }) {
  let src = imageGreece
  if (tour) {
    if (tour.destination === "Israel") {
      src = imageIsrael
    }
    if (tour.destination === "Italy") {
      src = imageItaly
    }
    if (tour.destination === "Turkey") {
      src = imageTurkey
    }
    if (tour.destination === "Egypt") {
      src = imageEgypt
    }
    if (tour.destination === "Europe") {
      src = imageEurope
    }
  }
  return <img src={src} {...rest} />
}
