/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { Helmet } from "react-helmet"
import {
  Configure,
  connectInfiniteHits,
  connectSearchBox,
  InstantSearch
} from "react-instantsearch-dom"
import Button from "../components/Button"
import Input from "../components/Input"
import Loading from "../components/Loading"
import RegistrantCard from "../components/RegistrantCard"
import Select from "../components/Select"
import { db } from "../firebase"
import useSyncSearchToURL from "../hooks/useSyncSearchToURL"
import { useStore } from "../store"
import { useTheme } from "../theme"

const SearchBox = connectSearchBox(props => {
  useSyncSearchToURL(props.currentRefinement, props.refine)

  return (
    <Input
      {...props}
      value={props.currentRefinement}
      onChange={e => props.refine(e.target.value)}
    />
  )
})

const Hits = connectInfiniteHits(props => {
  return (
    <ul css={{ margin: 0, padding: 0, listStyle: "none" }}>
      {props.hits ? (
        props.hits.map(hit => {
          const tour = props.tours[hit.tour_id]
          return (
            <li
              key={hit.objectID}
              css={{
                ":not(:hover) a": {
                  borderColor: "transparent"
                }
              }}>
              <RegistrantCard registrant={hit} tour={tour} />
            </li>
          )
        })
      ) : (
        <p>No registrations found.</p>
      )}
      {props.hasMore && (
        <Button size="large" kind="outline" onClick={() => props.refineNext()}>
          Load more
        </Button>
      )}
    </ul>
  )
})

let cachedTours = null

export default function RegistrantsBrowse() {
  const { searchClient } = useStore()
  const { theme } = useTheme()

  const [tours, setTours] = React.useState(null)

  const [status, setStatus] = React.useState("")

  let filters = []
  if (status) {
    filters.push(`inactive:${status}`)
  }

  React.useEffect(() => {
    loadData()
  }, [])

  // TODO: save tour name on registrant so we don't need to do this
  async function loadData() {
    if (!cachedTours) {
      try {
        const toursRef = db.collection("tours")
        const tourDocs = await toursRef.get()
        cachedTours = {}
        tourDocs.forEach(tourDoc => {
          const tour = tourDoc.data()
          cachedTours[tourDoc.id] = tour
        })
      } catch (error) {
        console.error(error)
        cachedTours = null
      }
    }
    setTours(cachedTours)
  }

  if (!tours) {
    return <Loading />
  }

  return (
    <>
      <Helmet>
        <title>Registrations | Faith Based Expeditions</title>
      </Helmet>
      <br />
      <h1 css={{ marginBottom: 0 }}>Registrations</h1>
      <InstantSearch searchClient={searchClient} indexName="fbe_registrants">
        <Configure filters={filters.join(" AND ")} />
        <header
          css={{
            zIndex: 1,
            position: "sticky",
            top: 0,
            padding: "1rem 0",
            display: "flex",
            alignItems: "flex-end",
            background: theme.backgroundColor,
            "@media (max-width: 1000px)": {
              top: "3rem",
              padding: "0.5rem 0"
            }
          }}>
          <Select value={status} onChange={e => setStatus(e.target.value)}>
            <option value="">All</option>
            <option value="false">Active</option>
            <option value="true">Inactive</option>
          </Select>
          &nbsp;
          <SearchBox type="search" placeholder="Search name or email..." />
        </header>
        <Hits tours={tours} />
      </InstantSearch>
    </>
  )
}
