const { getInvoiceData } = require("./getInvoiceData")
const { getExpenseData } = require("./getExpenseData")
const { getGroupInvoiceData } = require("./getGroupInvoiceData")

exports.getRegistrantsReportData = (
  tour,
  tourCosts,
  extensions,
  extensionsCosts,
  insurance,
  registrants,
  addons,
  payments
) => {
  return registrants
    .slice()
    .sort((a, b) => a.name_last.localeCompare(b.name_last))
    .map(registrant => {
      const invoiceData = getInvoiceData(
        registrant,
        tour,
        insurance,
        extensions,
        addons,
        payments
      )
      if (registrant.registrant_id) {
        invoiceData.totalRemaining = 0
      } else {
        const subregistrants = registrants.filter(
          x => x.registrant_id == registrant.id
        )
        const groupInvoiceData = getGroupInvoiceData(
          subregistrants.concat(registrant),
          tour,
          insurance,
          extensions,
          addons,
          payments
        )
        invoiceData.lateFee = groupInvoiceData.lateFee
        invoiceData.totalRemaining = groupInvoiceData.totalRemaining
      }
      const expenseData = getExpenseData(
        registrant,
        addons,
        tour,
        extensions,
        tourCosts,
        extensionsCosts,
        insurance
      )
      return {
        id: registrant.id,
        registrant,
        invoiceData,
        expenseData
      }
    })
}

exports.getRegistrantsReportDataSums = registrantsReportData => {
  let sums = {}
  registrantsReportData.forEach(data => {
    Object.keys(data.invoiceData).forEach(key => {
      let value = data.invoiceData[key]
      // special case, need to split into L/O and L&A totals
      if (key == "tourTotal") {
        if (data.registrant.airtravel_yes) key = "landAirTotal"
        else key = "landOnlyTotal"
      }
      if (!sums[key]) sums[key] = 0
      sums[key] += value
    })
    Object.keys(data.expenseData).forEach(key => {
      let value = data.expenseData[key]
      if (!sums[key]) sums[key] = 0
      sums[key] += value
    })
  })
  return sums
}
