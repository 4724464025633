/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { Helmet } from "react-helmet"
import { Redirect, Route } from "react-router-dom"
import Button from "../components/Button"
import Field from "../components/Field"
import TabLink from "../components/TabLink"
import Toggle from "../components/Toggle"
import AdminEmailsForm from "../forms/AdminEmailsForm"
import AdminUsersForm from "../forms/AdminUsersForm"
import { darkTheme, lightTheme, useTheme } from "../theme"
import Insurances from "./Insurances"

export default function Settings() {
  const { theme, setTheme } = useTheme()

  return (
    <>
      <Helmet>
        <title>Settings | Faith Based Expeditions</title>
      </Helmet>
      <br />
      <h1>Settings</h1>
      <nav
        css={{
          borderBottom: `1px solid ${theme.borderColor}`,
          marginBottom: "2rem",
          a: {
            marginRight: "1.5rem"
          }
        }}>
        <TabLink to="/settings/emails">Emails</TabLink>
        <TabLink to="/settings/theme">Theme</TabLink>
        <TabLink to="/settings/admins">Admins</TabLink>
        <TabLink to="/settings/insurance">Insurance</TabLink>
        <TabLink to="/settings/reports">Reports</TabLink>
      </nav>
      <Route path="/settings/emails">
        <AdminEmailsForm />
      </Route>
      <Route path="/settings/theme">
        <Field label="Dark Mode">
          <Toggle
            checked={theme.primaryText == darkTheme.primaryText}
            onChange={() =>
              setTheme(x => (x == darkTheme ? lightTheme : darkTheme))
            }>
            Enable dark mode
          </Toggle>
        </Field>
      </Route>
      <Route path="/settings/insurance">
        <Insurances />
      </Route>
      <Route path="/settings/admins">
        <AdminUsersForm />
      </Route>
      <Route path="/settings/reports">
        <h2>Tours Travelers Expense Worksheet</h2>
        <Button size="small" kind="outline">
          Download
        </Button>
      </Route>
      <Route
        exact
        path="/settings"
        render={() => <Redirect to="/settings/emails" />}
      />
    </>
  )
}
