/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "../theme"

export default function Select({ size, fullWidth, css, ...rest }) {
  const { theme } = useTheme()

  return (
    <div
      css={{
        display: fullWidth ? "block" : "inline-block",
        position: "relative",
        "::before,::after": {
          position: "absolute",
          right: "0.5rem",
          fontSize: "0.5rem",
          pointerEvents: "none"
        },
        "::before": {
          content: "'▲'",
          top: size === "large" ? "0.9rem" : "0.5rem"
        },
        "::after": {
          content: "'▼'",
          bottom: size === "large" ? "0.9rem" : "0.5rem"
        }
      }}>
      <select
        {...rest}
        css={{
          // height: size === "large" ? "3rem" : "2.2rem",
          width: fullWidth ? "100%" : "auto",
          padding: "0.5rem",
          fontSize: size === "large" ? "1.25rem" : "1rem",
          lineHeight: "1.1rem",
          appearance: "none",
          display: "block",
          paddingRight: "1.5rem",
          color: theme.primaryText,
          backgroundColor: theme.foregroundColor,
          border: "1px solid " + theme.borderColor,
          borderRadius: 3,
          ":disabled": {
            color: theme.hintText,
            backgroundColor: theme.underlayColor
          },
          ":focus": {
            borderColor: theme.accentText
          },
          ":-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 30px ${theme.foregroundColor} inset !important`,
            WebkitTextFillColor: `${theme.primaryText} !important`,
            border: `1px solid ${theme.borderColor} !important`
          },
          "::-ms-expand": {
            display: "none"
          }
        }}
      />
    </div>
  )
}
