/** @jsx jsx */
import { jsx } from "@emotion/core"
import pluralize from "pluralize"
import React from "react"
import * as Icon from "react-feather"
import { Helmet } from "react-helmet"
import { Link, useHistory, useParams } from "react-router-dom"
import Avatar from "../components/Avatar"
import Button from "../components/Button"
import ErrorBoundary from "../components/ErrorBoundary"
import Field from "../components/Field"
import Loading from "../components/Loading"
import PasswordInput from "../components/PasswordInput"
import ProcessingOverlay from "../components/ProcessingOverlay"
import SignatureInput from "../components/SignatureInput"
import Tag from "../components/Tag"
import Tooltip from "../components/Tooltip"
import { db, functions } from "../firebase"
import AccessCodeForm from "../forms/AccessCodeForm"
import { InvoiceLineItems } from "../forms/InvoiceForm"
import PaymentForm from "../forms/PaymentForm"
import RegistrantForm from "../forms/RegistrantForm"
import useLocalStorage from "../hooks/useLocalStorage"
import NotFound from "../screens/NotFound"
import { darkTheme, useTheme } from "../theme"
import {
  displayDate,
  displayDateRange,
  displayFullName,
  displayMoney,
  displayName
} from "../utils/format"
import { getGroupInvoiceData } from "../utils/getGroupInvoiceData"

const STEP_PASSENGERS = 0
const STEP_PAYDEPOSIT = 1
const STEP_ACCESSCODE = 2
const STEP_SUCCESS = 3

export function RegisterSteps({ tour, offerings, extensions, insurance }) {
  const { theme } = useTheme()

  const [step, setStep] = React.useState(STEP_PASSENGERS)
  const [checkingUser, setCheckingUser] = React.useState(false)
  const [userExists, setUserExists] = React.useState(null)
  const [editingRegistrant, setEditingRegistrant] = React.useState(null)
  const [validAccessCode, setValidAccessCode] = React.useState(null)
  const [registrants, setRegistrants, clearRegistrants] = useLocalStorage(
    `register:${tour.id}:registrants`,
    []
  )
  const [submitting, setSubmitting] = React.useState(false)

  React.useEffect(() => {
    // scroll to top of form whenever step changes
    window.scrollTo(0, 0)
    // reset editing states whenever step changes
    setEditingRegistrant(null)
    // reset access code
    if (step === STEP_ACCESSCODE) {
      setValidAccessCode(null)
    }
  }, [step])

  const [registrant, ...subregistrants] = registrants
  const groupInvoiceData = React.useMemo(() => {
    const registrations = registrants.map(x => {
      return { ...x, tour_leader_yes: !!validAccessCode }
    })
    return getGroupInvoiceData(registrations, tour, insurance, extensions)
  }, [registrants, tour, insurance, extensions, validAccessCode])
  const depositAmount = validAccessCode ? 0 : groupInvoiceData.depositTotal

  async function handleCheckUserExists(registrant) {
    const callUserExists = functions.httpsCallable("checkUserExists")
    const userExists = await callUserExists({ email: registrant.email })
    setUserExists(userExists.data.userExists)
  }

  async function handleFinalizeTravelers(registrant) {
    setCheckingUser(true)
    await handleCheckUserExists(registrant)
    setStep(STEP_PAYDEPOSIT)
    setCheckingUser(false)
  }

  async function handleSubmit({ token, amount, signature, password }) {
    const register = functions.httpsCallable("register")
    await register({
      token: token,
      terms_signature: signature,
      deposit_amount: amount,
      registrant: registrant,
      subregistrants: subregistrants,
      access_code: validAccessCode,
      password: password
    })
    // update registrants so success step can show details
    setRegistrants(registrants => {
      return registrants.map((registrant, index) => {
        if (index == 0) {
          return { ...registrant, paid: amount }
        }
        return registrant
      })
    })

    // show success/confirmation
    setStep(STEP_SUCCESS)
    // clear local storage
    clearRegistrants()
  }

  function updateRegistrant(data, status) {
    console.log("updateRegistrant", data, status)
    if (data.id) {
      setRegistrants(registrants => {
        return registrants.map(registrant => {
          if (registrant.id == data.id) {
            return data
          }
          return registrant
        })
      })
    } else {
      setRegistrants(registrants => {
        return registrants.concat({ ...data, id: registrants.length + 1 })
      })
    }
    if (status === "add-traveler") {
      setEditingRegistrant({})
    } else if (status === "finalize-travelers") {
      handleFinalizeTravelers(registrants?.[0] || data)
      setEditingRegistrant(null)
    } else {
      setEditingRegistrant(null)
    }
    window.scrollTo(0, 0)
  }

  return (
    <div css={{ maxWidth: "30rem", margin: "0 auto" }}>
      {step === STEP_SUCCESS ? null : (
        <header
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
          <Button
            kind="link"
            onClick={() => {
              setEditingRegistrant(null)
              setStep(STEP_PASSENGERS)
            }}>
            Traveler Information
          </Button>
          &nbsp;>&nbsp;
          <Button
            kind="link"
            disabled={!registrants.length}
            onClick={() => setStep(STEP_PAYDEPOSIT)}>
            Pay Deposit
          </Button>
        </header>
      )}
      {step === STEP_PASSENGERS ? (
        <>
          <h2 css={{ textAlign: "center" }}>Travelers</h2>
          {editingRegistrant ? (
            <>
              {editingRegistrant.id ? (
                <h2 css={{ textAlign: "center" }}>Edit Traveler</h2>
              ) : (
                <h2 css={{ textAlign: "center" }}>Add Traveler</h2>
              )}
              <RegistrantForm
                tour={tour}
                insurance={insurance}
                offerings={offerings}
                extensions={extensions}
                registrant={editingRegistrant}
                primary={registrant}
                canEditTrip={true}
                onSubmit={updateRegistrant}
                prompt="Save"
                hideSubmit={editingRegistrant.id ? false : true}>
                {editingRegistrant.id
                  ? undefined
                  : formik => (
                      <>
                        <Button
                          size="large"
                          kind="outline"
                          disabled={formik.isSubmitting}
                          onClick={() => {
                            formik.setStatus("add-traveler")
                            formik.submitForm()
                          }}>
                          {formik.isSubmitting
                            ? "Saving..."
                            : "Add another traveler"}
                        </Button>
                        <div css={{ display: "flex", alignItems: "center" }}>
                          <hr css={{ margin: 0, flex: 1 }} />
                          <small css={{ margin: "0 0.5rem" }}>OR</small>
                          <hr css={{ margin: 0, flex: 1 }} />
                        </div>
                        <Button
                          size="large"
                          kind="fill"
                          disabled={formik.isSubmitting || checkingUser}
                          onClick={() => {
                            formik.setStatus("finalize-travelers")
                            formik.submitForm()
                          }}>
                          {formik.isSubmitting || checkingUser
                            ? "Please wait..."
                            : "Continue to payment"}
                        </Button>
                      </>
                    )}
              </RegistrantForm>
            </>
          ) : registrants.length ? (
            <>
              <ul css={{ margin: 0, padding: 0, listStyle: "none" }}>
                {registrants.map((p, i) => {
                  return (
                    <li
                      key={p.id}
                      css={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem"
                      }}>
                      <Avatar user={p} size={36} />
                      <div
                        css={{ flexGrow: 1, minWidth: 1, margin: "0 0.5rem" }}>
                        <h5 css={{ margin: 0 }} className="num-lines-1">
                          {displayFullName(p)}
                        </h5>
                        {(i == 0 || p.email !== registrant.email) && (
                          <small className="num-lines-1">{p.email}</small>
                        )}
                      </div>
                      <Tooltip label="Edit">
                        <Button
                          size="small"
                          kind="outline"
                          onClick={() => setEditingRegistrant(p)}>
                          <Icon.Edit size="1em" title="Edit" />
                        </Button>
                      </Tooltip>
                      <Tooltip label="Remove">
                        <Button
                          size="small"
                          kind="outline"
                          css={{ marginLeft: "0.5rem" }}
                          onClick={() => {
                            if (
                              window.confirm(
                                `Are you sure you want to remove ${displayName(
                                  p
                                )} as a traveler?`
                              )
                            ) {
                              setRegistrants(
                                registrants.filter(x => x.id !== p.id)
                              )
                            }
                          }}>
                          <Icon.Trash2 size="1em" />
                        </Button>
                      </Tooltip>
                    </li>
                  )
                })}
              </ul>
              <br />
              <Button
                size="large"
                kind="outline"
                onClick={() => setEditingRegistrant({})}>
                Add another traveler
              </Button>
              <div css={{ display: "flex", alignItems: "center" }}>
                <hr css={{ margin: 0, flex: 1 }} />
                <small css={{ margin: "0 0.5rem" }}>OR</small>
                <hr css={{ margin: 0, flex: 1 }} />
              </div>
              <Button
                size="large"
                kind="fill"
                disabled={checkingUser}
                onClick={() => {
                  handleFinalizeTravelers(registrant)
                  setEditingRegistrant(null)
                }}>
                {checkingUser ? "Please wait..." : "Continue to payment"}
              </Button>
            </>
          ) : (
            <RegistrantForm
              tour={tour}
              insurance={insurance}
              offerings={offerings}
              extensions={extensions}
              onSubmit={updateRegistrant}
              canEditTrip={true}
              hideSubmit={true}>
              {formik => (
                <>
                  <Button
                    size="large"
                    kind="outline"
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      formik.setStatus("add-traveler")
                      formik.submitForm()
                    }}>
                    {formik.isSubmitting ? "Saving..." : "Add another traveler"}
                  </Button>
                  <div css={{ display: "flex", alignItems: "center" }}>
                    <hr css={{ margin: 0, flex: 1 }} />
                    <small css={{ margin: "0 0.5rem" }}>OR</small>
                    <hr css={{ margin: 0, flex: 1 }} />
                  </div>
                  <Button
                    size="large"
                    kind="fill"
                    disabled={formik.isSubmitting || checkingUser}
                    onClick={() => {
                      formik.setStatus("finalize-travelers")
                      formik.submitForm()
                    }}>
                    {formik.isSubmitting || checkingUser
                      ? "Please wait..."
                      : "Continue to payment"}
                  </Button>
                </>
              )}
            </RegistrantForm>
          )}
        </>
      ) : step === STEP_PAYDEPOSIT ? (
        <>
          <h2 css={{ textAlign: "center" }}>Pay Deposit</h2>
          <Field label="Summary">
            <div
              css={{
                position: "relative",
                backgroundColor: theme.foregroundColor,
                border: `1px solid ${theme.borderColor}`,
                borderRadius: 3
              }}>
              <table
                css={{
                  margin: 0,
                  width: "100%",
                  padding: "0.25rem",
                  zoom: 0.8,
                  "@media (max-width: 800px)": {
                    zoom: 0.6
                  }
                }}>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody
                  css={{
                    "td:not(:first-of-type)": {
                      whiteSpace: "pre"
                    }
                  }}>
                  <InvoiceLineItems
                    tour={tour}
                    extensions={extensions}
                    registrant={registrant}
                    registrants={registrants}
                    groupInvoiceData={groupInvoiceData}
                  />
                  <tr>
                    <td colSpan={4}>
                      <hr css={{ margin: "1px 0" }} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <h6 css={{ margin: 0, textAlign: "right" }}>Total</h6>
                    </td>
                    <td>{displayMoney(groupInvoiceData.totalDue)}</td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <h6 css={{ margin: 0, textAlign: "right" }}>Due Today</h6>
                    </td>
                    <td>{displayMoney(depositAmount)}</td>
                  </tr>
                </tbody>
                {groupInvoiceData.totalDue ? (
                  <tfoot>
                    <tr>
                      <td colSpan={4}>
                        <hr css={{ margin: "1px 0" }} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        Remaining tour balance is due no later than{" "}
                        {displayDate(groupInvoiceData.dueDate)} to avoid a late
                        payment fee of $95 per person.
                        {tour.processing_fee_disabled
                          ? ""
                          : " All card payments after deposit will have a 2.5% processing fee applied."}
                      </td>
                    </tr>
                  </tfoot>
                ) : null}
              </table>
            </div>
          </Field>
          {validAccessCode ? (
            <p>
              Using tour leader free access code: <Tag>{validAccessCode}</Tag>{" "}
              <Button kind="link" onClick={() => setStep(STEP_ACCESSCODE)}>
                Use a different code.
              </Button>
            </p>
          ) : (
            <p>
              Have a tour leader access code?{" "}
              <Button kind="link" onClick={() => setStep(STEP_ACCESSCODE)}>
                Enter your code now.
              </Button>
            </p>
          )}
          {depositAmount > 0 ? (
            <PaymentForm
              amountLabel="Deposit Amount"
              minAmount={depositAmount}
              maxAmount={depositAmount}
              // maxAmount={groupInvoiceData.totalDue}
              prompt={amount => `Book trip and pay ${displayMoney(amount)}`}
              onSubmit={handleSubmit}
              terms={true}
              newUserEmail={userExists ? null : registrant.email}
              onError={handleCheckUserExists}
            />
          ) : (
            <form
              onSubmit={async e => {
                e.preventDefault()
                const formData = new FormData(e.target)
                const signature = formData.get("terms_signature")
                const password = formData.get("password")
                try {
                  setSubmitting(true)
                  await handleSubmit({
                    signature: signature,
                    token: null,
                    amount: 0,
                    password: password
                  })
                } catch (error) {
                  throw error
                } finally {
                  setSubmitting(false)
                }
              }}>
              <SignatureInput />
              {!userExists && <PasswordInput email={registrant.email} />}
              <Button size="large" kind="fill">
                {submitting ? "Submitting..." : "Book trip for free"}
              </Button>
              {submitting && (
                <ProcessingOverlay title="Processing Registration" />
              )}
            </form>
          )}
          <footer css={{ textAlign: "center" }}>
            <Button
              type="button"
              kind="link"
              onClick={() => setStep(STEP_PASSENGERS)}>
              Go Back
            </Button>
          </footer>
        </>
      ) : step == STEP_ACCESSCODE ? (
        <>
          <h2 css={{ textAlign: "center" }}>Tour Leader Access</h2>
          <AccessCodeForm
            onSubmit={async accessCode => {
              const checkAccessCode = functions.httpsCallable("checkAccessCode")
              const result = await checkAccessCode({
                tour_id: tour.id,
                access_code: accessCode
              })
              if (result.data.valid) {
                setValidAccessCode(accessCode)
                setStep(STEP_PAYDEPOSIT)
              }
            }}
          />
          <footer css={{ textAlign: "center" }}>
            <Button
              type="button"
              kind="link"
              onClick={() => setStep(STEP_PAYDEPOSIT)}>
              Go Back
            </Button>
          </footer>
        </>
      ) : step === STEP_SUCCESS ? (
        <>
          <h1 css={{ textAlign: "center" }}>Success!</h1>
          <p>
            Thank you from Faith Based Expeditions. We have received the deposit
            for your tour {pluralize("reservation", registrants.length)}.
          </p>
          <br />
          <h5>Upcoming Tour Details:</h5>
          <hr />
          <p>
            Tour Dates:{" "}
            {displayDateRange(
              registrant.tour_start_date,
              registrant.tour_end_date
            )}
          </p>
          <p>
            Tour Departure:{" "}
            {registrant.airtravel_yes ? registrant.tour_departure : "n/a"}
          </p>
          <p>Deposit Amount: {displayMoney(registrant.paid)}</p>
          <p>Tickets Reserved: {registrants.length}</p>
          {/* <p>Terms & Conditions: Accepted by {displayName(registrant)}</p> */}
          <hr />
          <h5>Tour Package Options:</h5>
          <hr />
          <p>Tour Extension: {registrant.extension_id ? "yes" : "no"}</p>
          <p>Single Supplement: {registrant.supplement_yes ? "yes" : "no"}</p>
          <p>Insurance: {registrant.insurance_yes ? "yes" : "no"}</p>
          <hr />
          <h5>Travelers:</h5>
          <hr />
          {registrants.map(x => (
            <p key={x.id}>{displayFullName(x)}</p>
          ))}
          <hr />
          <br />
          <footer css={{ textAlign: "center" }}>
            <Button size="large" kind="fill" to="/signin?justregistered=true">
              Go to my trip dashboard
            </Button>
            <a
              href={`https://faithbasedexpeditions.com/tour/${
                tour.tour_code || ""
              }`}
              target="_blank"
              rel="noopener noreferrer">
              Or go to tour page
            </a>
          </footer>
          <br />
          <br />
        </>
      ) : null}
    </div>
  )
}

export default function Register() {
  const { theme } = useTheme()
  const { tourCode } = useParams()
  const { location } = useHistory()

  // tour data
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [tour, setTour] = React.useState(null)
  const [offerings, setOfferings] = React.useState([])
  const [extensions, setExtensions] = React.useState([])
  const [insurance, setInsurance] = React.useState(null)

  // register data

  React.useEffect(() => {
    loadData(tourCode)
  }, [tourCode])

  async function loadData(tourCode) {
    try {
      setError(null)
      setLoading(true)

      const tourQuery = await db
        .collection("tours")
        .where("inactive", "==", false)
        .where("tour_code", "==", String(tourCode).toUpperCase())
        .get()

      if (tourQuery.empty) {
        throw new Error("Matching tour code not found.")
      }

      const tour = tourQuery.docs[0].data()
      const tourRef = tourQuery.docs[0].ref

      if (!tour) {
        throw new Error("Tour not found.")
      }

      if (tour.inactive) {
        throw new Error("Tour is inactive.")
      }

      const offeringsRef = tourRef.collection("offerings")
      const offeringDocs = await offeringsRef.get()
      const offerings = []
      offeringDocs.forEach(offeringDoc => {
        const offering = offeringDoc.data()
        offerings.push(offering)
      })

      if (!offerings.length) {
        throw new Error("Tour offerings not found.")
      }

      const extensionsRef = tourRef
        .collection("extensions")
        .where("inactive", "==", false)
      const extensionDocs = await extensionsRef.get()
      const extensions = []
      extensionDocs.forEach(extensionDoc => {
        extensions.push(extensionDoc.data())
      })

      let insurance = null
      if (tour.insurance_id) {
        const insuranceRef = db.collection("insurances").doc(tour.insurance_id)
        const insuranceDoc = await insuranceRef.get()
        insurance = insuranceDoc.data()
        if (!insurance) {
          throw new Error("Tour missing required data.")
        }
      }

      setTour(tour)
      setOfferings(offerings)
      setExtensions(extensions)
      setInsurance(insurance)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  if (error) {
    console.error(error)
    return <NotFound />
  }

  if (!tour || loading) {
    return <Loading />
  }

  return (
    <>
      <Helmet>
        <title>Register for {tour.title} | Faith Based Expeditions</title>
      </Helmet>
      <div
        css={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "stretch",
          "@media (max-width: 1000px)": {
            flexDirection: "column"
          }
        }}>
        <div
          css={{
            position: "relative",
            width: "50vw",
            "@media (max-width: 1200px)": {
              width: "40vw"
            },
            "@media (max-width: 1000px)": {
              width: "100vw"
            }
          }}>
          <div
            css={{
              position: "fixed",
              top: 0,
              left: 0,
              padding: "4rem",
              width: "50vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              backgroundSize: "cover",
              backgroundPosition: "left center",
              backgroundImage: `url(${darkTheme.patternImage})`,
              backgroundColor: darkTheme.backgroundColor,
              borderRight: `1px solid ${theme.accentText}`,
              "h1, h2, h5": {
                margin: "0 0.1rem",
                color: darkTheme.primaryText
              },
              "@media (max-width: 1200px)": {
                width: "40vw"
              },
              "@media (max-width: 1000px)": {
                width: "100%",
                height: "auto",
                position: "static",
                display: "block",
                padding: "0.5rem 2rem",
                borderRight: "none",
                borderBottom: `1px solid ${theme.accentText}`,
                h5: {
                  fontSize: "0.875rem",
                  width: "100%"
                },
                "h1, h2": {
                  fontSize: "1.25rem"
                }
              }
            }}>
            <div
              css={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                padding: "0 0.5rem",
                display: "flex",
                alignItems: "center",
                "@media (max-width: 1000px)": {
                  bottom: 0
                }
              }}>
              {location.state && location.state.from ? (
                <Link to={location.state.from}>
                  <h4 css={{ color: darkTheme.primaryText }}>
                    <Icon.ArrowLeft size="1rem" />
                    &nbsp;
                    <span
                      css={{
                        "@media (max-width: 1000px)": {
                          display: "none"
                        }
                      }}>
                      Go to tour page
                    </span>
                  </h4>
                </Link>
              ) : (
                <a
                  href={`https://faithbasedexpeditions.com/tour/${tour.tour_code}`}>
                  <h4 css={{ color: darkTheme.primaryText }}>
                    <Icon.ArrowLeft size="1rem" />
                    &nbsp;
                    <span
                      css={{
                        "@media (max-width: 1000px)": {
                          display: "none"
                        }
                      }}>
                      Go to tour page
                    </span>
                  </h4>
                </a>
              )}
            </div>
            {offerings.length == 1 && (
              <h5>
                {displayDateRange(
                  offerings[0].tour_start_date,
                  offerings[0].tour_end_date
                )}
              </h5>
            )}
            <h2 css={{ margin: 0 }} className="num-lines-2">
              {tour.title}
            </h2>
          </div>
        </div>
        <div
          css={{
            width: "50vw",
            padding: "2rem",
            "@media (max-width: 1200px)": {
              width: "60vw"
            },
            "@media (max-width: 1000px)": {
              width: "100vw"
            }
          }}>
          <ErrorBoundary>
            <RegisterSteps
              tour={tour}
              offerings={offerings}
              extensions={extensions}
              insurance={insurance}
            />
          </ErrorBoundary>
        </div>
      </div>
    </>
  )
}
