/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import * as Icon from "react-feather"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { useStore } from "../store"
import { darkTheme, useTheme } from "../theme"
import ManifestForm from "../forms/ManifestForm"

export default function Manifest() {
  const { theme } = useTheme()
  const { user } = useStore()

  return (
    <>
      <Helmet>
        <title>Access Tour Manifest | Faith Based Expeditions</title>
      </Helmet>
      <div
        css={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "stretch",
          "@media (max-width: 1000px)": {
            flexDirection: "column"
          }
        }}>
        <div
          css={{
            position: "relative",
            width: "50vw",
            "@media (max-width: 1200px)": {
              width: "40vw"
            },
            "@media (max-width: 1000px)": {
              width: "100vw"
            }
          }}>
          <div
            css={{
              position: "fixed",
              top: 0,
              left: 0,
              padding: "4rem",
              width: "50vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              backgroundSize: "cover",
              backgroundPosition: "left center",
              backgroundImage: `url(${darkTheme.patternImage})`,
              backgroundColor: darkTheme.backgroundColor,
              borderRight: `1px solid ${theme.accentText}`,
              "h1, h2, h5": {
                margin: "0 0.1rem",
                color: darkTheme.primaryText
              },
              "@media (max-width: 1200px)": {
                width: "40vw"
              },
              "@media (max-width: 1000px)": {
                minHeight: 40,
                width: "100%",
                height: "auto",
                position: "static",
                display: "block",
                padding: "0.5rem 2rem",
                borderRight: "none",
                borderBottom: `1px solid ${theme.accentText}`,
                h5: {
                  fontSize: "0.875rem",
                  width: "100%"
                },
                "h1, h2": {
                  fontSize: "1.25rem"
                }
              }
            }}>
            <div
              css={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                padding: "0 0.5rem",
                display: "flex",
                alignItems: "center",
                "@media (max-width: 1000px)": {
                  bottom: 0
                }
              }}>
              <Link to="/">
                <h4 css={{ color: darkTheme.primaryText }}>
                  <Icon.ArrowLeft size="1rem" />
                  &nbsp;
                  <span
                    css={{
                      "@media (max-width: 1000px)": {
                        display: "none"
                      }
                    }}>
                    {user ? "Go to dashboard" : "Go to sign in"}
                  </span>
                </h4>
              </Link>
            </div>
            <h1>Tour Manifest</h1>
            <h5>DOWNLOAD PAGE</h5>
          </div>
        </div>
        <div
          css={{
            width: "50vw",
            padding: "2rem",
            "@media (max-width: 1200px)": {
              width: "60vw"
            },
            "@media (max-width: 1000px)": {
              width: "100vw"
            }
          }}>
          <div css={{ maxWidth: "30rem", margin: "0 auto" }}>
            <h2 css={{ textAlign: "center" }}>Access Your Manifest</h2>
            <ManifestForm />
          </div>
        </div>
      </div>
    </>
  )
}
