import "./polyfills"
import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/browser"
import App from "./App"

const rootEl = document.getElementById("root")

ReactDOM.render(<App />, rootEl)

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
}

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default
    ReactDOM.render(<NextApp />, rootEl)
  })
}
