/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { Helmet } from "react-helmet"
import { Redirect } from "react-router-dom"
import pluralize from "pluralize"
import { db } from "../firebase"
import { useStore } from "../store"
import Loading from "../components/Loading"
import TripCard from "../components/TripCard"

export default function TripBrowse() {
  const { user } = useStore()

  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [registrations, setRegistrations] = React.useState([])
  const [tours, setTours] = React.useState({})

  React.useEffect(() => {
    loadData()
  }, [])

  async function loadData() {
    try {
      setError(null)
      setLoading(true)
      const registrations = []
      const registrationsRef = db
        .collection("registrants")
        .where("email", "==", user.email)
        .where("inactive", "==", false)
      const registrantionDocs = await registrationsRef.get()
      registrantionDocs.forEach(async registrationDoc => {
        registrations.push(registrationDoc.data())
      })
      const tours = {}
      const tourRefs = registrations.map(x => db.collection("tours").doc(x.tour_id)) // prettier-ignore
      const tourDocs = await Promise.all(tourRefs.map(x => x.get()))
      tourDocs.forEach(tourDoc => {
        tours[tourDoc.id] = tourDoc.data()
      })
      setRegistrations(registrations)
      setTours(tours)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>
  }

  const trips = registrations
    .filter(x => tours[x.tour_id] && !tours[x.tour_id].inactive) // remove inactive
    .filter((x, i, r) => r.findIndex(y => y.tour_id == x.tour_id) >= i) // remove dedups

  if (!trips.length) {
    return <p>No active trips found.</p>
  }

  if (trips.length === 1) {
    return <Redirect to={`/registrations/${trips[0].id}`} />
  }

  return (
    <>
      <Helmet>
        <title>My Trips | Faith Based Expeditions</title>
      </Helmet>
      <h1>Your {pluralize("Trip", registrations.length)}</h1>
      <ul css={{ margin: 0, padding: 0, listStyle: "none" }}>
        {trips.map(x => (
          <li key={x.tour_id} css={{ margin: "1rem 0" }}>
            <TripCard
              registrant={x}
              tour={tours[x.tour_id]}
              to={`/registrations/${x.id}`}
            />
          </li>
        ))}
      </ul>
    </>
  )
}
