/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom"
import { StripeProvider } from "react-stripe-elements"
import Layout from "./components/Layout"
import Loading from "./components/Loading"
import Styles from "./components/Styles"
import Activity from "./screens/Activity"
import MaintenanceMode from "./screens/MaintenanceMode"
import Manifest from "./screens/Manifest"
import NotFound from "./screens/NotFound"
import Register from "./screens/Register"
import RegistrantDetails from "./screens/RegistrantDetails"
import RegistrantsBrowse from "./screens/RegistrantsBrowse"
import Settings from "./screens/Settings"
import SignIn from "./screens/SignIn"
import SignInConfirm from "./screens/SignInConfirm"
import StyleGuide from "./screens/StyleGuide"
import TourDetails from "./screens/TourDetails"
import ToursBrowse from "./screens/ToursBrowse"
import TripBrowse from "./screens/TripBrowse"
import VerifyEmail from "./screens/VerifyEmail"
import Store, { useStore } from "./store"
import { ThemeProvider } from "./theme"

function UserDashboard() {
  return (
    <Layout>
      <Switch>
        <Route path="/registrations/:registrantId">
          <RegistrantDetails />
        </Route>
        <Route path="/registrations">
          <TripBrowse />
        </Route>
        <Route render={() => <Redirect to="/registrations" />} />
      </Switch>
    </Layout>
  )
}

function AdminDashboard() {
  return (
    <Layout>
      <Switch>
        <Route path={["/tours", "/tours/new"]} exact>
          <ToursBrowse />
        </Route>
        <Route path="/tours/:tourId">
          <TourDetails />
        </Route>
        <Route path="/registrations/:registrantId">
          <RegistrantDetails />
        </Route>
        <Route path="/registrations">
          <RegistrantsBrowse />
        </Route>
        <Route path="/activity">
          <Activity />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/" exact render={() => <Redirect to="/tours" />} />
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Layout>
  )
}

function Dashboard() {
  const { user, isAdmin } = useStore()

  if (user) {
    if (!user.emailVerified) {
      return <VerifyEmail />
    }
    if (isAdmin) {
      return <AdminDashboard />
    }

    return <UserDashboard />
  }

  return <Redirect to="/signin" />
}

function Routes() {
  const { authenticating } = useStore()

  if (authenticating) {
    return <Loading />
  }

  return (
    <Router>
      <Switch>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signin-confirm">
          <SignInConfirm />
        </Route>
        <Route path="/register/:tourCode">
          <Register />
        </Route>
        <Route path="/styleguide">
          <StyleGuide />
        </Route>
        <Route path="/manifest">
          <Manifest />
        </Route>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    </Router>
  )
}

const MAINTENANCE_MODE = false

function App() {
  const stripe = React.useMemo(() => {
    return window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  }, [])

  return (
    <StripeProvider stripe={stripe}>
      <ThemeProvider>
        <Store>
          {MAINTENANCE_MODE ? <MaintenanceMode /> : <Routes />}
          <Styles />
        </Store>
      </ThemeProvider>
    </StripeProvider>
  )
}

export default App
