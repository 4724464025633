/** @jsx jsx */
import { jsx } from "@emotion/core"
import queryString from "query-string"
import React from "react"
import { Helmet } from "react-helmet"
import { Link, Redirect, useLocation } from "react-router-dom"
import Alert from "../components/Alert"
import Button from "../components/Button"
import Field from "../components/Field"
import Input from "../components/Input"
import Logo from "../components/Logo"
import firebase, { functions } from "../firebase"
import { useStore } from "../store"
import { useTheme } from "../theme"

const domain =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.REACT_APP_DOMAIN

export default function SignIn() {
  const { search } = useLocation()
  const { justregistered } = queryString.parse(search)

  const { theme } = useTheme()
  const { user } = useStore()

  const [error, setError] = React.useState(null)
  const [submitting, setSubmitting] = React.useState(false)
  const [submittedEmail, setSubmittedEmail] = React.useState(null)
  const [passwordResetSent, setPasswordResetSent] = React.useState(null)
  const [sendingPasswordReset, setSendingPasswordReset] = React.useState(null)

  const [userExists, setUserExists] = React.useState(null)

  if (user) {
    return <Redirect to="/" />
  }

  async function handleSubmitEmail(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const form = document.forms["email"]
    const email = formData.get("email")
    try {
      setError(null)
      setSubmitting(true)
      const checkUserExists = functions.httpsCallable("checkUserExists")
      const userExists = await checkUserExists({ email })
      // storage.set("emailForSignIn", email)
      setSubmittedEmail(email)
      setUserExists(userExists.data.userExists)
      form.reset()
    } catch (error) {
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  async function handleLogin(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const password = formData.get("password")
    try {
      setError(null)
      setSubmitting(true)
      await firebase.auth().signInWithEmailAndPassword(submittedEmail, password)
    } catch (error) {
      setError(error)
      setSubmitting(false)
    }
  }

  async function handleCreateAccount(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const password = formData.get("password")

    try {
      setError(null)
      setSubmitting(true)
      await firebase
        .auth()
        .createUserWithEmailAndPassword(submittedEmail, password)
    } catch (error) {
      setError(error)
      setSubmitting(false)
    }
  }

  async function handleResetPassword(e) {
    e.preventDefault()
    try {
      setError(null)
      setSendingPasswordReset(true)
      await firebase.auth().sendPasswordResetEmail(submittedEmail)
      setPasswordResetSent(true)
    } catch (error) {
      setError(error)
    } finally {
      setSendingPasswordReset(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>Sign In | Faith Based Expeditions</title>
      </Helmet>
      <div
        css={{ position: "relative", maxWidth: "34rem", margin: "20vh auto" }}>
        <div
          css={{
            padding: "2rem",
            borderRadius: 3,
            border: `1px solid ${theme.borderColor}`,
            backgroundImage: `url(${theme.patternImage})`,
            backgroundPosition: "center center",
            backgroundSize: "500%",
            "@media (max-width: 36rem)": {
              margin: "5rem 1rem"
            }
          }}>
          <Logo
            css={{
              position: "absolute",
              left: "50%",
              top: 0,
              transform: "translate3d(-50%,-50%,0)"
            }}
          />
          {/* OnSubmit */}
          {submittedEmail && userExists ? (
            <form onSubmit={handleLogin}>
              <header
                css={{
                  margin: "2rem auto",
                  maxWidth: "24rem",
                  textAlign: "center"
                }}>
                <h1 css={{ margin: 0 }}>Welcome Back!</h1>
                <p css={{ margin: 0 }}>
                  Enter your password for {submittedEmail}.
                </p>
              </header>
              <Field label="Password">
                <Input name="password" type="password" size="large" />
                <div
                  css={{
                    justifyContent: "flex-end",
                    width: "100%",
                    display: "flex",
                    marginTop: 5
                  }}>
                  {sendingPasswordReset || passwordResetSent ? (
                    <p
                      css={{
                        margin: 0
                      }}>
                      {sendingPasswordReset
                        ? "Sending password reset email…"
                        : "Check your email for a link to reset your password"}
                    </p>
                  ) : (
                    <button
                      css={{
                        color: theme.accentText,
                        margin: 0,
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      type="button"
                      disabled={sendingPasswordReset || passwordResetSent}
                      onClick={handleResetPassword}>
                      Forgot password?
                    </button>
                  )}
                </div>
              </Field>

              <Button
                size="large"
                kind="fill"
                type="submit"
                disabled={submitting}>
                {submitting ? "Logging in..." : "Login"}
              </Button>

              <footer css={{ textAlign: "center" }}>
                {!!error && <Alert error={error} />}
              </footer>
            </form>
          ) : !userExists && submittedEmail ? (
            <form onSubmit={handleCreateAccount}>
              <header
                css={{
                  margin: "2rem auto",
                  maxWidth: "28rem",
                  textAlign: "center"
                }}>
                <h1 css={{ margin: 0 }}>Let's Create a Login!</h1>
                <p css={{ margin: 0 }}>
                  Create a password for {submittedEmail}.
                </p>
              </header>
              <Field label="Password">
                <Input name="password" type="password" size="large" />
                <div
                  css={{
                    justifyContent: "flex-end",
                    width: "100%",
                    display: "flex",
                    marginTop: 5
                  }}></div>
              </Field>
              <Button
                size="large"
                kind="fill"
                type="submit"
                disabled={submitting}>
                {submitting ? "Creating Account..." : "Create Account"}
              </Button>
              <footer css={{ textAlign: "center" }}>
                {!!error && <Alert error={error} />}
              </footer>
            </form>
          ) : (
            <form onSubmit={handleSubmitEmail} name="email">
              <header
                css={{
                  margin: "2rem auto",
                  maxWidth: "24rem",
                  textAlign: "center"
                }}>
                <h1 css={{ margin: 0 }}>Welcome Back!</h1>
                <p css={{ margin: 0 }}>
                  {justregistered
                    ? "To continue to your dashboard you must enter an email used when you registered."
                    : "Enter your email to continue."}
                </p>
              </header>
              <Field label="Email Address">
                <Input name="email" type="email" size="large" />
              </Field>
              <Button
                size="large"
                kind="fill"
                type="submit"
                disabled={submitting}>
                {submitting ? "Submitting..." : "Continue"}
              </Button>
              <footer css={{ textAlign: "center" }}>
                {!!error && <Alert error={error} />}
              </footer>
            </form>
          )}
        </div>
        {submittedEmail && (
          <p css={{ textAlign: "center" }}>
            If this email is incorrect, sign in with a different email{" "}
            <span
              onClick={() => setSubmittedEmail(null)}
              css={{
                textDecoration: "underline",
                color: theme.accentText,
                cursor: "pointer"
              }}>
              here
            </span>
            .
          </p>
        )}
        {justregistered ? null : (
          <p css={{ textAlign: "center" }}>
            Are you a tour leader? You access your tour manifest{" "}
            <Link to="/manifest">here</Link>.
          </p>
        )}
      </div>
    </>
  )
}
