/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import * as Icon from "react-feather"
import libphonenumber from "google-libphonenumber"
import phoneFormatter from "phone-formatter"
import { v4 as uuid } from "uuid"
import Alert from "../components/Alert"
import Field from "../components/Field"
import Input from "../components/Input"
import Radio from "../components/Radio"
import Button from "../components/Button"
import Select from "../components/Select"
import Tooltip from "../components/Tooltip"
import Checkbox from "../components/Checkbox"
import FieldRow from "../components/FieldRow"
import Textarea from "../components/Textarea"
import CountrySelect from "../components/CountrySelect"
import DatePicker from "../components/DatePicker"
import {
  displayPhone,
  displayMoney,
  displayAddress,
  displayDateRange
} from "../utils/format"
import { serializeDate, deserializeDate } from "../utils/form"
import { getInvoiceData } from "../utils/getInvoiceData"
import { useTheme } from "../theme"
import { GOLD } from "../colors"
import firebase from "firebase"

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
Yup.addMethod(Yup.string, "phone", function () {
  return this.test({
    name: "phone",
    exclusive: true,
    message: "Invalid phone number",
    test: value => {
      try {
        const phone = phoneUtil.parse(value, "US")
        return phoneUtil.isValidNumber(phone)
      } catch (e) {
        return false
      }
    }
  })
})

// prettier-ignore
const PassengerSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  title: Yup.string().required("Required"),
  name_first: Yup.string().required("Required"),
  name_middle: Yup.string().required("Required"),
  name_last: Yup.string().required("Required"),
  name_tag: Yup.string().nullable(),
  birthdate: Yup.string().required("Required"),
  birthplace: Yup.string().nullable(),
  gender: Yup.string().oneOf(["Male", "Female"]),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().phone().required("Required"),
  passport_number: Yup.string(),
  passport_issue_date: Yup.string().when("passport_number", {
    is: val => !!val,
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable()
  }),
  passport_expiration_date: Yup.string().when("passport_number", {
    is: val => !!val,
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable()
  }),
  passport_country: Yup.string().when("passport_number", {
    is: val => !!val,
    then: Yup.string().required("Required"),
    otherwise: Yup.string()
  }),
  passport_photo: Yup.mixed().nullable(),
  mailing_address_country: Yup.string().required("Required"),
  mailing_address_street: Yup.string().required("Required"),
  mailing_address_city: Yup.string().required("Required"),
  mailing_address_state: Yup.string().required("Required"),
  mailing_address_zip: Yup.string().required("Required"),
  emergency_contact_name: Yup.string(),
  emergency_contact_phone: Yup.string(),
  offering_id: Yup.string().required("Required"),
  extension_id: Yup.string().nullable(),
  airtravel_yes: Yup.boolean().required(),
  insurance_yes: Yup.boolean().required(),
  location: Yup.string().nullable()
})

export default function RegistrantForm({
  prompt = "Submit",
  hideSubmit = false,
  children,
  onSubmit,
  canEditTrip,
  registrant = {},
  primary = {},
  tour,
  insurance,
  offerings = [],
  extensions = [],
  addons = []
}) {
  const { theme } = useTheme()

  // we're editing primary registrant
  const isPrimary = registrant.id === primary.id
  // additional registrant is using primary registrant's contact
  const initialSameContact = !isPrimary && registrant.email == primary.email && registrant.phone == primary.phone // prettier-ignore
  // additional registrant is using primary registrant's address
  const initialSameAddress = !isPrimary && registrant.mailing_address_street == primary.mailing_address_street && registrant.mailing_address_zip == primary.mailing_address_zip // prettier-ignore
  // additional registrant is using primary registrant's emergency contact
  const initialSameEmergency = !isPrimary && registrant.emergency_contact_phone == primary.emergency_contact_phone // prettier-ignore

  const [sameContact, setSameContact] = React.useState(initialSameContact)
  const [sameAddress, setSameAddress] = React.useState(initialSameAddress)
  const [sameEmergency, setSameEmergency] = React.useState(initialSameEmergency)

  const formik = useFormik({
    // prettier-ignore
    initialValues: {
      id: registrant.id || null,
      title: registrant.title || "Mr.",
      name_first: registrant.name_first || "",
      name_middle: registrant.name_middle || "",
      name_last: registrant.name_last || "",
      gender: registrant.gender || "",
      birthdate: deserializeDate(registrant.birthdate),
      birthplace: registrant.birthplace || "",
      name_tag: registrant.name_tag || "",
      passport_number: registrant.passport_number || "",
      passport_issue_date: deserializeDate(registrant.passport_issue_date),
      passport_expiration_date: deserializeDate(registrant.passport_expiration_date),
      passport_country: registrant.passport_country || "US",
      passport_photo: registrant.passport_photo || null,
      email: registrant.email || "",
      phone: registrant.phone || "",
      mailing_address_country: registrant.mailing_address_country || "US",
      mailing_address_street: registrant.mailing_address_street || "",
      mailing_address_city: registrant.mailing_address_city || "",
      mailing_address_state: registrant.mailing_address_state || "",
      mailing_address_zip: registrant.mailing_address_zip || "",
      roommate_preference: registrant.roommate_preference || "",
      supplement_yes: !!registrant.supplement_yes,
      comments: registrant.comments || "",
      emergency_contact_name: registrant.emergency_contact_name || "",
      emergency_contact_phone: registrant.emergency_contact_phone || "",
      offering_id: registrant.offering_id || offerings[0].id,
      extension_id: registrant.extension_id || null,
      airtravel_yes: registrant.airtravel_yes || false,
      insurance_yes: registrant.insurance_yes || false,
      location: registrant.location || null
    },
    validationSchema: PassengerSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        let passport_photo = null
        if (typeof values.passport_photo === "string") {
          passport_photo = values.passport_photo
        } else {
          if (
            values.passport_photo instanceof File ||
            values.passport_photo instanceof Blob
          ) {
            // use base64 encoded email as folder for permissions check
            const email = btoa((primary.email || values.email).toLowerCase())
            const upload = await firebase
              .storage()
              .ref()
              .child("passports")
              .child(email)
              .child(uuid())
              .put(values.passport_photo)
            passport_photo = upload.ref.fullPath
          }
        }

        const offering = offerings.find(x => x.id == values.offering_id)
        const extension = extensions.find(x => x.id == values.extension_id)
        // prettier-ignore
        const registrantData = {
          ...values,
          birthdate: serializeDate(values.birthdate),
          email: values.email.toLowerCase(),
          phone: phoneFormatter.normalize(String(values.phone)),
          passport_issue_date: serializeDate(values.passport_issue_date),
          passport_expiration_date: serializeDate(
            values.passport_expiration_date
          ),
          passport_photo: passport_photo,
          tour_id: tour.id,
          tour_start_date: offering.tour_start_date,
          tour_end_date: offering.tour_end_date,
          tour_departure: offering.tour_departure,
          tour_price: values.airtravel_yes
            ? offering.air_price
            : offering.land_price,
          extension_name: extension ? extension.title : null,
          extension_price: extension ? extension.price : 0
        }
        console.log({ registrantData })
        await onSubmit(registrantData, formik.status)
      } catch (error) {
        setErrors({ submit: error })
      }
    }
  })

  const insuranceTotal = React.useMemo(() => {
    const offering = offerings.find(x => x.id == formik.values.offering_id)
    const extension = extensions.find(x => x.id == formik.values.extension_id)
    const registrant = {
      // preview registrant
      ...formik.values,
      tour_price: formik.values.airtravel_yes ? offering.air_price : offering.land_price, // prettier-ignore
      extension_price: extension ? extension.price : 0,
      insurance_yes: true // just for preview
    }
    const invoiceData = getInvoiceData(
      registrant,
      tour,
      insurance,
      extensions,
      addons
    )
    return invoiceData.insuranceTotal
  }, [
    tour,
    insurance,
    extensions,
    offerings,
    registrant,
    formik.values.offering_id,
    formik.values.extension_id,
    formik.values.airtravel_yes,
    formik.values.supplement_yes
  ])

  const supplementTotal = React.useMemo(() => {
    const extension = extensions.find(x => x.id == formik.values.extension_id)
    if (extension) {
      return tour.supplement_price + extension.supplement_price
    } else {
      return tour.supplement_price
    }
  }, [tour, extensions, formik.values.extension_id])

  return (
    <form onSubmit={formik.handleSubmit}>
      <h3>Traveler</h3>
      <FieldRow breakpoint={1000}>
        <Field
          label="Title"
          style={{ flexGrow: "0.5" }}
          error={formik.touched.title && formik.errors.title}>
          <Select
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            required>
            {[
              "Mr.",
              "Ms.",
              "Mrs.",
              "Miss.",
              "Master.",
              "Dr.",
              "Rev.",
              "Pastor."
            ].map(title => (
              <option key={title} value={title}>
                {title}
              </option>
            ))}
          </Select>
        </Field>
        <Field
          label="First Name"
          error={formik.touched.name_first && formik.errors.name_first}>
          <Input
            name="name_first"
            type="text"
            value={formik.values.name_first}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Middle Name"
          error={formik.touched.name_middle && formik.errors.name_middle}>
          <Input
            name="name_middle"
            type="text"
            value={formik.values.name_middle}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Last Name"
          error={formik.touched.name_last && formik.errors.name_last}>
          <Input
            name="name_last"
            type="text"
            value={formik.values.name_last}
            onChange={formik.handleChange}
          />
        </Field>
      </FieldRow>
      <FieldRow breakpoint={1000}>
        <Field
          label="Date of Birth"
          error={formik.touched.birthdate && formik.errors.birthdate}>
          <DatePicker
            value={formik.values.birthdate}
            onChange={formik.handleChange("birthdate")}
          />
        </Field>
        <Field
          label="State of Birth"
          error={formik.touched.birthplace && formik.errors.birthplace}>
          <Input
            name="birthplace"
            type="text"
            value={formik.values.birthplace}
            onChange={formik.handleChange}
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field
          label="Preferred Name on Name Tag"
          error={formik.touched.name_tag && formik.errors.name_tag}>
          <Input
            name="name_tag"
            type="text"
            autoComplete="name_tag"
            value={formik.values.name_tag}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Gender"
          error={formik.touched.gender && formik.errors.gender}>
          <Select
            name="gender"
            fullWidth
            value={formik.values.gender}
            onChange={formik.handleChange}>
            <option disabled selected>
              Select...
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
        </Field>
      </FieldRow>
      <br />
      <h3>Passport</h3>
      <small css={{ display: "block", marginTop: "-1rem" }}>
        Enter the following information as it is printed on the passport.{" "}
        <Tooltip label="Individuals waiting to receive a passport or renewing a passport may state this in the space designated for the Passport Number">
          <span>
            <Icon.Info size="0.9em" />
          </span>
        </Tooltip>
      </small>
      <FieldRow>
        <Field
          label="Passport Number"
          tooltip="In the upper right-hand corner of your passport, there's a nine-digit number which is your passport number."
          error={
            formik.touched.passport_number && formik.errors.passport_number
          }>
          <Input
            name="passport_number"
            type="text"
            value={formik.values.passport_number}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Country of Issue"
          error={
            formik.touched.passport_country && formik.errors.passport_country
          }>
          <CountrySelect
            name="passport_country"
            value={formik.values.passport_country}
            onChange={formik.handleChange}
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field
          label="Passport Issue Date"
          error={
            formik.touched.passport_issue_date &&
            formik.errors.passport_issue_date
          }>
          <DatePicker
            name="passport_issue_date"
            type="date"
            value={formik.values.passport_issue_date}
            onChange={formik.handleChange("passport_issue_date")}
          />
        </Field>
        <Field
          label="Passport Expiration Date"
          error={
            formik.touched.passport_expiration_date &&
            formik.errors.passport_expiration_date
          }>
          <DatePicker
            name="passport_expiration_date"
            type="date"
            value={formik.values.passport_expiration_date}
            onChange={formik.handleChange("passport_expiration_date")}
            maxYear={2099}
          />
        </Field>
      </FieldRow>
      <PassportUpload
        value={formik.values.passport_photo}
        onChange={file => formik.setFieldValue("passport_photo", file)}
      />
      <br />
      <br />
      <h3>Contact</h3>
      {!isPrimary && (
        <Field>
          <Checkbox
            checked={sameContact}
            onChange={() => {
              // prettier-ignore
              if (sameContact) {
                setSameContact(false)
                formik.setFieldValue("email", "")
                formik.setFieldValue("phone", "")
              } else {
                setSameContact(true)
                formik.setFieldValue("email", primary.email)
                formik.setFieldValue("phone", primary.phone)
              }
            }}>
            <h6 css={{ margin: 0, color: theme.accentText }}>
              Use {primary.name_first}’s contact info
            </h6>
            <small>
              {primary.email} / {displayPhone(primary.phone)}
            </small>
          </Checkbox>
        </Field>
      )}
      {!sameContact && (
        <>
          <FieldRow breakpoint={1000}>
            <Field
              label="Email Address"
              tooltip="We’ll use this to contact you about registration information or tour updates. You’ll use this email to login and view your registration and tour information."
              error={formik.touched.email && formik.errors.email}>
              <Input
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </Field>
            <Field
              label="Phone"
              error={formik.touched.phone && formik.errors.phone}>
              <Input
                name="phone"
                mask={Input.phoneMask}
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
            </Field>
          </FieldRow>
        </>
      )}
      {!isPrimary && (
        <Field>
          <Checkbox
            checked={sameAddress}
            onChange={() => {
              // prettier-ignore
              if (sameAddress) {
                setSameAddress(false)
                formik.setFieldValue("mailing_address_country", "US")
                formik.setFieldValue("mailing_address_street", "")
                formik.setFieldValue("mailing_address_city", "")
                formik.setFieldValue("mailing_address_state", "")
                formik.setFieldValue("mailing_address_zip", "")
              } else {
                setSameAddress(true)
                formik.setFieldValue("mailing_address_country", primary.mailing_address_country)
                formik.setFieldValue("mailing_address_street", primary.mailing_address_street)
                formik.setFieldValue("mailing_address_city", primary.mailing_address_city)
                formik.setFieldValue("mailing_address_state", primary.mailing_address_state)
                formik.setFieldValue("mailing_address_zip", primary.mailing_address_zip)
              }
            }}>
            <h6 css={{ margin: 0, color: theme.accentText }}>
              Use {primary.name_first}’s address
            </h6>
            <small>{displayAddress(primary)}</small>
          </Checkbox>
        </Field>
      )}
      {!sameAddress && (
        <>
          <Field
            label="Country"
            error={
              formik.touched.mailing_address_country &&
              formik.errors.mailing_address_country
            }>
            <CountrySelect
              name="mailing_address_country"
              value={formik.values.mailing_address_country}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label="Street Address"
            error={
              formik.touched.mailing_address_street &&
              formik.errors.mailing_address_street
            }>
            <Input
              name="mailing_address_street"
              type="text"
              value={formik.values.mailing_address_street}
              onChange={formik.handleChange}
            />
          </Field>
          <FieldRow breakpoint={1000}>
            <Field
              label="City"
              style={{ flex: 3 }}
              error={
                formik.touched.mailing_address_city &&
                formik.errors.mailing_address_city
              }>
              <Input
                name="mailing_address_city"
                type="text"
                value={formik.values.mailing_address_city}
                onChange={formik.handleChange}
              />
            </Field>
            <Field
              label="State / Province"
              error={
                formik.touched.mailing_address_state &&
                formik.errors.mailing_address_state
              }
              style={{ flex: 2 }}>
              <Input
                name="mailing_address_state"
                type="text"
                value={formik.values.mailing_address_state}
                onChange={formik.handleChange}
              />
            </Field>
            <Field
              label="Zip Code"
              error={
                formik.touched.mailing_address_zip &&
                formik.errors.mailing_address_zip
              }
              style={{ flex: 2 }}>
              <Input
                name="mailing_address_zip"
                type="text"
                value={formik.values.mailing_address_zip}
                onChange={formik.handleChange}
              />
            </Field>
          </FieldRow>
        </>
      )}
      <br />
      <h3>Emergency Contact</h3>
      {!isPrimary &&
        primary.emergency_contact_name &&
        primary.emergency_contact_phone && (
          <Field>
            <Checkbox
              checked={sameEmergency}
              onChange={() => {
                // prettier-ignore
                if (sameEmergency) {
                  setSameEmergency(false)
                  formik.setFieldValue("emergency_contact_name", "")
                  formik.setFieldValue("emergency_contact_phone", "")
                } else {
                  setSameEmergency(true)
                  formik.setFieldValue("emergency_contact_name", primary.emergency_contact_name)
                  formik.setFieldValue("emergency_contact_phone", primary.emergency_contact_phone)
                }
              }}>
              <h6 css={{ margin: 0, color: theme.accentText }}>
                Use {primary.name_first}’s emergency contact
              </h6>
              <small>
                {primary.emergency_contact_name} /{" "}
                {displayPhone(primary.emergency_contact_phone)}
              </small>
            </Checkbox>
          </Field>
        )}
      {!sameEmergency && (
        <FieldRow>
          <Field
            label="Name"
            tooltip="Please enter contact details for someone who is not traveling with you."
            error={
              formik.touched.emergency_contact_name &&
              formik.errors.emergency_contact_name
            }>
            <Input
              name="emergency_contact_name"
              value={formik.values.emergency_contact_name}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label="Phone"
            tooltip="Please enter contact details for someone who is not traveling with you."
            error={
              formik.touched.emergency_contact_phone &&
              formik.errors.emergency_contact_phone
            }>
            <Input
              name="emergency_contact_phone"
              mask={Input.phoneMask}
              value={formik.values.emergency_contact_phone}
              onChange={formik.handleChange}
            />
          </Field>
        </FieldRow>
      )}
      <br />
      <h3>Trip Options</h3>
      {!canEditTrip && (
        <div
          css={{
            padding: "1rem",
            borderRadius: 3,
            border: `1px solid ${theme.borderColor}`
          }}>
          <p css={{ marginTop: 0, marginBottom: "0.5rem" }}>
            Changes to your trip cannot be made manually. You must request these
            changes so they can be processed.
          </p>
          <Button
            kind="fill"
            size="small"
            href="mailto:russ@faithbasedexpeditions.com?subject=FBE%20Itinerary%20Change%20Request">
            Request Changes
          </Button>
        </div>
      )}
      <Field
        label="Tour"
        tooltip="Select land only if you plan to buy your own plane tickets. Select land & air if you wish to have air travel costs included in tour price.">
        {offerings.map(x => (
          <React.Fragment key={x.id}>
            {x.land_price ? (
              <Radio
                disabled={!canEditTrip}
                checked={
                  !formik.values.airtravel_yes &&
                  formik.values.offering_id == x.id
                }
                onChange={() => {
                  formik.setFieldValue("offering_id", x.id)
                  formik.setFieldValue("airtravel_yes", false)
                }}
                name="travel"
                value="ground">
                <b>Land travel only</b> — {displayMoney(x.land_price)}
                <br />
                <small>
                  {displayDateRange(x.tour_start_date, x.tour_end_date)}
                </small>
              </Radio>
            ) : null}
            {x.air_price ? (
              <Radio
                disabled={!canEditTrip || x.disable_land_air_option}
                checked={
                  formik.values.airtravel_yes &&
                  formik.values.offering_id == x.id
                }
                onChange={() => {
                  formik.setFieldValue("offering_id", x.id)
                  formik.setFieldValue("airtravel_yes", true)
                }}
                name="travel"
                value="air">
                <b>Land & air travel</b> from {x.tour_departure} —{" "}
                {displayMoney(x.air_price)}
                <br />
                <small>
                  {displayDateRange(x.tour_start_date, x.tour_end_date)}
                </small>
              </Radio>
            ) : null}
          </React.Fragment>
        ))}
      </Field>
      {formik.values.airtravel_yes && !registrant.airtravel_yes ? (
        <Checkbox required name="accurate_yes">
          <small>
            I attest that the traveler name provided, including middle name, is
            as it appears on their current/future passport and understand that
            costly name change fees will be incurred if incorrect.
          </small>
        </Checkbox>
      ) : null}
      {extensions.length > 0 && (
        <Field
          label="Extensions"
          tooltip="Optionally add an extension to your tour itinerary. Note the date changes.">
          <Radio
            checked={!formik.values.extension_id}
            disabled={!canEditTrip}
            name="extension_id"
            onChange={() => formik.setFieldValue("extension_id", null)}>
            No Extension
          </Radio>
          {extensions.map(x => (
            <Radio
              key={x.id}
              name="extension_id"
              checked={formik.values.extension_id == x.id}
              disabled={x.inactive || !canEditTrip}
              onChange={() => formik.setFieldValue("extension_id", x.id)}
              value={x.id}>
              <b css={{ textTransform: "capitalize" }}>{x.title}</b> –{" "}
              {displayMoney(x.price)}
              <br />
              <small>{displayDateRange(x.start_date, x.end_date)}</small>
            </Radio>
          ))}
        </Field>
      )}
      {tour.locations && (
        <Field
          label="Your Location"
          tooltip="Please select whichever option best describes you.">
          <Select
            name="location"
            disabled={!canEditTrip}
            value={formik.values.location}
            onChange={formik.handleChange}
            fullWidth>
            <option disabled selected>
              Select...
            </option>
            {tour.locations.split("\n").map(x => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </Select>
        </Field>
      )}
      <Field label="Insurance">
        <Radio
          disabled={!canEditTrip}
          name="insurance"
          checked={!formik.values.insurance_yes}
          onChange={() => formik.setFieldValue("insurance_yes", false)}>
          No — skip GROUP travel insurance
        </Radio>
        <Radio
          disabled={!canEditTrip}
          name="insurance"
          defaultChecked={formik.values.insurance_yes}
          onChange={() => formik.setFieldValue("insurance_yes", true)}>
          {tour.insurance_id ? (
            <span>
              Yes — add to GROUP travel insurance for{" "}
              {displayMoney(insuranceTotal)}
            </span>
          ) : (
            <span>
              Yes — add to GROUP travel insurance through Travel Guard
            </span>
          )}
        </Radio>
      </Field>
      {!tour.insurance_id &&
        tour.insurance_link &&
        formik.values.insurance_yes && (
          <small
            css={{
              display: "block",
              marginBottom: "2rem",
              padding: "1rem",
              borderRadius: 4,
              border: "1px solid " + GOLD,
              color: GOLD
            }}>
            You will receive a link sent to{" "}
            {primary?.email || formik.values.email || "your email"} so you can
            purchase travel insurance after you register for this tour.
          </small>
        )}
      <FieldRow css={{ alignItems: "center" }}>
        <Field
          label="Preferred Roommate"
          tooltip="By default we try to put 2 persons to a room. You can enter the name of your preferred roommate or choose to room alone for a single supplement charge.">
          <Input
            name="roommate_preference"
            disabled={!canEditTrip || formik.values.supplement_yes}
            value={formik.values.roommate_preference}
            onChange={formik.handleChange}
            placeholder="Enter name..."
          />
        </Field>
        <Field
          label="Room Alone"
          tooltip="By default we try to put 2 persons to a room. You can enter the name of your preferred roommate or choose to room alone for a single supplement charge.">
          <Checkbox
            value={true}
            disabled={!canEditTrip}
            name="supplement_yes"
            checked={formik.values.supplement_yes}
            onChange={formik.handleChange}>
            Check to room alone for additional {displayMoney(supplementTotal)}{" "}
            charge.
          </Checkbox>
        </Field>
      </FieldRow>
      <Field label="Additional Comments">
        <Textarea
          name="comments"
          value={formik.values.comments}
          onChange={formik.handleChange}
          placeholder="Allergies, assistance needed, etc."
        />
      </Field>
      <br />
      {hideSubmit ? null : (
        <Button
          size="large"
          kind="outline"
          type="submit"
          disabled={formik.isSubmitting}>
          {formik.isSubmitting ? "Submitting..." : prompt}
        </Button>
      )}
      {typeof children === "function" ? children(formik) : null}
      {formik.errors.submit ? (
        <Alert error={formik.errors.submit} />
      ) : Object.keys(formik.errors).some(key => formik.touched[key]) ? (
        <Alert error="Please enter valid values into all required fields above." />
      ) : null}
    </form>
  )
}

function PassportUpload({ value, onChange }) {
  const { theme } = useTheme()

  const [preview, setPreview] = useState(null)

  useLayoutEffect(() => {
    // value is a string (url)
    if (typeof value === "string") {
      firebase.storage().ref(value).getDownloadURL().then(setPreview)
    }
    // value is a File or Blob, get object url
    if (value instanceof File || value instanceof Blob) {
      const preview = URL.createObjectURL(value)
      setPreview(preview)
      return () => {
        URL.revokeObjectURL(preview)
      }
    }
  }, [value])

  return (
    <>
      <div>
        <label
          for="files"
          css={{
            padding: 8,
            marginRight: 8,
            display: "inline-block",
            borderRadius: 3,
            cursor: "pointer",
            textAlign: "center",
            textDecoration: "none",
            whiteSpace: "nowrap",
            border: "1px solid " + theme.accentText,
            backgroundColor: theme.foregroundColor,
            color: theme.primaryText
          }}>
          <span css={{ marginRight: 8 }}>Upload Passport Photo</span>
          <Icon.Upload size="1em" color={GOLD} />
        </label>
        <Tooltip
          label={`Please upload a clear image of your passport that includes your photo and personal information.`}>
          <span>
            <Icon.Info size="0.9em" color={GOLD} />
          </span>
        </Tooltip>
      </div>
      <input
        id="files"
        type="file"
        label="Upload"
        style={{ visibility: "hidden" }}
        multiple={false}
        onChange={e => onChange(e.target.files?.[0] || null)}
      />
      {preview ? (
        <img
          src={preview}
          alt="Preview"
          css={{
            width: "100%",
            height: "auto"
          }}
        />
      ) : null}
    </>
  )
}
