import React from "react"
import AsyncSelect from "react-select/async"
import { useStore } from "../store"
import { useTheme } from "../theme"
import { displayFullName } from "../utils/format"
import { getReactSelectStyles } from "./Styles"

export default function RegistrantSelect({ tourId, onChange, ...rest }) {
  const { theme } = useTheme()
  const { searchClient } = useStore()

  const [searchResults, setSearchResults] = React.useState({})

  const searchIndex = React.useMemo(() => {
    return searchClient.initIndex("fbe_registrants")
  }, [searchClient])

  return (
    <AsyncSelect
      {...rest}
      {...getReactSelectStyles(theme)}
      isClearable={true}
      defaultOptions={true}
      loadOptions={async inputValue => {
        const searchResults = await searchIndex.search(inputValue, {
          filters: `tour_id:${tourId}`
        })
        setSearchResults(searchResults)
        return searchResults.hits.map(hit => ({
          label: `${displayFullName(hit)} (${hit.email})`,
          value: hit.id
        }))
      }}
      onChange={option => {
        if (onChange) {
          if (option) {
            onChange(option.value)
          } else {
            onChange(null)
          }
        }
      }}
    />
  )
}
