/** @jsx jsx */
import { jsx } from "@emotion/core"
import firebase from "firebase"
import queryString from "query-string"
import React from "react"
import { Helmet } from "react-helmet"
import { Link, Redirect, useLocation, useHistory } from "react-router-dom"
import { GOLD } from "../colors"
import Alert from "../components/Alert"
import Button from "../components/Button"
import Logo from "../components/Logo"
import { useStore } from "../store"
import { useTheme } from "../theme"

export default function VerifyEmail() {
  const history = useHistory()
  const { search } = useLocation()
  const { justregistered } = queryString.parse(search)

  const { theme } = useTheme()
  const { user, reloadUser } = useStore()

  const [error, setError] = React.useState(null)
  const [submitting, setSubmitting] = React.useState(false)

  React.useEffect(() => {
    if (user && !user.emailVerified) {
      user.sendEmailVerification()
      let interval = setInterval(async () => {
        const reloadedUser = await reloadUser()
        if (reloadedUser?.emailVerified) {
          history.replace("/signin")
        }
      }, 3000)
      return () => clearInterval(interval)
    }
  }, [user])

  if (user?.emailVerified) {
    return <Redirect to="/signin" />
  }

  const handleSendVerificationEmail = () => {
    setError(null)
    setSubmitting(true)
    try {
      user.sendEmailVerification()
    } catch (error) {
      setSubmitting(false)
      setError(error)
    } finally {
    }
  }

  return (
    <>
      <Helmet>
        <title>Sign In | Faith Based Expeditions</title>
      </Helmet>
      <div
        css={{ position: "relative", maxWidth: "34rem", margin: "20vh auto" }}>
        <div
          css={{
            padding: "2rem",
            borderRadius: 3,
            border: `1px solid ${theme.borderColor}`,
            backgroundImage: `url(${theme.patternImage})`,
            backgroundPosition: "center center",
            backgroundSize: "500%",
            "@media (max-width: 36rem)": {
              margin: "5rem 1rem"
            }
          }}>
          <Logo
            css={{
              position: "absolute",
              left: "50%",
              top: 0,
              transform: "translate3d(-50%,-50%,0)"
            }}
          />

          <header
            css={{
              margin: "2rem auto",
              maxWidth: "24rem",
              textAlign: "center"
            }}>
            <h1 css={{ margin: 0 }}>Verify your Email Address</h1>
            <p css={{ margin: 0 }}>
              Please check your email and follow the instructions to verify your
              email address.
            </p>
          </header>
          <Button
            size="large"
            kind="fill"
            type="submit"
            onClick={handleSendVerificationEmail}
            disabled={submitting}>
            {submitting ? "Email sent!" : "Resend Verification Email"}
          </Button>
          <footer css={{ textAlign: "center" }}>
            {!!error && <Alert error={error} />}
          </footer>
        </div>
        <p css={{ textAlign: "center" }}>
          Unable to access this email? Sign in with a different email{" "}
          <span
            onClick={() => firebase.auth().signOut()}
            css={{
              textDecoration: "underline",
              color: GOLD,
              cursor: "pointer"
            }}>
            here
          </span>
          .
        </p>
        {justregistered ? null : (
          <p css={{ textAlign: "center" }}>
            Are you a tour leader? You access your tour manifest{" "}
            <Link to="/manifest">here</Link>.
          </p>
        )}
      </div>
    </>
  )
}
