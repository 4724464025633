/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { animated, useSpring } from "react-spring"
import { Link, NavLink, useLocation } from "react-router-dom"
import * as Icon from "react-feather"
import { useStore } from "../store"
import firebase from "../firebase"
import Button from "./Button"
import { displayDaytime } from "../utils/format"
import { useTheme } from "../theme"

function NavLinkItem(props) {
  const { theme } = useTheme()
  return (
    <li>
      <NavLink
        {...props}
        style={{ textDecoration: "none", fontStretch: "ultra-condensed" }}
        activeStyle={{ textDecoration: "underline" }}>
        <h3>{props.children}</h3>
      </NavLink>
    </li>
  )
}

export default function Nav() {
  const { theme } = useTheme()
  const { user, isAdmin } = useStore()
  const { pathname } = useLocation()

  const [open, setOpen] = React.useState(false)

  const pathnameRef = React.useRef(pathname)
  React.useEffect(() => {
    if (pathname !== pathnameRef.current) {
      pathnameRef.current = pathname
      if (open) {
        setOpen(false)
      }
    }
  }, [pathname, open])

  const panelStyle = useSpring({
    transform: open ? "translateX(0)" : "translateX(-100%)",
    config: { duration: 100 }
  })

  const backdropStyle = useSpring({
    opacity: open ? 0.9 : 0,
    config: { duration: 100 }
  })

  function toggleOpen() {
    setOpen(open => !open)
  }

  return (
    <>
      <div
        css={{
          zIndex: 100,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: "3rem",
          padding: "0 0.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.backgroundColor,
          borderBottom: `1px solid ${theme.borderColor}`,
          "@media (min-width: 1001px)": {
            display: "none"
          }
        }}>
        <Link to="/">
          <img
            src={theme.iconImage}
            css={{ width: "auto", height: "2rem", display: "block" }}
          />
        </Link>
      </div>
      <animated.div
        onClick={toggleOpen}
        style={backdropStyle}
        css={{
          zIndex: 101,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: theme.backgroundColor,
          pointerEvents: open ? "auto" : "none"
        }}
      />
      <animated.nav
        style={panelStyle}
        css={{
          zIndex: 102,
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          width: 250,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundSize: "cover",
          backgroundPosition: "center left",
          backgroundImage: `url(${theme.patternImage})`,
          borderRight: `1px solid ${theme.accentText}`,
          "@media (min-width: 1001px)": {
            transform: "none !important"
          },
          "@media (max-width: 400px)": {
            width: "calc(100% - 2.5rem)"
          }
        }}>
        <button
          onClick={toggleOpen}
          css={{
            position: "absolute",
            top: 0,
            left: "100%",
            height: "3rem",
            padding: "0.5rem",
            "@media (min-width: 1001px)": {
              display: "none"
            }
          }}>
          {open ? <Icon.X size="1.5rem" /> : <Icon.Menu size="1.5rem" />}
        </button>
        <header css={{ padding: "2rem" }}>
          Good {displayDaytime()},
          <br />
          <h5 css={{ margin: 0 }} className="num-lines-1">
            {user.email.split("@").shift()}
          </h5>
        </header>
        {isAdmin && (
          <ul
            css={{
              margin: 0,
              padding: "2rem",
              listStyle: "none",
              a: {
                textDecoration: "none"
              }
            }}>
            <NavLinkItem to="/tours">Tours</NavLinkItem>
            <NavLinkItem to="/registrations">Registrations</NavLinkItem>
            <NavLinkItem to="/activity">Activity</NavLinkItem>
            <NavLinkItem to="/settings">Settings</NavLinkItem>
          </ul>
        )}
        <footer css={{ padding: "2rem" }}>
          <Button
            kind="fill"
            size="small"
            onClick={() => {
              firebase.auth().signOut()
            }}>
            Sign Out
          </Button>
        </footer>
      </animated.nav>
    </>
  )
}
