/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { CardElement, Elements, injectStripe } from "react-stripe-elements"
import Alert from "../components/Alert"
import Button from "../components/Button"
import Field from "../components/Field"
import FieldRow from "../components/FieldRow"
import Input from "../components/Input"
import PasswordInput from "../components/PasswordInput"
import ProcessingOverlay from "../components/ProcessingOverlay"
import Select from "../components/Select"
import SignatureInput from "../components/SignatureInput"
import { functions } from "../firebase"
import { useTheme } from "../theme"
import { deserializeMoney, serializeMoney } from "../utils/form"
import { displayMoney } from "../utils/format"

function CardForm({
  stripe,
  prompt,
  terms,
  fee,
  feePercentage,
  disabled,
  onSubmit,
  newUserEmail,
  onError
}) {
  const { theme } = useTheme()

  const [name, setName] = React.useState("")
  const [signature, setSignature] = React.useState("")
  const [error, setError] = React.useState(null)
  const [submitting, setSubmitting] = React.useState(false)

  async function handleSubmit(password) {
    try {
      setError(null)
      setSubmitting(true)
      const { token, error } = await stripe.createToken({ name })
      if (error) {
        throw new Error(error.message)
      }
      if (!token || !token.id) {
        throw new Error("Please enter valid card details.")
      }
      await onSubmit(token.id, signature, password)
    } catch (error) {
      await onError()
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const password = formData.get("password")
        handleSubmit(password)
      }}>
      <Field label="Name on card">
        <Input
          name="name"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
      </Field>
      <Field label="Card details">
        <div
          css={{
            width: "100%",
            padding: "0.5rem",
            backgroundColor: theme.foregroundColor,
            border: "1px solid " + theme.borderColor,
            borderRadius: 3
          }}>
          <CardElement
            style={{
              base: {
                fontSize: "16px",
                color: theme.primaryText,
                "::placeholder": {
                  color: theme.hintText
                }
              }
            }}
          />
        </div>
      </Field>
      {terms && (
        <SignatureInput
          value={signature}
          onChange={e => setSignature(e.target.value)}
        />
      )}
      {fee ? (
        <small>
          Note: includes a {feePercentage * 100}% processing fee (
          {displayMoney(fee)}).
        </small>
      ) : null}

      {!!newUserEmail && <PasswordInput email={newUserEmail} />}
      <Button size="large" kind="fill" disabled={disabled || submitting}>
        {submitting ? "Processing..." : prompt}
      </Button>
      {submitting && <ProcessingOverlay />}
      {error && <Alert error={error} />}
    </form>
  )
}

const CardFormWithStripe = injectStripe(CardForm)

function PlaidForm({
  prompt,
  terms,
  amount,
  fee = 0,
  feePercentage,
  disabled,
  onSubmit,
  newUserEmail,
  onError
}) {
  const { theme } = useTheme()

  const [token, setToken] = React.useState(null)
  const [signature, setSignature] = React.useState("")
  const [account, setAccount] = React.useState(null)
  const [linking, setLinking] = React.useState(false)
  const [linkingError, setLinkingError] = React.useState(null)
  const [submitting, setSubmitting] = React.useState(false)
  const [submittingError, setSubmittingError] = React.useState(null)

  const insufficientFunds = account
    ? account.balances.available * 100 < amount + fee
    : null

  function handleReset(e) {
    setToken(null)
    setAccount(null)
    setLinking(false)
    setLinkingError(null)
    setSubmitting(false)
    setSubmittingError(null)
  }

  async function handleSubmit(password) {
    // e.preventDefault()
    try {
      setSubmittingError(null)
      setSubmitting(true)
      await onSubmit(token, signature, password)
    } catch (error) {
      await onError()
      setSubmittingError(error)
    } finally {
      setSubmitting(false)
    }
  }

  function handlePlaid() {
    const plaidLink = window.Plaid.create({
      clientName: "Faith Based Expeditions",
      env: process.env.REACT_APP_PLAID_ENV,
      key: process.env.REACT_APP_PLAID_PUBLIC_KEY,
      product: ["auth", "transactions"],
      onSuccess: async (public_token, metadata) => {
        try {
          setToken(null)
          setAccount(null)
          setLinking(true)
          const getPlaidStripeToken = functions.httpsCallable(
            "getPlaidStripeToken"
          )
          const tokenResponse = await getPlaidStripeToken({
            public_token: public_token,
            account_id: metadata.account_id
          })
          setToken(tokenResponse.data.stripe_bank_account_token)
          setAccount(tokenResponse.data.account)
          setLinkingError(null)
        } catch (error) {
          setLinkingError(error)
        } finally {
          setLinking(false)
        }
      },
      onExit: error => {
        setLinkingError(error)
      }
    })
    plaidLink.open()
  }

  if (linkingError) {
    return (
      <>
        <Alert error={linkingError} />
        <br />
        <Button
          size="large"
          kind="fill"
          onClick={handlePlaid}
          disabled={disabled || linking}>
          {linking ? "Linking bank account..." : "Try another bank account"}
        </Button>
      </>
    )
  }

  if (account && token) {
    return (
      <form
        onSubmit={e => {
          const formData = new FormData(e.target)
          e.preventDefault()
          const password = formData.get("password") || null
          handleSubmit(password)
        }}
        css={{ margin: "1rem 0" }}>
        <div
          css={{
            padding: "1rem",
            borderRadius: 3,
            border: `1px solid ${theme.borderColor}`
          }}>
          <h5 css={{ marginTop: 0, marginBottom: "0.5rem" }}>
            Successfully linked {account.name} xxxx{account.mask}.
          </h5>
          {insufficientFunds ? (
            <Alert
              error={`You only have ${displayMoney(
                account.balances.available * 100
              )} available funds in this account. Please change the Deposit Amount
              or link a different bank account.`}
            />
          ) : (
            <p css={{ margin: 0 }}>
              {displayMoney(amount + fee)} will be transfered from available
              funds in this account.
            </p>
          )}
          <Button
            kind="link"
            onClick={handleReset}
            css={{ marginTop: "0.5rem" }}>
            Link a different bank account.
          </Button>
        </div>
        {terms && (
          <SignatureInput
            value={signature}
            onChange={e => setSignature(e.target.value)}
          />
        )}
        {fee ? (
          <small>
            Note: includes a {feePercentage * 100}% processing fee (
            {displayMoney(fee)}).
          </small>
        ) : null}
        {!!newUserEmail && <PasswordInput email={newUserEmail} />}
        <Button
          size="large"
          kind="fill"
          disabled={disabled || submitting || insufficientFunds}>
          {submitting ? "Processing..." : prompt}
        </Button>
        {submittingError && <Alert error={submittingError} />}
        {submitting && <ProcessingOverlay />}
      </form>
    )
  }

  return (
    <>
      <Button
        size="large"
        kind="fill"
        onClick={handlePlaid}
        disabled={disabled || linking}>
        {linking ? "Linking bank account..." : "Link your bank account"}
      </Button>
      {linking && <ProcessingOverlay title="Linking bank account" />}
    </>
  )
}

export default function PaymentForm({
  minAmount,
  maxAmount,
  amountLabel = "Amount",
  processFee = false,
  terms = false,
  prompt,
  onSubmit,
  newUserEmail,
  onError
}) {
  const [method, setMethod] = React.useState("card")
  const [amount, setAmount] = React.useState(minAmount)

  const ACHLimit = 190000 // temporary limit until Stripe establishes account history
  const invalidACH = method == "bank" && amount > ACHLimit
  const invalidAmount = amount < minAmount || amount > maxAmount || invalidACH

  const feePercentage = 0.025 // 2.5%
  const fee = processFee ? Math.round(feePercentage * amount) : 0
  const total = amount + fee

  return (
    <>
      <FieldRow>
        <Field label={amountLabel}>
          <Input
            name="amount"
            mask={Input.currencyMask}
            value={deserializeMoney(amount)}
            onChange={e => setAmount(serializeMoney(e.target.value))}
            disabled={minAmount && minAmount == maxAmount}
          />
        </Field>
        <Field label="Method">
          <Select
            fullWidth
            name="method"
            value={method}
            onChange={e => setMethod(e.currentTarget.value)}>
            <option value="card">Credit/Debit Card</option>
            <option value="bank">Online Check</option>
          </Select>
        </Field>
      </FieldRow>
      {invalidAmount && (
        <Alert
          error={
            invalidACH
              ? `You cannot transfer more than ${displayMoney(
                  ACHLimit
                )} via online check at this time.`
              : `Please enter an amount between ${displayMoney(
                  minAmount
                )} and ${displayMoney(maxAmount)}.`
          }
          css={{ marginTop: "-1rem" }}
        />
      )}
      {method === "card" ? (
        <Elements>
          <CardFormWithStripe
            prompt={typeof prompt === "function" ? prompt(total) : prompt}
            fee={fee}
            terms={terms}
            feePercentage={feePercentage}
            disabled={invalidAmount}
            newUserEmail={newUserEmail}
            onError={onError}
            onSubmit={async (token, signature, password) => {
              await onSubmit({
                signature,
                token,
                method,
                amount,
                fee,
                password
              })
            }}
          />
        </Elements>
      ) : method === "bank" ? (
        <PlaidForm
          prompt={typeof prompt === "function" ? prompt(total) : prompt}
          amount={amount}
          fee={fee}
          terms={terms}
          feePercentage={feePercentage}
          disabled={invalidAmount}
          newUserEmail={newUserEmail}
          onError={onError}
          onSubmit={async (token, signature, password) => {
            await onSubmit({
              signature,
              token,
              method,
              amount,
              fee,
              password
            })
          }}
        />
      ) : null}
    </>
  )
}
