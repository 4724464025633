/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { Link } from "react-router-dom"
import { fonts } from "../components/Styles"
import TourMeta from "../components/TourMeta"
import TourImage from "../components/TourImage"
import { useTheme } from "../theme"

export default function TripCard({ registrant, tour, extensions = [], to }) {
  const { theme } = useTheme()

  let extension
  if (registrant.extension_id && extensions.length) {
    extension = extensions.find(x => x.id == registrant.extension_id)
  }

  return (
    <div
      css={{
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        border: "1px solid " + theme.borderColor,
        borderRadius: 3,
        cursor: to ? "pointer" : null,
        "> div": {
          padding: "0.5rem"
        }
      }}>
      <Link
        to={to}
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      />
      <div css={{ width: 180, flexShrink: 0 }}>
        <TourImage
          tour={tour}
          css={{
            display: "block",
            width: "100%",
            height: "auto",
            borderRadius: 3
          }}
        />
      </div>
      <div css={{ width: 1, flex: 1 }}>
        <h5
          className="num-lines-2"
          css={{
            fontFamily: fonts.surveyor,
            fontWeight: "normal",
            fontSize: "1.5rem",
            minWidth: 1,
            marginTop: 0
          }}>
          {tour.title}
        </h5>
        <TourMeta
          tour={tour}
          showFront
          departures={[registrant.tour_departure].filter(x => x)}
          tourDates={[[registrant.tour_start_date, registrant.tour_end_date]]}
          extensionDates={
            extension ? [[extension.start_date, extension.end_date]] : []
          }
        />
      </div>
    </div>
  )
}
