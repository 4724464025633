const moment = require("moment")

exports.getOfferingData = offerings => {
  let upcomingDates = []
  let uniqueLeaders = []
  let uniqueDepartures = []
  offerings.forEach(offering => {
    if (moment.utc(offering.tour_end_date).isAfter()) {
      upcomingDates.push([offering.tour_start_date, offering.tour_end_date])
    }
    if (offering.tour_leader) {
      if (!uniqueLeaders.includes(offering.tour_leader)) {
        uniqueLeaders.push(offering.tour_leader)
      }
    }
    if (offering.tour_departure) {
      if (!uniqueDepartures.includes(offering.tour_departure)) {
        uniqueDepartures.push(offering.tour_departure)
      }
    }
  })

  return {
    upcomingDates,
    uniqueLeaders,
    uniqueDepartures
  }
}
