import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import numeral from "numeral"
import Alert from "../components/Alert"
import Field from "../components/Field"
import Input from "../components/Input"
import Button from "../components/Button"
import Select from "../components/Select"
import Textarea from "../components/Textarea"
import FieldRow from "../components/FieldRow"
import { displayFullName } from "../utils/format"
import { serializeMoney, deserializeMoney } from "../utils/form"
import Checkbox from "../components/Checkbox"

const schema = Yup.object().shape({
  registrant_id: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  cost: Yup.string().required("Required"),
  price: Yup.string().required("Required"),
  insurance_yes: Yup.boolean(),
  nights: Yup.number().min(0, "Enter valid number of nights").nullable(),
  timing: Yup.string().nullable()
})

export default function AddonForm({
  prompt = "Submit",
  registrants = [],
  addon,
  onSubmit
}) {
  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      registrant_id: addon?.registrant_id || null,
      category: addon?.category || null,
      description: addon?.description || "",
      cost: deserializeMoney(addon?.cost),
      price: deserializeMoney(addon?.price),
      nights: addon?.nights || null,
      timing: addon?.timing || null,
      insurance_yes: !!addon?.insurance_yes
    },
    onSubmit: async (values, { setFieldError }) => {
      try {
        const addonData = {
          ...values,
          cost: serializeMoney(values.cost),
          price: serializeMoney(values.price)
        }
        await onSubmit(addonData)
      } catch (error) {
        setFieldError("submit", error.message)
      }
    }
  })

  React.useEffect(() => {
    if (formik.values.category === "lodging") {
      formik.setFieldValue("description", "extra room nights")
    } else {
      formik.setFieldValue("description", addon?.description || "")
    }
  }, [formik.values.category])

  return (
    <form onSubmit={formik.handleSubmit}>
      <FieldRow>
        <Field
          label="Registrant"
          tooltip="Choose which registration will apply the add-on"
          error={formik.touched.registrant_id && formik.errors.registrant_id}>
          <Select
            name="registrant_id"
            value={formik.values.registrant_id}
            onChange={formik.handleChange}
            disabled={!!addon}
            fullWidth>
            <option selected disabled>
              Select...
            </option>
            {registrants.map(x => (
              <option key={x.id} value={x.id}>
                {displayFullName(x)}
              </option>
            ))}
          </Select>
        </Field>
        <Field
          label="Category"
          error={formik.touched.category && formik.errors.category}>
          <Select
            name="category"
            value={formik.values.category}
            onChange={formik.handleChange}
            fullWidth>
            <option selected disabled>
              Select...
            </option>
            <option value="lodging">Extra Room Nights</option>
            <option value="transport">Transport</option>
            <option value="refund">Refund</option>
            <option value="other">Other</option>
          </Select>
        </Field>
      </FieldRow>
      {formik.values.category === "lodging" ? (
        <FieldRow>
          <Field
            label="Number of Nights"
            error={formik.touched.nights && formik.errors.nights}>
            <Input
              name="nights"
              type="number"
              value={formik.values.nights}
              onChange={formik.handleChange}
              required
            />
          </Field>
          <Field label="Timing">
            <Select
              name="timing"
              value={formik.values.when}
              onChange={formik.handleChange}
              required
              fullWidth>
              <option disabled selected>
                Select...
              </option>
              <option value="early">Early Arrival</option>
              <option value="late">Late Departure</option>
            </Select>
          </Field>
        </FieldRow>
      ) : (
        <Field
          label="Reason"
          tooltip="Why you’re adding this charge or refund"
          error={formik.touched.description && formik.errors.description}>
          <Textarea
            name="description"
            placeholder="Transportation, refund, etc."
            value={formik.values.description}
            onChange={formik.handleChange}
          />
        </Field>
      )}
      <FieldRow>
        <Field
          label="Price"
          tooltip="How much you’re charging for this add-on"
          error={formik.touched.price && formik.errors.price}>
          <Input
            name="price"
            mask={Input.currencyMask}
            value={formik.values.price}
            onChange={formik.handleChange}
          />
        </Field>
        <Field
          label="Internal Cost"
          tooltip="How much this add-on costs to provide"
          error={formik.touched.cost && formik.errors.cost}>
          <Input
            name="cost"
            mask={Input.currencyMask}
            value={formik.values.cost}
            onChange={formik.handleChange}
          />
        </Field>
      </FieldRow>
      <Field
        label="Insured"
        tooltip="If enabled, this addon will decrease or increase the total insurance cost charged to the registrant"
        error={formik.touched.insurance_yes && formik.errors.insurance_yes}>
        <Checkbox
          name="insurance_yes"
          value={true}
          checked={formik.values.insurance_yes}
          onChange={formik.handleChange}>
          This add-on should be included in insurance cost calculations.
        </Checkbox>
      </Field>
      <Button
        type="submit"
        kind="fill"
        size="large"
        disabled={formik.isSubmitting}>
        {formik.isSubmitting ? "Submitting..." : prompt}
      </Button>
      {console.log(formik.errors)}
      {formik.errors.submit ? (
        <Alert error={formik.errors.submit} />
      ) : Object.keys(formik.errors).some(key => formik.touched[key]) ? (
        <Alert error="Please enter valid values into all required fields above." />
      ) : null}
    </form>
  )
}
