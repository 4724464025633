/** @jsx jsx */
import { jsx } from "@emotion/core"
import chunk from "lodash/chunk"
import pluralize from "pluralize"
import Path from "path"
import React from "react"
import moment from "moment"
import { Helmet } from "react-helmet"
import {
  Link,
  Redirect,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom"
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink
} from "@reach/menu-button"
import firebase, { db, functions } from "../firebase"
import * as Icon from "react-feather"
import Tag from "../components/Tag"
import Avatar from "../components/Avatar"
import Button from "../components/Button"
import Tooltip from "../components/Tooltip"
import Loading from "../components/Loading"
import PanelRoute from "../components/PanelRoute"
import DownloadManifestButton from "../components/DownloadManifestButton"
import AddonForm from "../forms/AddonForm"
import InvoiceForm from "../forms/InvoiceForm"
import PaymentForm from "../forms/PaymentForm"
import SwitchTourForm from "../forms/SwitchTourForm"
import RegistrantForm from "../forms/RegistrantForm"
import AdminPaymentForm from "../forms/AdminPaymentForm"
import MoveRegistrantForm from "../forms/MoveRegistrantForm"
import {
  displayPaymentMethod,
  displayFullName,
  displayMoney,
  displayAddress,
  displayPhone,
  displayName,
  displayDate
} from "../utils/format"
import { useStore } from "../store"
import TripCard from "../components/TripCard"
import TourDocuments from "./TourDocuments"
import { getGroupInvoiceData } from "../utils/getGroupInvoiceData"
import multiDownload from "multi-download"

export default function RegistrantDetails() {
  const { isAdmin } = useStore()
  const { url, path } = useRouteMatch()
  const { registrantId } = useParams()
  const { location } = useHistory()

  const from = React.useRef(location.state?.from)
  const backTo = from.current || Path.join(location.pathname, "../")

  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  const [redirectId, setRedirectId] = React.useState(null)
  const [tour, setTour] = React.useState(null)
  const [insurance, setInsurance] = React.useState(null)
  const [offerings, setOfferings] = React.useState(null)
  const [extensions, setExtensions] = React.useState(null)
  const [registrant, setRegistrant] = React.useState(null)
  const [subregistrants, setSubregistrants] = React.useState(null)
  const [payments, setPayments] = React.useState(null)
  const [addons, setAddons] = React.useState(null)
  const [passportPhotos, setPassportPhotos] = React.useState(null)

  React.useEffect(() => {
    loadData(registrantId)
  }, [registrantId])

  async function loadData(registrantId) {
    try {
      setError(null)
      setLoading(true)
      setRedirectId(null)

      const registrantRef = db.collection("registrants").doc(registrantId)
      const registrantDoc = await registrantRef.get()
      const registrant = registrantDoc.data()

      if (registrant.registrant_id) {
        setRedirectId(registrant.registrant_id)
        return
      }

      const tourRef = db.collection("tours").doc(registrant.tour_id)
      const tourDoc = await tourRef.get()
      const tour = tourDoc.data()

      let insurance = null
      if (tour.insurance_id) {
        const insuranceRef = db.collection("insurances").doc(tour.insurance_id)
        const insuranceDoc = await insuranceRef.get()
        insurance = insuranceDoc.data()
      }

      const offerings = []
      const offeringDocs = await tourRef.collection("offerings").get()
      offeringDocs.forEach(offeringDoc => {
        offerings.push(offeringDoc.data())
      })

      const extensions = []
      const extensionDocs = await tourRef.collection("extensions").get()
      extensionDocs.forEach(extensionDoc => {
        extensions.push(extensionDoc.data())
      })

      const subregistrants = []
      const subregistrantDocs = await db
        .collection("registrants")
        .where("registrant_id", "==", registrantId)
        .get()
      subregistrantDocs.forEach(subregistrantDoc => {
        subregistrants.push(subregistrantDoc.data())
      })

      const registrantIds = [registrant, ...subregistrants].map(x => x.id)
      // firebase "in" queries only support 10 at a time
      const chunkedIds = chunk(registrantIds, 10)

      const addons = []
      for (const ids of chunkedIds) {
        const addonDocs = await db
          .collectionGroup("addons")
          .where("registrant_id", "in", ids)
          .get()
        addonDocs.forEach(addonDoc => {
          addons.push(addonDoc.data())
        })
      }

      const payments = []
      for (const ids of chunkedIds) {
        const paymentDocs = await db
          .collectionGroup("payments")
          .where("registrant_id", "in", ids)
          .get()
        paymentDocs.forEach(paymentDoc => {
          payments.push(paymentDoc.data())
        })
      }

      const passportPhotos = []
      for (const r of [registrant, ...subregistrants]) {
        if (r.passport_photo) {
          try {
            const url = await firebase
              .storage()
              .ref(r.passport_photo)
              .getDownloadURL()
            const metadata = await firebase
              .storage()
              .ref(r.passport_photo)
              .getMetadata()
            passportPhotos.push({ url, metadata })
          } catch (error) {
            // swallow errors
          }
        }
      }

      setTour(tour)
      setInsurance(insurance)
      setOfferings(offerings)
      setExtensions(extensions)
      setRegistrant(registrant)
      setSubregistrants(subregistrants)
      setPayments(payments)
      setAddons(addons)
      setPassportPhotos(passportPhotos)
    } catch (error) {
      console.log({ error })
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  function setRegistrantHelper(id, callback) {
    if (id === registrant.id) {
      setRegistrant(registrant => {
        return callback(registrant)
      })
    } else {
      setSubregistrants(subregistrants => {
        return subregistrants.map(subregistrant => {
          if (subregistrant.id === id) {
            return callback(subregistrant)
          } else {
            return subregistrant
          }
        })
      })
    }
  }

  if (redirectId) {
    return <Redirect to={`/registrations/${redirectId}`} />
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <p>Unable to load registration: {String(error.message || error)}</p>
  }

  const registrants = [registrant].concat(subregistrants)

  const tourClosed =
    tour.inactive && moment.utc(registrant.tour_end_date).isBefore()

  const groupInvoiceData = getGroupInvoiceData(
    registrants,
    tour,
    insurance,
    extensions,
    addons,
    payments
  )

  let title
  if (registrants.length > 1) {
    if (registrants.every(x => x.name_last === registrant.name_last)) {
      title = `The ${registrant.name_last}’s Trip`
    } else if (registrants.length < 3) {
      title = `${registrants.map(x => x.name_first).join(" and ")}’s Trip`
    } else {
      title = `${registrant.name_first}’s Trip (${registrants.length} people)`
    }
  } else {
    title = `${registrant.name_first}’s Trip`
  }

  return (
    <div
      css={{
        section: { margin: "2rem 0" }
      }}>
      <br />
      <Helmet>
        <title>{title} | Faith Based Expeditions</title>
      </Helmet>
      <Button to={backTo}>
        <Icon.ArrowLeft size="1.5rem" />
      </Button>
      <h2>{title}</h2>
      <hr />
      <section>
        <header
          css={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between"
          }}>
          <h3>Trip {tourClosed ? "(tour is closed)" : null}</h3>
          <div style={{ display: "flex" }}>
            {!isAdmin && registrant.tour_leader_yes && (
              <DownloadManifestButton
                tourCode={tour.tour_code}
                accessCode={registrant.tour_id}
              />
            )}
            &nbsp;
            {isAdmin ? (
              <Button kind="outline" size="small" to={`${url}/switch-tour`}>
                Change Tour
              </Button>
            ) : null}
          </div>
        </header>
        <TripCard
          tour={tour}
          registrant={registrant}
          extensions={extensions}
          to={isAdmin ? `/tours/${registrant.tour_id}` : null}
        />
      </section>
      <section>
        <header
          css={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between"
          }}>
          <h3>Travelers</h3>
          {isAdmin ? (
            <Menu>
              <Button as={MenuButton} size="small" kind="fill">
                Add Traveler <Icon.ChevronDown size="1em" />
              </Button>
              <MenuList>
                <MenuLink as={Link} to={`${url}/registrant/new`}>
                  Add New Traveler
                </MenuLink>
                <MenuLink as={Link} to={`${url}/registrant/move`}>
                  Add Traveler from Manifest
                </MenuLink>
              </MenuList>
            </Menu>
          ) : null}
        </header>
        <ul css={{ margin: "-0.5rem", padding: 0, listStyle: "none" }}>
          {registrants.map(x => (
            <li key={x.id}>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  "> div": {
                    padding: "0.5rem",
                    whiteSpace: "nowrap"
                  }
                }}>
                <div>
                  <Avatar user={x} />
                </div>
                <div css={{ width: 300 }}>
                  <h5 css={{ margin: 0 }}>{displayFullName(x)}</h5>
                  <small>
                    <Tag disabled={x.inactive}>
                      {x.inactive ? "Inactive" : "Active"}
                    </Tag>
                    {!x.registrant_id && <Tag>Primary</Tag>}
                    {x.tour_leader_yes && <Tag>Tour Leader</Tag>}
                  </small>
                </div>
                <div
                  css={{
                    width: 250,
                    "@media (max-width: 600px)": {
                      display: "none"
                    }
                  }}>
                  <small className="num-lines-2">
                    {displayPhone(x.phone)}
                    <br />
                    {x.email}
                  </small>
                </div>
                <div
                  css={{
                    width: 1,
                    flexGrow: 1,
                    "@media (max-width: 1000px)": {
                      display: "none"
                    }
                  }}>
                  <small css={{ whiteSpace: "pre" }} className="num-lines-2">
                    {displayAddress(x)}
                  </small>
                </div>
                <div css={{ width: 50, display: "flex" }}>
                  {x.supplement_yes ? (
                    <Tooltip label="Room Alone">
                      <span>
                        <Icon.UserMinus
                          size={16}
                          style={{ display: "block" }}
                        />
                      </span>
                    </Tooltip>
                  ) : x.roommate_preference ? (
                    <Tooltip
                      label={`Preferred Roommate: ${x.roommate_preference}`}>
                      <span>
                        <Icon.UserPlus size={16} style={{ display: "block" }} />
                      </span>
                    </Tooltip>
                  ) : null}
                  {x.comments ? (
                    <Tooltip label={`Comments: ${x.comments}`}>
                      <span>
                        <Icon.MessageCircle
                          size={16}
                          style={{ display: "block" }}
                        />
                      </span>
                    </Tooltip>
                  ) : null}
                </div>
                <div>
                  {isAdmin ? (
                    <Menu>
                      <Button kind="outline" size="small" as={MenuButton}>
                        Actions <Icon.ChevronDown size="1em" />
                      </Button>
                      <MenuList>
                        <MenuLink as={Link} to={`${url}/registrant/${x.id}`}>
                          Edit Traveler / Itinerary
                        </MenuLink>
                        <MenuItem
                          onSelect={async () => {
                            const prompt = `Are you sure you want to set ${
                              x.name_first
                            } ${x.inactive ? "active" : "inactive"}?`

                            if (!window.confirm(prompt)) {
                              return
                            }

                            const registrantUpdate = {
                              inactive: !x.inactive,
                              modified: Date.now()
                            }
                            await db
                              .collection("registrants")
                              .doc(x.id)
                              .update(registrantUpdate)
                            setRegistrantHelper(x.id, registrant => {
                              return { ...registrant, ...registrantUpdate }
                            })
                          }}>
                          {x.inactive ? "Set Active" : "Set Inactive"}
                        </MenuItem>
                        <MenuItem
                          onSelect={async () => {
                            const prompt = `Are you sure you want to ${
                              !x.tour_leader_yes ? "give" : "remove"
                            } tour leader status for ${
                              x.name_first
                            }? This will update the invoice so that ${
                              x.name_first
                            } owes ${
                              !x.tour_leader_yes ? "nothing" : "the full amount"
                            } for this tour.`

                            if (!window.confirm(prompt)) {
                              return
                            }

                            await db
                              .collection("registrants")
                              .doc(x.id)
                              .update({
                                tour_leader_yes: !x.tour_leader_yes,
                                modified: Date.now()
                              })
                            setRegistrantHelper(x.id, r => {
                              return {
                                ...r,
                                tour_leader_yes: !x.tour_leader_yes
                              }
                            })
                          }}>
                          {x.tour_leader_yes
                            ? "Remove Tour Leader Status"
                            : "Give Tour Leader Status"}
                        </MenuItem>
                        {x.registrant_id && (
                          <MenuItem
                            onSelect={async () => {
                              const prompt = `Are you sure you want to separate ${x.name_first} from this group? This will also remove any addons created for ${x.name_first} or payments made by ${x.name_first} from this group’s invoice.`

                              if (!window.confirm(prompt)) {
                                return
                              }

                              // separate registrant from this group
                              await db
                                .collection("registrants")
                                .doc(x.id)
                                .update({
                                  registrant_id: null,
                                  modified: Date.now()
                                })

                              // update local state (remove registrant and their addons and payments)
                              setAddons(addons => {
                                return addons.filter(addon => {
                                  return addon.registrant_id !== x.id
                                })
                              })
                              setPayments(payments => {
                                return payments.filter(payment => {
                                  return payment.registrant_id !== x.id
                                })
                              })
                              setSubregistrants(subregistrants => {
                                return subregistrants.filter(subregistrant => {
                                  return subregistrant.id !== x.id
                                })
                              })
                            }}>
                            Separate Registration
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  ) : (
                    <Button
                      size="small"
                      kind="outline"
                      to={`${url}/registrant/${x.id}`}>
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>
      {(addons.length > 0 || isAdmin) && (
        <section>
          <header
            css={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between"
            }}>
            <h3>Add-ons</h3>
            &nbsp;
            {isAdmin && (
              <Button size="small" kind="fill" to={`${url}/addon/new`}>
                Create Add-on
              </Button>
            )}
          </header>
          {addons.length ? (
            <ul css={{ margin: 0, padding: 0, listStyle: "none" }}>
              {addons.map(addon => (
                <li
                  key={addon.id}
                  css={{
                    margin: "0.3rem 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}>
                  <div css={{ flexGrow: 1, paddingRight: "1rem" }}>
                    {displayMoney(addon.price)} {addon.description} for{" "}
                    {displayName(
                      registrants.find(x => x.id == addon.registrant_id)
                    )}
                    , added {moment.utc(addon.created).fromNow()}
                  </div>
                  {isAdmin && (
                    <Button
                      kind="outline"
                      size="small"
                      to={`${url}/addon/${addon.id}`}>
                      Edit
                    </Button>
                  )}
                  &nbsp;
                  {isAdmin && (
                    <Button
                      kind="outline"
                      size="small"
                      onClick={async () => {
                        if (
                          window.confirm(
                            "Are you sure you want to remove this addon?"
                          )
                        ) {
                          await db
                            .collection("registrants")
                            .doc(String(addon.registrant_id))
                            .collection("addons")
                            .doc(String(addon.id))
                            .delete()
                          setAddons(addons => {
                            return addons.filter(x => x.id != addon.id)
                          })
                        }
                      }}>
                      Remove
                    </Button>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p css={{ margin: 0 }}>No addons yet.</p>
          )}
        </section>
      )}
      <section>
        <header
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <h3>Payments</h3>
          <div>
            <Button size="small" kind="outline" to={`${url}/invoice`}>
              View Invoice
            </Button>
            &nbsp;
            <Button size="small" kind="fill" to={`${url}/payment`}>
              {isAdmin ? "Log Payment" : "Make Payment"}
            </Button>
          </div>
        </header>
        {payments.length ? (
          <ul css={{ margin: 0, padding: 0, listStyle: "none" }}>
            {payments.map(payment => (
              <li
                key={payment.id}
                css={{
                  margin: "0.5rem 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}>
                <p css={{ margin: 0 }}>
                  {displayMoney(payment.amount)}{" "}
                  {displayPaymentMethod(payment.method)} payment, made{" "}
                  {moment.utc(payment.created).fromNow()}. {payment.note}
                </p>
                {isAdmin && payment.admin_logged && (
                  <Button
                    size="small"
                    kind="outline"
                    onClick={async () => {
                      if (
                        window.confirm(
                          "Are you sure you want to remove this admin-logged payment?"
                        )
                      ) {
                        await db
                          .collection("registrants")
                          .doc(String(payment.registrant_id))
                          .collection("payments")
                          .doc(String(payment.id))
                          .delete()
                        setPayments(payments => {
                          return payments.filter(x => x.id !== payment.id)
                        })
                      }
                    }}>
                    Remove
                  </Button>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p css={{ margin: 0 }}>No payments yet.</p>
        )}
      </section>
      {passportPhotos?.length ? (
        <section>
          <header
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <h3>Passports</h3>
            {isAdmin ? (
              <Button
                size="small"
                kind="fill"
                onClick={async e => {
                  for (const [index, item] of passportPhotos.entries()) {
                    const base64str = await getBase64ImageFromUrl(item.url)
                    const anchor = document.createElement("a")
                    const filetype = item.metadata.contentType.split("/")[1]
                    anchor.href = base64str
                    anchor.download = `passport-${index + 1}.${filetype}`
                    document.body.appendChild(anchor)
                    anchor.click()
                    document.body.removeChild(anchor)
                    await new Promise(r => setTimeout(r, 500))
                  }
                }}>
                <Icon.Download size="1em" /> Download
              </Button>
            ) : null}
          </header>
          <div css={{ display: "flex", flexWrap: "wrap", gap: 12 }}>
            {passportPhotos.map(x => (
              <img
                key={x.url}
                src={x.url}
                alt="passport photo"
                css={{
                  display: "block",
                  height: 300,
                  width: "auto"
                }}
              />
            ))}
          </div>
        </section>
      ) : null}
      <section>
        <header
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <h3>Documents</h3>
        </header>
        <TourDocuments tourId={tour.id} />
      </section>
      <br />
      <br />
      <br />
      <br />
      <PanelRoute path={`${path}/switch-tour`}>
        <Helmet>
          <title>Switch Tour | Faith Based Expeditions</title>
        </Helmet>
        <h2>
          Move {pluralize("registrations", registrants.length)} to another tour
        </h2>
        <SwitchTourForm
          tour={tour}
          extensions={extensions}
          registrants={registrants}
          onSubmit={async (tourId, registrantsData) => {
            const batch = db.batch()

            registrantsData.forEach(({ registrant, changes }) => {
              const registrantRef = db
                .collection("registrants")
                .doc(registrant.id)
              batch.update(registrantRef, changes)
            })

            addons.forEach(addon => {
              const addonRef = db
                .collection("registrants")
                .doc(String(addon.registrant_id))
                .collection("addons")
                .doc(String(addon.id))
              batch.update(addonRef, { tour_id: tourId })
            })

            payments.forEach(payment => {
              const paymentRef = db
                .collection("registrants")
                .doc(payment.registrant_id)
                .collection("payments")
                .doc(payment.id)
              batch.update(paymentRef, { tour_id: tourId })
            })

            await batch.commit()

            window.location.href = url
          }}
        />
      </PanelRoute>
      <PanelRoute path={`${path}/registrant/:registrantId`}>
        {({ params, close }) => {
          if (params.registrantId === "move") {
            return (
              <>
                <Helmet>
                  <title>Add Traveler | Faith Based Expeditions</title>
                </Helmet>
                <h2>Add Traveler from Manifest</h2>
                {registrants.length > 1 ? (
                  <p>
                    You can add an existing traveler to this registration group.
                    Any travelers in this group can see the group details and
                    make payments for the group.
                  </p>
                ) : (
                  <p>
                    You can add an existing traveler to {registrant.name_first}
                    ’s registration to make a registration group. Any travelers
                    in this group can see the group details and make payments
                    for the group.
                  </p>
                )}
                <MoveRegistrantForm
                  tour={tour}
                  registrant={registrant}
                  onSubmit={async registrantIds => {
                    const batch = db.batch()

                    registrantIds.forEach(id => {
                      const registrantRef = db.collection("registrants").doc(id)
                      const registrantUpdate = {
                        registrant_id: registrant.id,
                        modified: Date.now()
                      }
                      batch.update(registrantRef, registrantUpdate)
                    })

                    await batch.commit()

                    window.location.href = url = url
                  }}
                />
              </>
            )
          }
          if (params.registrantId === "new") {
            return (
              <>
                <Helmet>
                  <title>Add Traveler | Faith Based Expeditions</title>
                </Helmet>
                <h2>Add Traveler</h2>
                <RegistrantForm
                  prompt="Add Traveler"
                  tour={tour}
                  addons={addons}
                  primary={registrant}
                  insurance={insurance}
                  offerings={offerings}
                  extensions={extensions}
                  canEditTrip={isAdmin}
                  onSubmit={async formData => {
                    // create new registrant
                    const registrantRef = db.collection("registrants").doc()
                    const newRegistrant = {
                      ...registrant, // copy from primary registrant
                      ...formData, // set new passenger data
                      id: registrantRef.id,
                      registrant_id: registrantId,
                      created: Date.now(),
                      modified: null,
                      paid: 0
                    }
                    await registrantRef.set(newRegistrant)
                    // update state
                    setSubregistrants(subregistrants => {
                      return subregistrants.concat(newRegistrant)
                    })
                    // close panel
                    close()
                  }}
                />
              </>
            )
          } else {
            const traveler = registrants.find(x => x.id == params.registrantId)
            if (traveler) {
              console.log({ traveler })
              return (
                <>
                  <Helmet>
                    <title>Traveler | Faith Based Expeditions</title>
                  </Helmet>
                  <h2>{traveler.name_first}’s Info</h2>
                  <RegistrantForm
                    canEditTrip={isAdmin}
                    tour={tour}
                    addons={addons}
                    primary={registrant}
                    registrant={traveler}
                    insurance={insurance}
                    offerings={offerings}
                    extensions={extensions}
                    prompt="Save Traveler"
                    onSubmit={async formData => {
                      // update registrant
                      const updateRegistrant =
                        functions.httpsCallable("updateRegistrant")
                      await updateRegistrant({ registrant: formData })
                      // update state
                      setRegistrantHelper(params.registrantId, r => {
                        return { ...r, ...formData }
                      })
                      // close panel
                      close()
                    }}
                  />
                </>
              )
            } else {
              return (
                <>
                  <Helmet>
                    <title>Traveler Not Found | Faith Based Expeditions</title>
                  </Helmet>
                  <h2>Traveler Not Found</h2>
                  <Button kind="outline" size="small" onClick={close}>
                    Go Back
                  </Button>
                </>
              )
            }
          }
        }}
      </PanelRoute>
      <PanelRoute path={`${path}/addon/:addon_id`}>
        {({ params, close }) => {
          const isNew = params.addon_id === "new"

          async function createAddon(formData) {
            const addonRef = db
              .collection("registrants")
              .doc(formData.registrant_id)
              .collection("addons")
              .doc()
            const newAddon = {
              ...formData,
              id: addonRef.id,
              tour_id: registrant.tour_id,
              created: Date.now(),
              modified: null
            }
            await addonRef.set(newAddon)
            setAddons(addons => {
              return addons.concat(newAddon)
            })
          }

          async function updateAddon(formData) {
            const addonRef = db
              .collection("registrants")
              .doc(formData.registrant_id)
              .collection("addons")
              .doc(params.addon_id)
            const addonUpdate = {
              ...formData,
              modified: Date.now()
            }
            await addonRef.update(addonUpdate)
            setAddons(addons => {
              return addons.map(x => {
                if (x.id == params.addon_id) {
                  return { ...x, ...addonUpdate }
                }
                return x
              })
            })
          }

          return (
            <>
              <Helmet>
                <title>
                  {isNew ? "Create" : "Edit"} Add-on | Faith Based Expeditions
                </title>
              </Helmet>
              <h2>{isNew ? "Create" : "Edit"} Add-on</h2>
              <AddonForm
                prompt="Save Add-on"
                registrants={registrants}
                addon={isNew ? null : addons.find(x => x.id == params.addon_id)}
                onSubmit={async formData => {
                  if (isNew) {
                    await createAddon(formData)
                  } else {
                    await updateAddon(formData)
                  }
                  close()
                }}
              />
            </>
          )
        }}
      </PanelRoute>
      <PanelRoute path={`${path}/payment`}>
        {({ close }) => {
          if (isAdmin) {
            return (
              <>
                <Helmet>
                  <title>Log Payment | Faith Based Expeditions</title>
                </Helmet>
                <h2>Log Payment</h2>
                <p>
                  Use this form to manually log payments you received directly
                  from the registrants.
                </p>
                <AdminPaymentForm
                  minAmount={0} // $1
                  maxAmount={10000000} // $100,000
                  prompt="Log payment"
                  onSubmit={async formData => {
                    const paymentRef = db
                      .collection("registrants")
                      .doc(registrantId)
                      .collection("payments")
                      .doc()
                    const paymentData = {
                      ...formData,
                      id: paymentRef.id,
                      tour_id: tour.id,
                      registrant_id: registrantId,
                      created: Date.now(),
                      admin_logged: true,
                      modified: null
                    }
                    await paymentRef.set(paymentData)
                    setPayments(payments => {
                      return payments.concat(paymentData)
                    })
                    close()
                  }}
                />
              </>
            )
          } else if (groupInvoiceData.totalRemaining > 0) {
            return (
              <>
                <Helmet>
                  <title>Make Payment | Faith Based Expeditions</title>
                </Helmet>
                <h2>Make a payment</h2>
                <p>
                  Balance due {displayMoney(groupInvoiceData.totalRemaining)} (
                  {displayMoney(groupInvoiceData.totalPaid)} already paid).
                </p>
                <p>
                  Tour balance is due no later than{" "}
                  {displayDate(groupInvoiceData.dueDate)} to avoid a late
                  payment fee of $95 per person.
                </p>
                <PaymentForm
                  minAmount={0}
                  maxAmount={groupInvoiceData.totalRemaining}
                  processFee={!tour.processing_fee_disabled}
                  prompt={amount => `Make payment of ${displayMoney(amount)}`}
                  onSubmit={async formData => {
                    const makePayment = functions.httpsCallable("makePayment")
                    const paymentResult = await makePayment({
                      token: formData.token,
                      method: formData.method,
                      amount: formData.amount,
                      fee: formData.fee,
                      tour_id: tour.id,
                      registrant_id: registrantId
                    })
                    setRegistrant(paymentResult.data.registrant)
                    setPayments(payments => {
                      return payments.concat(paymentResult.data.payment)
                    })
                    close()
                  }}
                />
              </>
            )
          } else {
            return (
              <>
                <Helmet>
                  <title>Make Payment | Faith Based Expeditions</title>
                </Helmet>
                <h2>Make a payment</h2>
                <p>
                  No additional payments necessary! (
                  {displayMoney(groupInvoiceData.totalPaid)} already paid)
                </p>
                <Button kind="outline" size="small" onClick={close}>
                  Go Back
                </Button>
              </>
            )
          }
        }}
      </PanelRoute>
      <PanelRoute path={`${path}/invoice`}>
        {({ close }) => (
          <>
            <Helmet>
              <title>Invoice | Faith Based Expeditions</title>
            </Helmet>
            <InvoiceForm
              tour={tour}
              insurance={insurance}
              extensions={extensions}
              registrant={registrant}
              subregistrants={subregistrants}
              payments={payments}
              addons={addons}
              toPayment={`${url}/payment`}
            />
          </>
        )}
      </PanelRoute>
    </div>
  )
}

async function getBase64ImageFromUrl(imageUrl) {
  var res = await fetch(imageUrl)
  var blob = await res.blob()

  return new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.addEventListener(
      "load",
      function () {
        resolve(reader.result)
      },
      false
    )

    reader.onerror = () => {
      return reject(this)
    }
    reader.readAsDataURL(blob)
  })
}
