/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Search } from "js-search"
import moment from "moment"
import path from "path"
import queryString from "query-string"
import React from "react"
import * as Icon from "react-feather"
import { Helmet } from "react-helmet"
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  Redirect
} from "react-router-dom"
import Button from "../components/Button"
import DownloadManifestButton from "../components/DownloadManifestButton"
import Input from "../components/Input"
import Loading from "../components/Loading"
import PanelRoute from "../components/PanelRoute"
import Select from "../components/Select"
import { fonts } from "../components/Styles"
import { Cell, Row } from "../components/Table"
import Tag from "../components/Tag"
import Tooltip from "../components/Tooltip"
import TourMeta from "../components/TourMeta"
import { db, functions } from "../firebase"
import ExtensionForm from "../forms/ExtensionForm"
import TourForm from "../forms/TourForm"
import useLocalStorage from "../hooks/useLocalStorage"
import { useTheme } from "../theme"
import downloadRegistrantsReport from "../download/downloadRegistrantsReport"
import {
  displayAddress,
  displayAge,
  displayDateRange,
  displayFullName,
  displayMoney,
  displayPhone
} from "../utils/format"
import { getOfferingData } from "../utils/getOfferingData"
import {
  getRegistrantsReportData,
  getRegistrantsReportDataSums
} from "../utils/getRegistrantsReportData"
import { getTourReportData } from "../utils/getTourReportData"
import { ActivityTimeline } from "./Activity"
import TourDocuments from "./TourDocuments"

function RegistrantsReport({ tour, registrationsData }) {
  const { theme } = useTheme()
  const history = useHistory()

  // sync report view to url
  const initialSearch = queryString.parse(history.location.search)
  const [report, setReport] = React.useState(initialSearch.report || "manifest")
  React.useEffect(() => {
    const pathname = history.location.pathname
    const search = queryString.stringify({ report })
    if (report) {
      history.replace({ pathname, search })
    } else {
      history.replace(pathname)
    }
  }, [report, history])

  const [search, setSearch] = React.useState("")
  const searchRef = React.useRef(new Search("id"))
  React.useEffect(() => {
    if (registrationsData.length) {
      searchRef.current = new Search("id")
      searchRef.current.addIndex(["registrant", "name_first"])
      searchRef.current.addIndex(["registrant", "name_middle"])
      searchRef.current.addIndex(["registrant", "name_last"])
      searchRef.current.addIndex(["registrant", "email"])
      searchRef.current.addDocuments(registrationsData)
    }
  }, [registrationsData.length, tour.modified])
  let registrantResults = registrationsData
  if (search) {
    registrantResults = searchRef.current.search(search)
  }

  const reportSums = React.useMemo(() => {
    if (registrationsData.length) {
      return getRegistrantsReportDataSums(registrationsData)
    }
  }, [registrationsData, tour.modified])

  if (!registrationsData.length) {
    return <p>No registrations yet.</p>
  }

  return (
    <div
      css={{
        overflow: "auto",
        height: "100vh",
        "@media (max-width: 1000px)": {
          height: "calc(100vh - 3rem)"
        }
      }}>
      <header
        css={{
          zIndex: 4,
          position: "sticky",
          top: 0,
          left: 0,
          padding: "0.5rem 0",
          display: "flex",
          alignItems: "center",
          backgroundColor: theme.backgroundColor
        }}>
        <Select value={report} onChange={e => setReport(e.target.value)}>
          <option value="manifest">Manifest</option>
          <option value="billing">Billing</option>
          <option value="expenses">Expenses</option>
        </Select>
        &nbsp;
        <Input
          type="Search"
          placeholder="Search..."
          onChange={e => setSearch(e.target.value)}
          css={{ width: 200 }}
        />
        <div css={{ flexGrow: 1 }} />
        <DownloadManifestButton
          size="small"
          kind="outline"
          tourCode={tour.tour_code}
          accessCode={tour.id}
        />
        &nbsp;
        <Button
          size="small"
          kind="outline"
          onClick={() => downloadRegistrantsReport(tour, registrationsData)}>
          Download Report
        </Button>
      </header>
      <table
        css={{
          minWidth: "100%",
          fontSize: "0.875rem",
          th: {
            zIndex: 2,
            position: "sticky",
            top: "3.1rem",
            verticalAlign: "bottom",
            borderBottom: "1px solid " + theme.borderColor,
            backgroundColor: theme.backgroundColor
          },
          "th:first-of-type, td:first-of-type": {
            zIndex: 3,
            position: "sticky",
            left: 0,
            borderRight: "1px solid " + theme.borderColor,
            backgroundColor: theme.backgroundColor
          },
          "th:first-of-type": {
            zIndex: 4
          },
          "td:nth-of-type(2)": {
            borderSpacing: "1rem"
          }
        }}>
        <thead>
          <tr>
            <th>Passenger</th>
            <th />
            {report === "manifest" && (
              <>
                <th>Age</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Address</th>
                <th>Registered</th>
                <th>Tour Dates</th>
                <th>Tour Departure</th>
              </>
            )}
            {report === "billing" && (
              <>
                <th>Age</th>
                <th>Land & Air Price</th>
                <th>Land Only Price</th>
                <th>Early Registration Discount</th>
                <th>Extension Price</th>
                <th>Single Supplement Price</th>
                <th>Addons Price</th>
                <th>Tour Subtotal</th>
                <th>Insurance Premium</th>
                <th>Processing Fees</th>
                <th>Late Fees</th>
                <th>Total Tour Revenue</th>
                <th>Paid</th>
                <th>Balance</th>
              </>
            )}
            {report === "expenses" && (
              <>
                <th>Air Cost</th>
                <th>Land Cost</th>
                <th>Extension Cost</th>
                <th>Insurance Cost</th>
                <th>Gratuity</th>
                <th>Single Supplement Cost</th>
                <th>Addons Cost</th>
                <th>Headset</th>
                <th>Commission</th>
                <th>Bottled Water</th>
                <th>Business Class Upgrade</th>
                <th>Ground Transfer</th>
                <th>Total Expense</th>
                <th>Profit</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
          </tr>
          {registrantResults.map((data, index) => {
            return (
              <Row
                key={data.registrant.id}
                to={{
                  pathname: `/registrations/${data.registrant.id}`,
                  state: {
                    from: history.location.pathname + history.location.search
                  }
                }}
                css={{
                  color: data.registrant.inactive
                    ? theme.tertiaryText
                    : theme.primaryText,
                  td: {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    border: "1px solid transparent"
                  },
                  "@media (hover: hover)": {
                    ":hover td": {
                      borderTopColor: theme.borderColor,
                      borderBottomColor: theme.borderColor,
                      ":first-of-type": {
                        borderTopLeftRadius: 3,
                        borderBottomLeftRadius: 3,
                        borderLeftColor: theme.borderColor
                      },
                      ":last-child": {
                        borderTopRightRadius: 3,
                        borderBottomRightRadius: 3,
                        borderRightColor: theme.borderColor
                      }
                    }
                  }
                }}>
                <Cell>
                  {displayFullName(data.registrant)}{" "}
                  {data.registrant.registrant_id ? null : <Tag>P</Tag>}{" "}
                </Cell>
                <Cell />
                {report === "manifest" && (
                  <>
                    <Cell>{displayAge(data.registrant)}</Cell>
                    <Cell>{displayPhone(data.registrant.phone)}</Cell>
                    <Cell>{data.registrant.email}</Cell>
                    <Cell>{displayAddress(data.registrant)}</Cell>
                    <Cell>
                      {moment.utc(data.registrant.created).format("l")}
                    </Cell>
                    <Cell>
                      {displayDateRange(
                        data.registrant.tour_start_date,
                        data.registrant.tour_end_date
                      )}
                    </Cell>
                    <Cell>
                      {data.registrant.airtravel_yes
                        ? data.registrant.tour_departure
                        : null}
                    </Cell>
                  </>
                )}
                {report === "billing" && (
                  <>
                    <Cell>{displayAge(data.registrant)}</Cell>
                    <Cell>
                      {displayMoney(
                        data.registrant.airtravel_yes
                          ? data.invoiceData.tourTotal
                          : 0
                      )}
                    </Cell>
                    <Cell>
                      {displayMoney(
                        data.registrant.airtravel_yes
                          ? 0
                          : data.invoiceData.tourTotal
                      )}
                    </Cell>
                    <Cell>{displayMoney(-data.invoiceData.earlyDiscount)}</Cell>
                    <Cell>{displayMoney(data.invoiceData.extensionTotal)}</Cell>
                    <Cell>
                      {displayMoney(data.invoiceData.supplementTotal)}
                    </Cell>
                    <Cell>{displayMoney(data.invoiceData.addonsTotal)}</Cell>
                    <Cell>{displayMoney(data.invoiceData.subtotalDue)}</Cell>
                    <Cell>{displayMoney(data.invoiceData.insuranceTotal)}</Cell>
                    <Cell>{displayMoney(data.invoiceData.processingFee)}</Cell>
                    <Cell>{displayMoney(data.invoiceData.lateFee)}</Cell>
                    <Cell>{displayMoney(data.invoiceData.totalDue)}</Cell>
                    <Cell>{displayMoney(data.invoiceData.totalPaid)}</Cell>
                    <Cell>{displayMoney(data.invoiceData.totalRemaining)}</Cell>
                  </>
                )}
                {report === "expenses" && (
                  <>
                    <Cell>{displayMoney(data.expenseData.airCost)}</Cell>
                    <Cell>{displayMoney(data.expenseData.landCost)}</Cell>
                    <Cell>{displayMoney(data.expenseData.extensionCost)}</Cell>
                    <Cell>{displayMoney(data.expenseData.insuranceCost)}</Cell>
                    <Cell>{displayMoney(data.expenseData.gratuityCost)}</Cell>
                    <Cell>{displayMoney(data.expenseData.supplementCost)}</Cell>
                    <Cell>{displayMoney(data.expenseData.addonsCost)}</Cell>
                    <Cell>{displayMoney(data.expenseData.headsetCost)}</Cell>
                    <Cell>{displayMoney(data.expenseData.commissionCost)}</Cell>
                    <Cell>
                      {displayMoney(data.expenseData.bottledWaterCost)}
                    </Cell>
                    <Cell>
                      {displayMoney(
                        data.expenseData.tourLeaderBusinessClassUpgradeCost
                      )}
                    </Cell>
                    <Cell>
                      {displayMoney(data.expenseData.groundTransferCost)}
                    </Cell>
                    <Cell>{displayMoney(data.expenseData.totalCost)}</Cell>
                    <Cell>
                      {displayMoney(
                        data.invoiceData.totalDue - data.expenseData.totalCost
                      )}
                    </Cell>
                  </>
                )}
              </Row>
            )
          })}
          {report !== "manifest" && (
            <Row
              css={{
                td: {
                  zIndex: 2,
                  // position: "sticky",
                  // bottom: 0,
                  color: theme.accentText,
                  borderTop: "1px solid " + theme.borderColor,
                  backgroundColor: theme.backgroundColor
                }
              }}>
              <Cell>Totals</Cell>
              <Cell />
              {report === "billing" && (
                <>
                  <Cell />
                  <Cell>{displayMoney(reportSums.landAirTotal)}</Cell>
                  <Cell>{displayMoney(reportSums.landOnlyTotal)}</Cell>
                  <Cell>{displayMoney(-reportSums.earlyDiscount)}</Cell>
                  <Cell>{displayMoney(reportSums.extensionTotal)}</Cell>
                  <Cell>{displayMoney(reportSums.supplementTotal)}</Cell>
                  <Cell>{displayMoney(reportSums.addonsTotal)}</Cell>
                  <Cell>{displayMoney(reportSums.subtotalDue)}</Cell>
                  <Cell>{displayMoney(reportSums.insuranceTotal)}</Cell>
                  <Cell>{displayMoney(reportSums.processingFee)}</Cell>
                  <Cell>{displayMoney(reportSums.lateFee)}</Cell>
                  <Cell>{displayMoney(reportSums.totalDue)}</Cell>
                  <Cell>{displayMoney(reportSums.totalPaid)}</Cell>
                  <Cell>{displayMoney(reportSums.totalRemaining)}</Cell>
                </>
              )}
              {report === "expenses" && (
                <>
                  <Cell>{displayMoney(reportSums.airCost)}</Cell>
                  <Cell>{displayMoney(reportSums.landCost)}</Cell>
                  <Cell>{displayMoney(reportSums.extensionCost)}</Cell>
                  <Cell>{displayMoney(reportSums.insuranceCost)}</Cell>
                  <Cell>{displayMoney(reportSums.gratuityCost)}</Cell>
                  <Cell>{displayMoney(reportSums.supplementCost)}</Cell>
                  <Cell>{displayMoney(reportSums.addonsCost)}</Cell>
                  <Cell>{displayMoney(reportSums.headsetCost)}</Cell>
                  <Cell>{displayMoney(reportSums.commissionCost)}</Cell>
                  <Cell>{displayMoney(reportSums.bottledWaterCost)}</Cell>
                  <Cell>
                    {displayMoney(
                      reportSums.tourLeaderBusinessClassUpgradeCost
                    )}
                  </Cell>
                  <Cell>{displayMoney(reportSums.groundTransferCost)}</Cell>
                  <Cell>{displayMoney(reportSums.totalCost)}</Cell>
                  <Cell>
                    {displayMoney(reportSums.totalDue - reportSums.totalCost)}
                  </Cell>
                </>
              )}
            </Row>
          )}
        </tbody>
      </table>
    </div>
  )
}

function Figure({ stat, money, label, tooltip }) {
  const { theme } = useTheme()

  return (
    <li css={{ padding: "0.5rem" }}>
      <figure
        css={{
          margin: 0,
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 3,
          border: `1px solid ${theme.borderColor}`
        }}>
        <h3
          css={{
            position: "relative",
            margin: 0,
            fontFamily: fonts.ideal,
            fontWeight: "normal",
            letterSpacing: "0rem",
            span: {
              position: "absolute",
              right: "100%",
              bottom: "0.2rem",
              fontSize: "1rem"
            }
          }}>
          {String(stat).includes("$") ? <span>$</span> : null}
          {String(stat).replace("$", "")}
        </h3>
        <small>
          {label}
          &nbsp;
          {tooltip ? (
            <Tooltip label={tooltip}>
              <span>
                <Icon.Info size="1em" />
              </span>
            </Tooltip>
          ) : null}
        </small>
      </figure>
    </li>
  )
}

function FigureContainer({ children }) {
  return (
    <ul
      css={{
        listStyle: "none",
        padding: 0,
        margin: "1rem -0.5rem",
        display: "flex",
        flexWrap: "wrap",
        "& > h5": {
          width: "100%",
          margin: "1rem 0.5rem 0"
        }
      }}>
      {children}
    </ul>
  )
}

function TourReportOverview({ reportData }) {
  if (!reportData.mainTourLandOnlyCount) {
    return <p>No registrations yet.</p>
  }

  return (
    <FigureContainer>
      <h5>Travelers</h5>
      <Figure
        stat={reportData.mainTourLandOnlyCount}
        label="Main Tour"
        tooltip="Total number of travelers doing the main tour."
      />
      <Figure
        stat={reportData.preTourExtensionLandOnlyCount}
        label="Pre-Tour"
        tooltip="Total number of travelers doing the pre-tour extension and main tour."
      />
      <Figure
        stat={reportData.postTourExtensionLandOnlyCount}
        label="Post-Tour"
        tooltip="Total number of travelers doing the main tour and post-tour extension."
      />
      <Figure
        stat={reportData.preAndPostTourExtensionLandOnlyCount}
        label="Pre & Post"
        tooltip="Total number of travelers doing the pre-tour extension, main tour, and post-tour extension."
      />
      <h5>Group Air</h5>
      <Figure
        stat={reportData.mainTourLandAndAirMainTourOnlyCount}
        label="Main Tour Only"
        tooltip="Total number of travelers taking group air departure flight for main tour and group air return flight for main tour."
      />
      <Figure
        stat={reportData.preTourExtensionAirCount}
        label="Pre-Tour"
        tooltip="Total number of travelers taking group air departure flight for pre-tour extension and group air return flight for main tour."
      />
      <Figure
        stat={reportData.postTourExtensionAirCount}
        label="Post-Tour"
        tooltip="Total number of travelers taking group air departure flight for main tour and group air return flight for post-tour extension."
      />
      <Figure
        stat={reportData.preAndPostTourExtensionAirCount}
        label="Pre & Post"
        tooltip="Total number of travelers taking group air departure flight for pre-tour extension and group air return flight for post-tour extension."
      />
      <h5>Single Supplements</h5>
      <Figure
        stat={reportData.mainTourSingleSupplementCount}
        label="Main Tour"
        tooltip="Total number of travelers rooming alone during main tour."
      />
      <Figure
        stat={reportData.preTourExtensionSupplementCount}
        label="Pre-Tour"
        tooltip="Total number of travelers rooming alone during pre-tour extension."
      />
      <Figure
        stat={reportData.postTourExtensionSupplementCount}
        label="Post-Tour"
        tooltip="Total number of travelers rooming alone during post-tour extension."
      />
      <h5>Extra Room Nights</h5>
      <Figure
        stat={reportData.earlyArrivalExtraRoomNightsCount}
        label="Early Arrival"
        tooltip="Total number of extra room nights for travelers arriving early."
      />
      <Figure
        stat={reportData.lateDepartureExtraRoomNightsCount}
        label="Late Departure"
        tooltip="Total number of extra room nights for travelers departing late."
      />
      <h5>Free Slots</h5>
      <Figure
        stat={reportData.mainTourLandOnlyFreeCount}
        label="Land Only"
        tooltip="Total number of free slots (1 after every 10) for land-only travelers."
      />
      <Figure
        stat={reportData.mainTourLandAndAirFreeCount}
        label="Land & Group Air"
        tooltip="Total number of free slots (1 after every 10) for land and group air travelers."
      />
    </FigureContainer>
  )
}

function TourReportExpenses({ tour, reportData }) {
  const [syncing, setSyncing] = React.useState(false)
  if (!reportData.totalCost) {
    return <p>No registrations yet.</p>
  }

  return (
    <div css={{ position: "relative" }}>
      <div css={{ position: "absolute", right: 0, top: 0 }}>
        <Button
          kind="outline"
          size="small"
          onClick={async () => {
            try {
              setSyncing(true)
              const generateReport =
                functions.httpsCallable("generateTourReport")
              const syncTourReport = functions.httpsCallable("syncTourReport")
              await generateReport({ tourId: tour.id })
              await syncTourReport({ tourId: tour.id })
            } catch (error) {
              alert(error.message)
            } finally {
              setSyncing(false)
            }
          }}>
          {syncing ? "Syncing..." : "Sync Expenses"}
        </Button>
        &nbsp;
        <Button
          kind="outline"
          size="small"
          target="_blank"
          href={`https://docs.google.com/spreadsheets/d/${process.env.REACT_APP_GOOGLE_SHEET_ID}`}>
          View Expenses
        </Button>
      </div>
      <FigureContainer>
        <h5>Land Costs</h5>
        <Figure
          stat={displayMoney(reportData.mainTourLandCost)}
          label="Main Tour"
        />
        <Figure
          stat={displayMoney(reportData.preTourExtensionLandOnlyCost)}
          label="Pre-Tour"
        />
        <Figure
          stat={displayMoney(reportData.postTourExtensionLandOnlyCost)}
          label="Post-Tour"
        />
        <h5>Group Air Costs</h5>
        <Figure
          stat={displayMoney(reportData.mainTourGroupAirCost)}
          label="Main Tour Only"
        />
        <Figure
          stat={displayMoney(reportData.preTourExtensionGroupAirCost)}
          label="Pre-Tour"
        />
        <Figure
          stat={displayMoney(reportData.postTourExtensionGroupAirCost)}
          label="Post-Tour"
        />
        <Figure
          stat={displayMoney(reportData.preAndPostTourExtensionGroupAirCost)}
          label="Pre & Post"
        />
        <h5>Local Air Costs</h5>
        <Figure
          stat={displayMoney(reportData.mainTourInCountryAirCost)}
          label="Main Tour"
        />
        <Figure
          stat={displayMoney(reportData.preExtensionInCountryAirCost)}
          label="Pre-Tour"
        />
        <Figure
          stat={displayMoney(reportData.postExtensionInCountryAirCost)}
          label="Post-Tour"
        />
        <h5>Single Supplement Costs</h5>
        <Figure
          stat={displayMoney(reportData.mainTourGratuityCost)}
          label="Main Tour"
        />
        <Figure
          stat={displayMoney(reportData.preExtensionSingleSupplementCost)}
          label="Pre-Tour"
        />
        <Figure
          stat={displayMoney(reportData.postExtensionSingleSupplementCost)}
          label="Post-Tour"
        />
        <h5>Extra Room Night Costs</h5>
        <Figure
          stat={displayMoney(reportData.earlyArrivalExtraRoomNightsCost)}
          label="Early Arrivals"
        />
        <Figure
          stat={displayMoney(reportData.lateDepartureExtraRoomNightsCost)}
          label="Late Departures"
        />
        <h5>Gratuity Costs</h5>
        <Figure
          stat={displayMoney(reportData.mainTourGratuityCost)}
          label="Main Tour"
        />
        <Figure
          stat={displayMoney(reportData.extensionGratuityCost)}
          label="Extension"
        />
        <h5>Insurance Costs</h5>
        <Figure
          stat={displayMoney(reportData.travelInsurancePremiumCost)}
          label="Insurance Premium"
        />
        <Figure
          stat={displayMoney(reportData.travelInsuranceLessCommCost)}
          label="Less Commision"
        />
        <h5>Misc.</h5>
        <Figure stat={displayMoney(reportData.headsetsCost)} label="Headsets" />
        <Figure
          stat={displayMoney(reportData.oneTwentyBenefit)}
          label="1:20 Benefit"
        />
        <Figure stat={displayMoney(reportData.commission)} label="Commission" />
        <Figure
          stat={displayMoney(reportData.bottledWaterCost)}
          label="Bottled Water"
        />
        <Figure
          stat={displayMoney(reportData.tourLeaderBusinessClassUpgradeCost)}
          label="Business Class Upgrade"
        />
        <Figure
          stat={displayMoney(reportData.groundTransferCost)}
          label="Ground Transfer"
        />
      </FigureContainer>
    </div>
  )
}

let useState
if (process.env.NODE_ENV === "development") {
  useState = useLocalStorage
} else {
  useState = function (key, initialValue) {
    return React.useState(initialValue)
  }
}

export default function Tour() {
  const { tourId } = useParams()

  const location = useLocation()

  const { theme } = useTheme()

  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [cached, setCached] = React.useState(false)

  const [tour, setTour] = useState("tour", null)
  const [tourCosts, setTourCosts] = useState("tourCosts", null)
  const [accessCode, setAccessCode] = useState("accessCode", null)
  const [insurance, setInsurance] = useState("insurance", null)
  const [offerings, setOfferings] = useState("offerings", [])
  const [extensions, setExtensions] = useState("extensions", [])
  const [extensionsCosts, setExtensionsCosts] = useState("extensionsCosts", []) // prettier-ignore
  const [registrants, setRegistrants] = useState("registrants", [])
  const [payments, setPayments] = useState("payments", [])
  const [addons, setAddons] = useState("addons", [])

  React.useEffect(() => {
    // if (process.env.NODE_ENV === "development" && tour?.id == tourId) {
    //   setCached(true)
    // }
    loadData()
  }, [tourId])

  async function loadData() {
    if (tour && tour.id == tourId) {
      setLoading(false)
      return
    }

    try {
      setLoading(true)

      const tourRef = db.collection("tours").doc(tourId)
      const tourCostsRef = db.collection("tour_costs").doc(tourId)
      const accessCodeRef = db.collection("access_codes").doc(tourId)
      const offeringsRef = tourRef.collection("offerings")
      const extensionsRef = tourRef.collection("extensions")
      const extensionsCostsRef = db
        .collection("extension_costs")
        .where("tour_id", "==", tourId)
      const registrantsRef = db
        .collection("registrants")
        .where("tour_id", "==", tourId)
      const paymentsRef = db
        .collectionGroup("payments")
        .where("tour_id", "==", tourId)
      const addonsRef = db
        .collectionGroup("addons")
        .where("tour_id", "==", tourId)

      const [
        tourDoc,
        tourCostsDoc,
        accessCodeDoc,
        offeringDocs,
        extensionDocs,
        extensionCostsDocs,
        registrantDocs,
        paymentDocs,
        addonDocs
      ] = await Promise.all([
        tourRef.get(),
        tourCostsRef.get(),
        accessCodeRef.get(),
        offeringsRef.get(),
        extensionsRef.get(),
        extensionsCostsRef.get(),
        registrantsRef.get(),
        paymentsRef.get(),
        addonsRef.get()
      ])

      const tour = tourDoc.data()
      if (!tour) {
        throw new Error("Tour not found.")
      }

      const tourCosts = tourCostsDoc.data()
      if (!tourCosts) {
        throw new Error("Tour costs not found.")
      }

      const accessCode = accessCodeDoc.data()

      let insurance = null
      if (tour.insurance_id) {
        const insuranceRef = db.collection("insurances").doc(tour.insurance_id)
        const insuranceDoc = await insuranceRef.get()
        insurance = insuranceDoc.data()
        if (!insurance) {
          throw new Error("Insurance not found.")
        }
      }

      const offerings = []
      offeringDocs.forEach(offeringDoc => {
        offerings.push(offeringDoc.data())
      })
      if (!offerings.length) {
        throw new Error("Tour offerings not found.")
      }

      const extensions = []
      extensionDocs.forEach(extensionDoc => {
        extensions.push(extensionDoc.data())
      })
      const extensionsCosts = []
      extensionCostsDocs.forEach(extensionCostsDoc => {
        extensionsCosts.push(extensionCostsDoc.data())
      })
      const registrants = []
      registrantDocs.forEach(registrantDoc => {
        registrants.push(registrantDoc.data())
      })
      const payments = []
      paymentDocs.forEach(paymentDoc => {
        payments.push(paymentDoc.data())
      })
      const addons = []
      addonDocs.forEach(addonDoc => {
        addons.push(addonDoc.data())
      })

      setTour(tour)
      setInsurance(insurance)
      setTourCosts(tourCosts)
      setAccessCode(accessCode)
      setOfferings(offerings)
      setExtensions(extensions)
      setExtensionsCosts(extensionsCosts)
      setRegistrants(registrants)
      setPayments(payments)
      setAddons(addons)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const registrationsData = React.useMemo(() => {
    if (!loading && !error) {
      return getRegistrantsReportData(
        tour,
        tourCosts,
        extensions,
        extensionsCosts,
        insurance,
        registrants,
        addons,
        payments
      )
    }
  }, [loading, error, registrants.length, tour?.modified])

  const reportData = React.useMemo(() => {
    if (!loading && !error) {
      return getTourReportData(
        tour,
        tourCosts,
        extensions,
        extensionsCosts,
        insurance,
        registrants,
        addons,
        payments
      )
    }
  }, [loading, error, registrants.length, tour?.modified])

  const offeringData = React.useMemo(() => {
    if (!loading) {
      return getOfferingData(offerings)
    }
  }, [loading, offerings])

  if (error) {
    console.error(error)
    return <p>Unable to load tour. {error.message}</p>
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Helmet>
        <title>{tour.title} | Faith Based Expeditions</title>
      </Helmet>
      <header
        css={{
          margin: "0 -2rem",
          padding: "2rem 2rem 0",
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          backgroundImage: `url(${theme.patternImage})`,
          borderBottom: `1px solid ${theme.borderColor}`
        }}>
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <Button to="/tours">
            <Icon.ArrowLeft size="1.5rem" />
          </Button>
          &nbsp;
          <div>
            <Button
              size="small"
              kind="outline"
              onClick={async () => {
                // prettier-ignore
                if (!window.confirm(`Are you sure you want to set this tour ${tour.inactive ? "active" : "inactive"}?`)) {
                  return
                }
                const tourRef = db.collection("tours").doc(tourId)
                const tourUpdate = {
                  inactive: !tour.inactive,
                  modified: Date.now()
                }
                await tourRef.update(tourUpdate)
                setTour(tour => {
                  return { ...tour, ...tourUpdate }
                })
              }}>
              Set {tour.inactive ? "Active" : "Inactive"}
            </Button>
            &nbsp;
            <Button
              size="small"
              kind="fill"
              to={path.join(location.pathname, "/edit")}>
              Edit Tour
            </Button>
          </div>
        </div>
        <h2 className="num-lines-2">
          {tour.title}
          {cached && <span> (cached)</span>}
        </h2>
        <TourMeta
          tour={tour}
          leaders={offeringData.uniqueLeaders}
          departures={offeringData.uniqueDepartures}
          tourDates={offeringData.upcomingDates}
          showRegister
          showFront
        />
        <br />
        <nav
          css={{
            a: {
              display: "inline-block",
              marginRight: "1rem"
            }
          }}>
          <NavLink
            to={`/tours/${tourId}/registrations`}
            style={{ textDecoration: "none" }}
            activeStyle={{ textDecoration: "underline" }}>
            <h3>Registrations</h3>
          </NavLink>
          <NavLink
            to={`/tours/${tourId}/activity`}
            style={{ textDecoration: "none" }}
            activeStyle={{ textDecoration: "underline" }}>
            <h3>Activity</h3>
          </NavLink>
          <NavLink
            to={`/tours/${tourId}/documents`}
            style={{ textDecoration: "none" }}
            activeStyle={{ textDecoration: "underline" }}>
            <h3>Documents</h3>
          </NavLink>
          <NavLink
            exact
            to={`/tours/${tourId}/extensions`}
            style={{ textDecoration: "none" }}
            activeStyle={{ textDecoration: "underline" }}>
            <h3>Extensions</h3>
          </NavLink>
          <NavLink
            to={`/tours/${tourId}/expenses`}
            style={{ textDecoration: "none" }}
            activeStyle={{ textDecoration: "underline" }}>
            <h3>Expenses</h3>
          </NavLink>
          <NavLink
            exact
            to={`/tours/${tourId}/overview`}
            style={{ textDecoration: "none" }}
            activeStyle={{ textDecoration: "underline" }}>
            <h3>Overview</h3>
          </NavLink>
        </nav>
      </header>
      <Switch>
        <Route path={`/tours/:tourId`} exact>
          <Redirect to={`/tours/${tourId}/registrations`} />
        </Route>
        <Route path={`/tours/:tourId/registrations`}>
          <Helmet>
            <title>
              Registrations for {tour.title} | Faith Based Expeditions
            </title>
          </Helmet>
          <RegistrantsReport
            tour={tour}
            registrationsData={registrationsData}
          />
        </Route>
        <Route path={`/tours/:tourId/expenses`}>
          <TourReportExpenses tour={tour} reportData={reportData} />
        </Route>
        <Route path={`/tours/:tourId/activity`}>
          <Helmet>
            <title>Activity for {tour.title} | Faith Based Expeditions</title>
          </Helmet>
          <br />
          <ActivityTimeline tourId={tourId} />
        </Route>
        <Route path={`/tours/:tourId/documents`}>
          <Helmet>
            <title>Documents for {tour.title} | Faith Based Expeditions</title>
          </Helmet>
          <TourDocuments tourId={tourId} showUpload={true} />
        </Route>
        <Route path={`/tours/:tourId/extensions`}>
          <Helmet>
            <title>Extensions for {tour.title} | Faith Based Expeditions</title>
          </Helmet>
          <div>
            <br />
            <header
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <h3 css={{ margin: 0 }}>Extensions</h3>
              <Button
                size="small"
                kind="fill"
                to={`/tours/${tourId}/extensions/new`}>
                Add Extension
              </Button>
            </header>
            {extensions.length ? (
              <ul
                css={{
                  margin: 0,
                  padding: 0,
                  listStyle: "none"
                }}>
                {extensions.map(x => (
                  <li
                    key={x.id}
                    css={{
                      marginTop: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}>
                    <div>
                      <h5 css={{ margin: 0 }}>{x.title}</h5>
                      <div>
                        <Tag disabled={x.inactive}>
                          {x.inactive ? "Inactive" : "Active"}
                        </Tag>
                        &nbsp;
                        <small>
                          {displayDateRange(x.start_date, x.end_date)}
                          &mdash;
                          {displayMoney(x.price)}
                        </small>
                      </div>
                    </div>
                    <div>
                      <Button
                        size="small"
                        kind="outline"
                        onClick={async () => {
                          // prettier-ignore
                          if (!window.confirm(`Are you sure you want to make this extension ${x.inactive ? "active" : "inactive"}?`)) {
                            return
                          }
                          const extensionRef = db
                            .collection("tours")
                            .doc(tourId)
                            .collection("extensions")
                            .doc(x.id)
                          const extensionUpdate = {
                            inactive: !x.inactive,
                            modified: Date.now()
                          }
                          await extensionRef.update(extensionUpdate)
                          setExtensions(extensions => {
                            return extensions.map(extension => {
                              if (extension.id == x.id) {
                                return { ...extension, ...extensionUpdate }
                              }
                              return extension
                            })
                          })
                        }}>
                        Set {x.inactive ? "Active" : "Inactive"}
                      </Button>
                      &nbsp;
                      <Button
                        size="small"
                        kind="outline"
                        to={`/tours/${tourId}/extensions/${x.id}`}>
                        Edit
                      </Button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No extensions yet.</p>
            )}
            <br />
            <br />
            <br />
          </div>
        </Route>
        <Route path={`/tours/:tourId/overview`}>
          <TourReportOverview reportData={reportData} />
        </Route>
      </Switch>
      <PanelRoute
        backTo={path.join(location.pathname, "../")}
        path={[
          `/tours/:tourId/edit`,
          `/tours/:tourId/registrations/edit`,
          `/tours/:tourId/activity/edit`,
          `/tours/:tourId/documents/edit`,
          `/tours/:tourId/extensions/edit`,
          `/tours/:tourId/expenses/edit`
        ]}>
        {({ params, close }) => (
          <>
            <Helmet>
              <title>Edit Tour | Faith Based Expeditions</title>
            </Helmet>
            <h2>Edit Tour</h2>
            <TourForm
              tour={tour}
              offerings={offerings}
              tourCosts={tourCosts}
              accessCode={accessCode}
              prompt="Save Changes"
              onSubmit={async (
                tourData,
                offeringsData,
                tourCostsData,
                accessCodeData
              ) => {
                const { tourId } = params

                const batch = db.batch()

                const tourRef = db.collection("tours").doc(tourId)
                const tourUpdate = { ...tourData, modified: Date.now() }
                batch.update(tourRef, tourUpdate)

                const offeringsUpdates = []
                const registrantsUpdates = []
                offeringsData.forEach(offeringData => {
                  const offering = offerings.find(x => x.id == offeringData.id)
                  if (offering) {
                    offeringsUpdates.push({ ...offering, ...offeringData })
                  } else {
                    const offeringRef = tourRef.collection("offerings").doc()
                    offeringsUpdates.push({
                      ...offeringData,
                      id: offeringRef.id,
                      tour_id: tourId,
                      created: Date.now(),
                      modified: null
                    })
                  }
                  // if enabled to update existing registrants, extract
                  // updates for any registrants on this offering
                  if (offeringData._update_existing_registrants) {
                    registrants.forEach(registrant => {
                      if (registrant.offering_id == offeringData.id) {
                        registrantsUpdates.push({
                          modified: Date.now(),
                          id: registrant.id,
                          tour_price: registrant.airtravel_yes
                            ? offeringData.air_price
                            : offeringData.land_price
                        })
                      }
                    })
                  }
                })
                offeringsUpdates.forEach(offeringUpdate => {
                  const offeringRef = tourRef
                    .collection("offerings")
                    .doc(offeringUpdate.id)
                  batch.set(offeringRef, offeringUpdate)
                })
                registrantsUpdates.forEach(registrantUpdate => {
                  const registrantRef = db
                    .collection("registrants")
                    .doc(registrantUpdate.id)
                  batch.update(registrantRef, registrantUpdate)
                })

                const tourCostsRef = db.collection("tour_costs").doc(tourId)
                const tourCostsUpdate = {
                  ...tourCostsData,
                  modified: Date.now()
                }
                batch.update(tourCostsRef, tourCostsUpdate)

                const accessCodeRef = db.collection("access_codes").doc(tourId)
                batch.set(accessCodeRef, accessCodeData)

                await batch.commit()

                setTour(tour => {
                  return { ...tour, ...tourUpdate }
                })
                setTourCosts(tourCosts => {
                  return { ...tourCosts, ...tourCostsUpdate }
                })
                setOfferings(offeringsUpdates)
                setRegistrants(registrants => {
                  return registrants.map(registrant => {
                    const registrantUpdate = registrantsUpdates.find(
                      x => x.id == registrant.id
                    )
                    if (registrantUpdate) {
                      console.log("MERGING UPDATE")
                      return { ...registrant, ...registrantUpdate }
                    } else {
                      return registrant
                    }
                  })
                })
                setAccessCode(accessCodeData)

                close()

                window.location.reload()
              }}
            />
          </>
        )}
      </PanelRoute>
      <PanelRoute
        open={match => match && match.params.extensionId !== "edit"}
        backTo={`/tours/${tourId}/extensions`}
        path={`/tours/:tourId/extensions/:extensionId`}>
        {({ params, close }) => {
          const isNew = params.extensionId == "new"

          async function createExtension(extensionForm, extensionCostsForm) {
            const batch = db.batch()

            const extensionRef = db
              .collection("tours")
              .doc(tourId)
              .collection("extensions")
              .doc()
            const extensionData = {
              ...extensionForm,
              id: extensionRef.id,
              tour_id: tourId,
              created: Date.now(),
              inactive: false,
              modified: null
            }

            const extensionCostsRef = db
              .collection("extension_costs")
              .doc(extensionRef.id)
            const extensionCostsData = {
              ...extensionCostsForm,
              id: extensionRef.id,
              tour_id: tourId,
              created: Date.now(),
              modified: null
            }

            batch.set(extensionRef, extensionData)
            batch.set(extensionCostsRef, extensionCostsData)

            await batch.commit()

            setExtensions(extensions => {
              return extensions.concat(extensionData)
            })
            setExtensionsCosts(extensionsCosts => {
              return extensionsCosts.concat(extensionCostsData)
            })

            close()
          }

          async function updateExtension(extensionForm, extensionCostsForm) {
            const batch = db.batch()

            const extensionRef = db
              .collection("tours")
              .doc(tourId)
              .collection("extensions")
              .doc(params.extensionId)
            const extensionUpdate = {
              ...extensionForm,
              modified: Date.now()
            }

            const extensionCostsRef = db
              .collection("extension_costs")
              .doc(params.extensionId)
            const extensionCostsUpdate = {
              ...extensionCostsForm,
              modified: Date.now()
            }

            let registrantsUpdates = []
            // if enabled to update existing registrants, extract
            // updates for any registrants on this extension
            if (extensionForm._update_existing_registrants) {
              registrants.forEach(registrant => {
                if (registrant.extension_id == params.extensionId) {
                  registrantsUpdates.push({
                    modified: Date.now(),
                    id: registrant.id,
                    extension_price: extensionForm.price
                  })
                }
              })
            }

            registrantsUpdates.forEach(registrantUpdate => {
              const registrantRef = db
                .collection("registrants")
                .doc(registrantUpdate.id)
              batch.update(registrantRef, registrantUpdate)
            })

            batch.update(extensionRef, extensionUpdate)

            batch.update(extensionCostsRef, extensionCostsUpdate)

            await batch.commit()

            setExtensions(extensions => {
              return extensions.map(x => {
                if (x.id == params.extensionId) {
                  return { ...x, ...extensionUpdate }
                }
                return x
              })
            })

            setExtensionsCosts(extensionsCosts => {
              return extensionsCosts.map(x => {
                if (x.id == params.extensionId) {
                  return { ...x, ...extensionCostsUpdate }
                }
                return x
              })
            })

            close()

            window.location.reload()
          }

          if (isNew) {
            return (
              <>
                <Helmet>
                  <title>Create Extension | Faith Based Expeditions</title>
                </Helmet>
                <h2>Create Extension</h2>
                <ExtensionForm onSubmit={createExtension} />
              </>
            )
          } else {
            return (
              <>
                <Helmet>
                  <title>Update Extension | Faith Based Expeditions</title>
                </Helmet>
                <h2>Update Extension</h2>
                <ExtensionForm
                  extension={extensions.find(x => x.id == params.extensionId)}
                  extensionCosts={extensionsCosts.find(
                    x => x.id == params.extensionId
                  )}
                  onSubmit={updateExtension}
                />
              </>
            )
          }
        }}
      </PanelRoute>
    </>
  )
}
