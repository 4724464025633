/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { NavLink } from "react-router-dom"
import { useTheme } from "../theme"

export default function TabLink(props) {
  const { theme } = useTheme()

  return (
    <NavLink
      style={{
        display: "inline-block",
        marginBottom: -1,
        padding: "0.2rem 0",
        borderBottomWidth: 2,
        borderBottomStyle: "solid",
        borderBottomColor: "transparent",
        textDecoration: "none"
      }}
      activeStyle={{ borderBottomColor: theme.accentText }}
      {...props}>
      <h3 style={{ margin: 0 }}>{props.children}</h3>
    </NavLink>
  )
}
