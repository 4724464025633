import React from "react"
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom"
import Panel from "./Panel"

export default function PanelRoute(props) {
  const history = useHistory()
  const routeMatch = useRouteMatch()

  function close() {
    history.push(props.backTo || routeMatch.url) // go back to parent route
  }

  return (
    <Route {...props}>
      {({ match }) => (
        <Panel
          open={props.open ? props.open(match) : !!match}
          onClose={close}
          title={props.title}>
          {match
            ? typeof props.children === "function"
              ? props.children({ params: match.params, close })
              : props.children
            : null}
        </Panel>
      )}
    </Route>
  )
}
