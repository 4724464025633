import { displayPhone } from "../utils/format"

export default async function downloadManifest(tour, registrants) {
  const Excel = await import("exceljs")

  const workbook = new Excel.Workbook()

  workbook.created = new Date()

  const worksheet = workbook.addWorksheet("Manifest")

  // add column headers
  worksheet.columns = [
    { header: "#", key: "active", width: 10 },
    { header: "Free Spot", key: "free_spot", width: 10 },
    { header: "Title", key: "title", width: 10 },
    { header: "First Name", key: "name_first", width: 20 },
    { header: "Last Name", key: "name_last", width: 20 },
    { key: "d1", width: 1 },
    { header: "Main Tour Only", key: "land_main_only", width: 10 },
    { header: "With Extension", key: "land_extension", width: 10 },
    { header: "Main Tour Only", key: "air_main_only", width: 10 },
    { header: "With Extension", key: "air_extension", width: 10 },
    { key: "d2", width: 1 },
    { header: "Address", key: "address", width: 30 },
    { header: "City", key: "city", width: 20 },
    { header: "State", key: "state", width: 10 },
    { header: "Zip", key: "zip", width: 10 },
    { header: "Email", key: "email", width: 30 },
    { header: "Phone", key: "phone", width: 20 }
  ]

  // add two rows above column headers
  worksheet.spliceRows(1, 0, [], [])

  // add header info
  worksheet.getCell("A1").value = tour.title
  worksheet.mergeCells("A1:P1")

  worksheet.getCell("G2").value = "Land Only"
  worksheet.getCell("G2").fill = styles.fillRed
  worksheet.mergeCells("G2:H2")

  worksheet.getCell("I2").value = "Land & Air"
  worksheet.getCell("I2").fill = styles.fillRed
  worksheet.mergeCells("I2:J2")

  worksheet.getRow(1).alignment = styles.alignCenter
  worksheet.getRow(2).alignment = styles.alignCenter
  worksheet.getRow(3).alignment = styles.alignCenter
  worksheet.getRow(3).fill = styles.fillBlue

  // add registrants
  registrants.forEach(registrant => {
    // prettier-ignore
    worksheet.addRow({
      active: registrant.inactive ? undefined : 1,
      free_spot: registrant.inactive ? undefined : registrant.tour_leader_yes ? 1 : undefined,
      title: registrant.title,
      name_first: registrant.name_first,
      name_last: registrant.name_last,
      land_main_only: registrant.inactive ? undefined : registrant.airtravel_yes ? undefined : registrant.extension_id ? undefined : 1,
      land_extension: registrant.inactive ? undefined : registrant.airtravel_yes ? undefined : registrant.extension_id ? 1 : undefined,
      air_main_only: registrant.inactive ? undefined : registrant.airtravel_yes && !registrant.extension_id ? 1 : undefined,
      air_extension: registrant.inactive ? undefined : registrant.airtravel_yes && registrant.extension_id ? 1 : undefined,
      address: registrant.mailing_address_street,
      city: registrant.mailing_address_city,
      state: registrant.mailing_address_state,
      zip: registrant.mailing_address_zip,
      email: registrant.email,
      phone: displayPhone(registrant.phone)
    })
    if (registrant.tour_leader_yes) {
      worksheet.lastRow.fill = styles.fillGreen
    }
    if (registrant.inactive) {
      worksheet.lastRow.fill = styles.fillGray
    }
  })

  // add totals
  worksheet.addRow()

  worksheet.addRow({
    active: {
      formula: `SUM(${worksheet.getColumn("active").letter}4:${
        worksheet.lastRow.getCell("active").address
      })`
    },
    free_spot: {
      formula: `SUM(${worksheet.getColumn("free_spot").letter}4:${
        worksheet.lastRow.getCell("free_spot").address
      })`
    },
    land_main_only: {
      formula: `SUM(${worksheet.getColumn("land_main_only").letter}4:${
        worksheet.lastRow.getCell("land_main_only").address
      })`
    },
    land_extension: {
      formula: `SUM(${worksheet.getColumn("land_extension").letter}4:${
        worksheet.lastRow.getCell("land_extension").address
      })`
    },
    air_main_only: {
      formula: `SUM(${worksheet.getColumn("air_main_only").letter}4:${
        worksheet.lastRow.getCell("air_main_only").address
      })`
    },
    air_extension: {
      formula: `SUM(${worksheet.getColumn("air_extension").letter}4:${
        worksheet.lastRow.getCell("air_extension").address
      })`
    }
  })
  worksheet.lastRow.fill = styles.fillBlue

  worksheet.getColumn("d1").fill = styles.fillDark
  worksheet.getColumn("d2").fill = styles.fillDark
  worksheet.getRow(1).fill = styles.fillWhite

  // write to file
  const xls64 = await workbook.xlsx.writeBuffer({ base64: true })
  var data = new Blob([xls64], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  })

  // download
  const url = URL.createObjectURL(data)
  const filename = `${tour.title} — Manifest.xlsx`

  const a = document.createElement("a")
  a.setAttribute("href", url)
  a.setAttribute("download", filename)
  document.body.appendChild(a)
  a.click()

  // cleanup
  setTimeout(function () {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

const styles = {
  alignCenter: {
    horizontal: "center",
    vertical: "bottom",
    wrapText: true
  },
  fillBlue: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFA9BCD8" }
  },
  fillDark: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FF535353" }
  },
  fillGray: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFDEDDDD" }
  },
  fillGreen: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFD1DCB1" }
  },
  fillRed: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFD8AAA8" }
  },
  fillWhite: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFFFFFFF" }
  }
}
