/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import Nav from "../components/Nav"
import { useTheme } from "../theme"

export default function Layout({ children }) {
  const { theme } = useTheme()
  return (
    <>
      <Nav />
      <div
        css={{
          marginLeft: 250,
          padding: "0 2rem",
          minHeight: "100vh",
          maxWidth: 1200,
          minWidth: 768,
          "@media (max-width: 1000px)": {
            marginLeft: 0,
            marginTop: "3rem",
            padding: "0 0.5rem"
          },
          "@media (min-width: 1450px)": {
            borderRight: `1px solid ${theme.borderColor}`
          }
        }}>
        {children}
      </div>
    </>
  )
}
