const { getExpenseData } = require("./getExpenseData")
const { getGroupInvoiceData } = require("./getGroupInvoiceData")

exports.getTourReportData = (
  tour,
  tourCosts,
  extensions,
  extensionsCosts,
  insurance,
  registrants,
  addons,
  payments
) => {
  let mainTourLandOnlyCount = 0 // total number of travelers
  let mainTourLandOnlyFreeCount = 0 // 1 free after 10 paid (counting land only travelers)
  let mainTourLandAndAirFreeCount = 0 // 1 free after 10 paid (counting land & air travelers)
  let mainTourLandAndAirMainTourOnlyCount = 0 // total number of travelers doing main tour group air
  let preTourExtensionLandOnlyCount = 0 // total number of travelers doing pre-tour extension (but not both)
  let preTourExtensionAirCount = 0 // total number of travelers doing group air for pre-tour extension
  let postTourExtensionLandOnlyCount = 0 // total number of travelers doing post-tour extension (but not both)
  let postTourExtensionAirCount = 0 // total number of travelers doing group air for post-tour extension
  let preAndPostTourExtensionLandOnlyCount = 0 // total number of travelers doing both pre- and post-tour extensions
  let preAndPostTourExtensionAirCount = 0 // total number of travelers doing group air for both pre- and post-tour extension
  let mainTourSingleSupplementCount = 0 // total number of travelers rooming alone during main tour
  let preTourExtensionSupplementCount = 0 // total number of travelers rooming alone during pre-tour extension
  let postTourExtensionSupplementCount = 0 // total number of travelers rooming alone during post-tour extension

  let travelInsurancePremiumCost = 0

  let travelerTotalDue = 0
  let travelerTotalPaid = 0
  let travelerTotalRemaining = 0

  registrants.forEach(registrant => {
    if (!registrant.registrant_id) {
      let subs = registrants.filter(x => x.registrant_id == registrant.id)
      let ids = subs.map(x => x.id).concat(registrant.id)
      let invoiceData = getGroupInvoiceData(
        subs.concat(registrant),
        tour,
        insurance,
        extensions,
        addons.filter(x => ids.includes(x.registrant_id)),
        payments.filter(x => ids.includes(x.registrant_id))
      )
      travelerTotalDue += invoiceData.totalDue
      travelerTotalPaid += invoiceData.totalPaid
      travelerTotalRemaining += invoiceData.totalRemaining
    }

    if (registrant.inactive) {
      return
    }

    mainTourLandOnlyCount += 1

    if (registrant.supplement_yes) {
      mainTourSingleSupplementCount += 1
    }

    if (registrant.airtravel_yes) {
      if (!registrant.extension_id && !registrant.extension_price) {
        mainTourLandAndAirMainTourOnlyCount += 1
      }
    }

    if (registrant.extension_id) {
      const extension = extensions.find(x => x.id == registrant.extension_id)
      if (extension) {
        if (extension.timing === "pre") {
          preTourExtensionLandOnlyCount += 1
          if (registrant.airtravel_yes) {
            preTourExtensionAirCount += 1
          }
          if (registrant.supplement_yes) {
            preTourExtensionSupplementCount += 1
          }
        }
        if (extension.timing === "post") {
          postTourExtensionLandOnlyCount += 1
          if (registrant.airtravel_yes) {
            postTourExtensionAirCount += 1
          }
          if (registrant.supplement_yes) {
            postTourExtensionSupplementCount += 1
          }
        }
        if (extension.timing === "both") {
          preAndPostTourExtensionLandOnlyCount += 1
          if (registrant.airtravel_yes) {
            preAndPostTourExtensionAirCount += 1
          }
          if (registrant.supplement_yes) {
            preTourExtensionSupplementCount += 1
            postTourExtensionSupplementCount += 1
          }
        }
      }
    }

    if (registrant.insurance_yes) {
      let expenseData = getExpenseData(
        registrant,
        addons,
        tour,
        extensions,
        tourCosts,
        extensionsCosts,
        insurance
      )
      travelInsurancePremiumCost += expenseData.insuranceCost
    }
  })

  mainTourLandAndAirFreeCount = Math.floor(
    mainTourLandAndAirMainTourOnlyCount / 11
  )
  mainTourLandOnlyFreeCount =
    Math.floor(mainTourLandOnlyCount / 11) - mainTourLandAndAirFreeCount

  let earlyArrivalExtraRoomNightsCount = 0
  let lateDepartureExtraRoomNightsCount = 0
  let earlyArrivalExtraRoomNightsCost = 0
  let lateDepartureExtraRoomNightsCost = 0
  addons.map(addon => {
    if (addon.category === "lodging" && addon.timing && addon.nights > 0) {
      if (addon.timing === "early") {
        earlyArrivalExtraRoomNightsCount += addon.nights
        earlyArrivalExtraRoomNightsCost += addon.cost || 0
      }
      if (addon.timing === "late") {
        earlyArrivalExtraRoomNightsCount += addon.nights
        lateDepartureExtraRoomNightsCost += addon.cost || 0
      }
    }
  })

  let preTourExtension = extensions.find(x => x.timing === "pre")
  let postTourExtension = extensions.find(x => x.timing === "post")
  let preAndPostTourExtension = extensions.find(x => x.timing === "both")
  let preTourExtensionCosts = {}
  let postTourExtensionCosts = {}
  let preAndPostTourExtensionCosts = {}
  if (preTourExtension) {
    preTourExtensionCosts = extensionsCosts.find(
      x => x.id === preTourExtension.id
    )
  }
  if (postTourExtension) {
    postTourExtensionCosts = extensionsCosts.find(
      x => x.id === postTourExtension.id
    )
  }
  if (preAndPostTourExtension) {
    preAndPostTourExtensionCosts = extensionsCosts.find(
      x => x.id === preAndPostTourExtension.id
    )
  }

  let mainTourLandCost = (tourCosts.land || 0) * mainTourLandOnlyCount

  let preTourExtensionLandOnlyCost =
    (preTourExtensionCosts.land || 0) * preTourExtensionLandOnlyCount

  let postTourExtensionLandOnlyCost =
    (postTourExtensionCosts.land || 0) * postTourExtensionLandOnlyCount

  let preAndPostTourExtensionLandOnlyCost =
    (preAndPostTourExtensionCosts.land || 0) *
    preAndPostTourExtensionLandOnlyCount

  let mainTourGroupAirCost =
    (tourCosts.group_air || 0) * mainTourLandAndAirMainTourOnlyCount

  let preTourExtensionGroupAirCost =
    (preTourExtensionCosts.group_air || 0) * preTourExtensionAirCount

  let postTourExtensionGroupAirCost =
    (postTourExtensionCosts.group_air || 0) * postTourExtensionAirCount

  let preAndPostTourExtensionGroupAirCost =
    (preAndPostTourExtensionCosts.group_air || 0) *
    preAndPostTourExtensionAirCount

  let mainTourInCountryAirCost =
    (tourCosts.local_air || 0) * mainTourLandOnlyCount

  let preTourExtensionInCountryAirCost =
    (preTourExtensionCosts.local_air || 0) * preTourExtensionLandOnlyCount

  let postTourExtensionInCountryAirCost =
    (postTourExtensionCosts.local_air || 0) * postTourExtensionLandOnlyCount

  let preAndPostTourExtensionInCountryAirCost =
    (preAndPostTourExtensionCosts.local_air || 0) *
    preAndPostTourExtensionLandOnlyCount

  let mainTourGratuityCost = (tourCosts.gratuity || 0) * mainTourLandOnlyCount

  let extensionGratuityCost =
    (preTourExtensionCosts.gratuity || 0) * preTourExtensionLandOnlyCount +
    (postTourExtensionCosts.gratuity || 0) * postTourExtensionLandOnlyCount +
    (preAndPostTourExtensionCosts.gratuity || 0) *
      preAndPostTourExtensionLandOnlyCount

  let travelInsuranceLessCommCost = travelInsurancePremiumCost * 0.26 * -1

  // Single Supplements Costs

  let mainTourSingleSupplementCost =
    (tourCosts.supplement || 0) * mainTourSingleSupplementCount

  let preExtensionSingleSupplementCost =
    (preTourExtensionCosts.supplement || 0) * preTourExtensionSupplementCount

  let postExtensionSingleSupplementCost =
    (postTourExtensionCosts.supplement || 0) * postTourExtensionSupplementCount

  // let preAndPostExtensionSingleSupplementCost =
  //   (preAndPostTourExtensionCosts.supplement || 0) *
  //   preAndPostTourExtensionLandOnlyCount

  let headsetsCost = (tourCosts.headset || 0) * mainTourLandOnlyCount

  let oneTwentyBenefit =
    Math.floor(mainTourLandOnlyCount / 21) * tourCosts.land * -1

  let commissionCost = (tourCosts.commission || 0) * mainTourLandOnlyCount

  let bottledWaterCost = (tourCosts.bottled_water || 0) * mainTourLandOnlyCount

  // TODO: allow users to set these values
  let tourLeaderBusinessClassUpgradeCost = 0
  let groundTransferCost = 0

  let totalCost =
    mainTourLandCost +
    mainTourGroupAirCost +
    mainTourInCountryAirCost +
    preTourExtensionLandOnlyCost +
    postTourExtensionLandOnlyCost +
    preTourExtensionGroupAirCost +
    preTourExtensionInCountryAirCost +
    postTourExtensionGroupAirCost +
    postTourExtensionInCountryAirCost +
    preAndPostTourExtensionGroupAirCost +
    preAndPostTourExtensionInCountryAirCost +
    earlyArrivalExtraRoomNightsCost +
    lateDepartureExtraRoomNightsCost +
    mainTourGratuityCost +
    extensionGratuityCost +
    travelInsurancePremiumCost +
    travelInsuranceLessCommCost +
    mainTourSingleSupplementCost +
    preExtensionSingleSupplementCost +
    postExtensionSingleSupplementCost +
    headsetsCost +
    oneTwentyBenefit +
    commissionCost +
    bottledWaterCost +
    tourLeaderBusinessClassUpgradeCost +
    groundTransferCost

  return {
    mainTourLandOnlyCount, // H
    mainTourLandOnlyFreeCount, // I
    mainTourLandAndAirFreeCount, // J
    mainTourLandAndAirMainTourOnlyCount, // K
    preTourExtensionLandOnlyCount, // L
    preTourExtensionAirCount, // M
    postTourExtensionLandOnlyCount, // N
    postTourExtensionAirCount, // O
    preAndPostTourExtensionLandOnlyCount, // P
    preAndPostTourExtensionAirCount, // Q
    mainTourSingleSupplementCount, // R
    preTourExtensionSupplementCount, // S
    postTourExtensionSupplementCount, // T
    earlyArrivalExtraRoomNightsCount,
    lateDepartureExtraRoomNightsCount,

    mainTourLandCost,
    mainTourGroupAirCost,
    mainTourInCountryAirCost,
    preTourExtensionLandOnlyCost,
    postTourExtensionLandOnlyCost,
    preTourExtensionGroupAirCost,
    preTourExtensionInCountryAirCost,
    postTourExtensionGroupAirCost,
    postTourExtensionInCountryAirCost,
    preAndPostTourExtensionGroupAirCost,
    preAndPostTourExtensionInCountryAirCost,
    earlyArrivalExtraRoomNightsCost,
    lateDepartureExtraRoomNightsCost,
    mainTourGratuityCost,
    extensionGratuityCost,
    travelInsurancePremiumCost,
    travelInsuranceLessCommCost,
    mainTourSingleSupplementCost,
    preExtensionSingleSupplementCost,
    postExtensionSingleSupplementCost,
    headsetsCost,
    oneTwentyBenefit,
    commissionCost,
    bottledWaterCost,
    tourLeaderBusinessClassUpgradeCost,
    groundTransferCost,
    totalCost,

    travelerTotalDue,
    travelerTotalPaid,
    travelerTotalRemaining
  }
}
