/** @jsx jsx */
import { jsx } from "@emotion/core"
import md5 from "md5"
import React from "react"
import { displayInitials } from "../utils/format"
import { useTheme } from "../theme"

export default function Avatar({ user, size = 36 }) {
  const { theme } = useTheme()

  const initials = displayInitials(user)
  const gravatar = md5(String(user.email).trim().toLowerCase()) // prettier-ignore

  return (
    <div
      css={{
        position: "relative",
        width: size,
        height: size,
        fontSize: size / 2.5,
        flexGrow: 0,
        flexShrink: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        backgroundColor: theme.accentText,
        color: theme.backgroundColor
      }}>
      {initials}
      {/* <img
        src={`https://www.gravatar.com/avatar/${gravatar}?d=blank`}
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: size,
          height: size,
          borderRadius: "100%"
        }}
      /> */}
    </div>
  )
}
