import React from "react"
import CreatableSelect from "react-select/creatable"
import { db } from "../firebase"
import { useTheme } from "../theme"
import { getReactSelectStyles } from "./Styles"

export default function DepartureSelect({ name, required, ...props }) {
  const { theme } = useTheme()

  const [loading, setLoading] = React.useState(true)
  const [options, setOptions] = React.useState([])
  const [value, setValue] = React.useState(null)

  React.useEffect(() => {
    loadOptions()
  }, [])

  async function loadOptions() {
    try {
      setLoading(true)
      const departures = []
      const departuresRef = db.collection("tour_departures")
      const departureDocs = await departuresRef.get()
      departureDocs.forEach(departureDoc => {
        const departureData = departureDoc.data()
        const departureValue = {
          value: departureData.name,
          label: departureData.name
        }
        departures.push(departureValue)
      })
      setOptions(departures)
      if (props.defaultValue) {
        const departureValue = departures.find(
          x => x.value == props.defaultValue
        )
        setValue(departureValue)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function createOption(inputValue) {
    // prettier-ignore
    if (!window.confirm(`Are you sure you want to create a new tour departure “${inputValue}”?`)) { 
      return
    }

    try {
      setLoading(true)
      const departureRef = db.collection("tour_departures").doc()
      const departureData = {
        id: departureRef.id,
        name: inputValue,
        created: Date.now(),
        modified: null
      }
      await departureRef.set(departureData)
      const departureValue = {
        value: departureData.name,
        label: departureData.name
      }
      setOptions(options => options.concat(departureValue))
      setValue(departureValue)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <CreatableSelect
        {...props}
        isClearable
        isDisabled={loading}
        isLoading={loading}
        options={options}
        value={value}
        onChange={setValue}
        onCreateOption={createOption}
        {...getReactSelectStyles(theme)}
      />
      <input
        name={name}
        required={required}
        tabIndex={-1}
        autoComplete="off"
        style={{ position: "absolute", opacity: 0, height: 0 }}
        value={value && value.value}
      />
    </>
  )
}
