/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { Link } from "react-router-dom"
import { BLACK_5 } from "../colors"
import { useTheme } from "../theme"
import { fonts } from "./Styles"

// kind: outline, fill
// size: large, small

export default React.forwardRef(({ kind, size, ...props }, ref) => {
  const { theme } = useTheme()

  const Component = props.for
    ? "label"
    : props.as
    ? props.as
    : props.to
    ? Link
    : props.href
    ? "a"
    : "button"

  const styles = {
    all: {
      display: "inline-block",
      cursor: props.disabled ? "auto" : "pointer",
      borderRadius: 3,
      textAlign: "center",
      textDecoration: "none",
      opacity: props.disabled ? 0.8 : 1,
      whiteSpace: "nowrap"
    },
    small: {
      padding: "0.2rem 0.5rem 0",
      fontSize: "0.875rem",
      fontWeight: 600,
      fontFamily: fonts.ideal
    },
    large: {
      margin: "1rem 0",
      padding: "0.5rem 1rem",
      display: "block",
      width: "100%",
      fontSize: "1.5rem",
      fontWeight: 400,
      fontFamily: fonts.knockout,
      letterSpacing: "0.08rem",
      textTransform: "uppercase"
    },
    outline: {
      border: "1px solid " + theme.accentText,
      backgroundColor: theme.foregroundColor,
      color: theme.primaryText,
      ":active": {
        backgroundColor: BLACK_5
      }
    },
    fill: {
      border: "1px solid " + theme.accentText,
      backgroundColor: theme.accentText,
      color: theme.backgroundColor,
      ":active": {
        opacity: 0.9
      }
    },
    link: {
      color: props.disabled ? theme.tertiaryText : theme.accentText,
      fontFamily: fonts.ideal,
      textDecoration: props.disabled ? "none" : "underline"
    }
  }

  return (
    <Component
      ref={ref}
      css={[styles.all, styles[size], styles[kind]]}
      {...props}
    />
  )
})
