/** @jsx jsx */
import { jsx } from "@emotion/core"
import { useTheme } from "../theme"

export default function Tag({ color, disabled, children }) {
  const { theme } = useTheme()

  return (
    <span
      css={{
        marginRight: "0.3rem",
        padding: "0.1rem 0.2rem",
        borderRadius: 3,
        fontWeight: "bold",
        fontSize: "0.675rem",
        color: disabled ? theme.tertiaryText : theme.foregroundColor,
        backgroundColor: disabled
          ? theme.borderColor
          : color || theme.accentText
      }}>
      {children}
    </span>
  )
}
