import React from "react"
import * as COLORS from "./colors"
import useLocalStorage from "./hooks/useLocalStorage"

export const lightTheme = {
  primaryText: COLORS.BLACK,
  secondaryText: COLORS.BLACK_80,
  tertiaryText: COLORS.BLACK_50,
  accentText: COLORS.OLIVE,
  hintText: COLORS.BLACK_30,
  borderColor: COLORS.BLACK_20,
  underlayColor: COLORS.BLACK_5,
  highlightColor: COLORS.GOLD,
  foregroundColor: COLORS.WHITE,
  backgroundColor: COLORS.CREAM,
  textureImage: require("./assets/fbe-engraving-pattern-black10.svg"),
  patternImage: require("./assets/fbe-topographic-pattern-black12.svg"),
  iconImage: require("./assets/fbe-icon-green.svg")
}

export const darkTheme = {
  primaryText: COLORS.WHITE,
  secondaryText: COLORS.WHITE_80,
  tertiaryText: COLORS.WHITE_50,
  accentText: COLORS.GOLD,
  hintText: COLORS.WHITE_50,
  borderColor: COLORS.WHITE_20,
  underlayColor: COLORS.BLACK_10,
  highlightColor: COLORS.OLIVE,
  foregroundColor: COLORS.GREEN,
  backgroundColor: COLORS.GREEN,
  textureImage: require("./assets/fbe-engraving-pattern-gold10.svg"),
  patternImage: require("./assets/fbe-topographic-pattern-gold12.svg"),
  iconImage: require("./assets/fbe-icon-gold.svg")
}

export const defaultTheme = darkTheme

export const ThemeContext = React.createContext({
  theme: defaultTheme,
  setTheme: () => {}
})

export function useTheme() {
  return React.useContext(ThemeContext)
}

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useLocalStorage("theme", defaultTheme)

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
