import React from "react"
import storage from "store"

export default function useLocalStorage(key, initialValue) {
  const [state, setState] = React.useState(() => {
    try {
      return JSON.parse(storage.get(key))
    } catch {
      return initialValue
    }
  })

  React.useEffect(() => {
    try {
      storage.set(key, JSON.stringify(state))
    } catch (error) {
      console.error(error)
    }
  }, [state])

  function clearStorage() {
    storage.remove(key)
  }

  return [state, setState, clearStorage]
}
