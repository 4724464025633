/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { Link } from "react-router-dom"

export const TableContext = React.createContext({ to: null })

export function Row({ to, ...props }) {
  if (to) {
    return (
      <TableContext.Provider value={{ to }}>
        <tr {...props} />
      </TableContext.Provider>
    )
  }

  return <tr {...props} />
}

export function Cell(props) {
  const { to } = React.useContext(TableContext)

  if (to) {
    return (
      <td css={{ padding: 0 }} {...props}>
        <Link
          to={to}
          css={{
            padding: "0.5rem",
            display: "block",
            color: "inherit",
            textDecoration: "none"
          }}>
          {props.children}
        </Link>
      </td>
    )
  }

  return <td {...props} />
}
