/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { Helmet } from "react-helmet"
import * as COLORS from "../colors"
import Field from "../components/Field"
import Input from "../components/Input"
import Button from "../components/Button"
import Select from "../components/Select"
import Radio from "../components/Radio"
import Checkbox from "../components/Checkbox"
import Textarea from "../components/Textarea"
import Toggle from "../components/Toggle"

export default function StyleGuide() {
  return (
    <div
      css={{
        section: {
          margin: "4rem auto",
          maxWidth: "calc(7 * 7rem)"
        }
      }}>
      <Helmet>
        <title>Styleguide | Faith Based Expeditions</title>
      </Helmet>
      <section>
        <h3>Colors</h3>
        <hr />
        <ul
          css={{
            margin: "-1rem",
            padding: 0,
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none"
          }}>
          {Object.keys(COLORS).map(x => (
            <li key={x} css={{ padding: "1rem" }}>
              <div
                css={{
                  width: "5rem",
                  height: "5rem",
                  border: "1px solid " + COLORS.BLACK_10,
                  background: COLORS[x]
                }}
              />
              <h6>{x}</h6>
            </li>
          ))}
        </ul>
      </section>
      <section>
        <h3>Typography</h3>
        <hr />
        <div
          css={{
            width: "22rem",
            marginRight: "4rem",
            display: "inline-block"
          }}>
          <h1>Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>
          <h6>Heading 6</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dapibus
            posuere leo eget pretium. Morbi ornare accumsan erat, quis fermentum
            sem vulputate quis. Proin eleifend elementum mi quis consectetur.
          </p>
          <ul>
            <li>Unordered</li>
            <li>List</li>
            <li>Items</li>
          </ul>
          <ol>
            <li>Ordered</li>
            <li>List</li>
            <li>Items</li>
          </ol>
        </div>
        <div
          css={{
            width: "22rem",
            display: "inline-block",
            fontStyle: "italic"
          }}>
          <h1>Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>
          <h6>Heading 6</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dapibus
            posuere leo eget pretium. Morbi ornare accumsan erat, quis fermentum
            sem vulputate quis. Proin eleifend elementum mi quis consectetur.
          </p>
          <ul>
            <li>Unordered</li>
            <li>List</li>
            <li>Items</li>
          </ul>
          <ol>
            <li>Ordered</li>
            <li>List</li>
            <li>Items</li>
          </ol>
        </div>
      </section>
      <section>
        <h3>Forms</h3>
        <hr />
        <div css={{ maxWidth: "22rem" }}>
          <Field label="Input">
            <Input type="text" placeholder="Enter some text..." />
          </Field>
          <Field label="Textarea">
            <Textarea placeholder="Enter some text..." minRows={3} />
          </Field>
          <Field label="Select">
            <Select>
              <option value={1}>One</option>
              <option value={2}>Two</option>
            </Select>
          </Field>
          <Field label="Radio">
            <Radio name="radio" value={1} defaultChecked>
              One
            </Radio>
            <Radio name="radio" value={2}>
              Two
            </Radio>
          </Field>
          <Field label="Checkbox">
            <Checkbox name="check" value={1} defaultChecked>
              One
            </Checkbox>
            <Checkbox name="check" value={2}>
              Two
            </Checkbox>
            <Checkbox name="check" value={3}>
              Three
            </Checkbox>
          </Field>
          <Field label="Toggle">
            <Toggle name="toggle" defaultChecked />
          </Field>
        </div>
      </section>
      <section>
        <h3>Buttons</h3>
        <hr />
        <a href="#">An external link.</a>
        <Button size="large" kind="fill">
          Large Fill Button
        </Button>
        <Button size="large" kind="outline">
          Large Outline Button
        </Button>
        <Button size="small" kind="fill">
          Fill Button
        </Button>
        &nbsp;
        <Button size="small" kind="outline">
          Outline Button
        </Button>
      </section>
    </div>
  )
}
