import moment from "moment"
import { displayAge, displayPhone } from "../utils/format"

function displayDate(d) {
  // hack to fix Excel date conversion
  return new Date(moment(d).add(12, "hours"))
}

export default async function downloadReport(tour, registrantsReportData) {
  const XLSX = await import("xlsx")

  const headings = [
    // Identity
    "Active",
    "Title",
    "First Name",
    "Middle Name",
    "Last Name",
    "Name Tag",
    "Birth Date",
    "Age",
    "Passport Number",
    "Street",
    "City",
    "State",
    "Zip",
    // Contact
    "Email",
    "Phone",
    // Trip
    "Primary",
    "Registered",
    "Tour Start Date",
    "Tour End Date",
    "Tour Departure",
    "Roommate Preference",
    "Comments",
    // Billing
    "Land & Air Price",
    "Land Only Price",
    "Early Registration Discount",
    "Extension Price",
    "Single Supplement Price",
    "Addons Price",
    "Tour Subtotal",
    "Insurance Premium",
    "Processing Fees",
    "Late Fees",
    "Total Tour Revenue",
    "Paid",
    "Balance",
    // Expenses
    "Air Cost",
    "Land Cost",
    "Extension Cost",
    "Insurance Cost",
    "Gratuity",
    "Single Supplement Cost",
    "Addons Cost",
    "Headset",
    "Commission",
    "Total Expense",
    "Profit"
  ]

  let ws = {}
  let rIndex = 0
  let cellRef = "A1"
  headings.forEach((heading, cIndex) => {
    cellRef = XLSX.utils.encode_cell({ r: rIndex, c: cIndex })
    ws[cellRef] = { v: heading }
  })
  registrantsReportData
    .filter(x => !x.registrant.inactive)
    .forEach((reportData, i) => {
      rIndex = i + 1
      headings.forEach((heading, cIndex) => {
        cellRef = XLSX.utils.encode_cell({ r: rIndex, c: cIndex })
        if (heading === "Active") {
          ws[cellRef] = { v: !reportData.registrant.inactive, t: "b" }
        }
        if (heading === "Title") {
          ws[cellRef] = { v: reportData.registrant.title, t: "s" }
        }
        if (heading === "First Name") {
          ws[cellRef] = { v: reportData.registrant.name_first, t: "s" }
        }
        if (heading === "Middle Name") {
          ws[cellRef] = { v: reportData.registrant.name_middle, t: "s" }
        }
        if (heading === "Last Name") {
          ws[cellRef] = { v: reportData.registrant.name_last, t: "s" }
        }
        if (heading === "Name Tag") {
          ws[cellRef] = { v: reportData.registrant.name_tag, t: "s" }
        }
        if (heading === "Birth Date") {
          ws[cellRef] = {
            v: displayDate(reportData.registrant.birthdate),
            t: "d"
          }
        }
        if (heading === "Age") {
          ws[cellRef] = { v: displayAge(reportData.registrant), t: "n" }
        }
        if (heading === "Passport Number") {
          ws[cellRef] = {
            v: reportData.registrant.passport_number || "",
            t: "s"
          }
        }
        if (heading === "Street") {
          ws[cellRef] = {
            v: reportData.registrant.mailing_address_street,
            t: "s"
          }
        }
        if (heading === "City") {
          ws[cellRef] = {
            v: reportData.registrant.mailing_address_city,
            t: "s"
          }
        }
        if (heading === "State") {
          ws[cellRef] = {
            v: reportData.registrant.mailing_address_state,
            t: "s"
          }
        }
        if (heading === "Zip") {
          ws[cellRef] = { v: reportData.registrant.mailing_address_zip, t: "s" }
        }
        if (heading === "Email") {
          ws[cellRef] = { v: reportData.registrant.email, t: "s" }
        }
        if (heading === "Phone") {
          ws[cellRef] = { v: displayPhone(reportData.registrant.phone), t: "s" }
        }
        if (heading === "Primary") {
          ws[cellRef] = { v: !reportData.registrant.registrant_id, t: "b" }
        }
        if (heading === "Registered") {
          ws[cellRef] = {
            v: displayDate(reportData.registrant.created),
            t: "d"
          }
        }
        if (heading === "Tour Start Date") {
          ws[cellRef] = {
            v: displayDate(reportData.registrant.tour_start_date),
            t: "d"
          }
        }
        if (heading === "Tour End Date") {
          ws[cellRef] = {
            v: displayDate(reportData.registrant.tour_end_date),
            t: "d"
          }
        }
        if (heading === "Tour Departure") {
          ws[cellRef] = {
            v:
              (reportData.registrant.airtravel_yes &&
                reportData.registrant.tour_departure) ||
              "",
            t: "s"
          }
        }
        if (heading === "Roommate Preference") {
          ws[cellRef] = {
            v: reportData.registrant.roommate_preference || "",
            t: "s"
          }
        }
        if (heading === "Comments") {
          ws[cellRef] = { v: reportData.registrant.comments || "", t: "s" }
        }
        if (heading === "Land & Air Price") {
          const airTotal = reportData.registrant.airtravel_yes
            ? reportData.invoiceData.tourTotal
            : 0
          ws[cellRef] = {
            v: airTotal / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Land Only Price") {
          const landTotal = reportData.registrant.airtravel_yes
            ? 0
            : reportData.invoiceData.tourTotal
          ws[cellRef] = {
            v: landTotal / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Early Registration Discount") {
          ws[cellRef] = {
            v: -reportData.invoiceData.earlyDiscount / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Extension Price") {
          ws[cellRef] = {
            v: reportData.invoiceData.extensionTotal / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Single Supplement Price") {
          ws[cellRef] = {
            v: reportData.invoiceData.supplementTotal / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Addons Price") {
          ws[cellRef] = {
            v: reportData.invoiceData.addonsTotal / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Tour Subtotal") {
          ws[cellRef] = {
            v: reportData.invoiceData.subtotalDue / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Insurance Premium") {
          ws[cellRef] = {
            v: reportData.invoiceData.insuranceTotal / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Processing Fees") {
          ws[cellRef] = {
            v: reportData.invoiceData.processingFee / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Late Fees") {
          ws[cellRef] = {
            v: reportData.invoiceData.lateFee / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Total Tour Revenue") {
          ws[cellRef] = {
            v: reportData.invoiceData.totalDue / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Paid") {
          ws[cellRef] = {
            v: reportData.invoiceData.totalPaid / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Balance") {
          ws[cellRef] = {
            v: reportData.invoiceData.totalRemaining / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Air Cost") {
          ws[cellRef] = {
            v: reportData.expenseData.airCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Land Cost") {
          ws[cellRef] = {
            v: reportData.expenseData.landCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Extension Cost") {
          ws[cellRef] = {
            v: reportData.expenseData.extensionCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Insurance Cost") {
          ws[cellRef] = {
            v: reportData.expenseData.insuranceCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Gratuity") {
          ws[cellRef] = {
            v: reportData.expenseData.gratuityCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Single Supplement Cost") {
          ws[cellRef] = {
            v: reportData.expenseData.supplementCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Addons Cost") {
          ws[cellRef] = {
            v: reportData.expenseData.addonsCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Headset") {
          ws[cellRef] = {
            v: reportData.expenseData.headsetCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Commission") {
          ws[cellRef] = {
            v: reportData.expenseData.commissionCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Total Expense") {
          ws[cellRef] = {
            v: reportData.expenseData.totalCost / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
        if (heading === "Profit") {
          const totalProfit =
            reportData.invoiceData.totalDue - reportData.expenseData.totalCost
          ws[cellRef] = {
            v: totalProfit / 100,
            z: "$#,##0.##",
            t: "n"
          }
        }
      })
    })
  headings.forEach((heading, cIndex) => {
    // don't need to calculate anything for first 20 cols
    if (cIndex >= 22) {
      cellRef = XLSX.utils.encode_cell({ r: rIndex + 1, c: cIndex })
      let colRef = XLSX.utils.encode_col(cIndex)
      let rowRef = XLSX.utils.encode_row(rIndex)
      // sum all billing/expense columns
      ws[cellRef] = {
        f: `SUM(${colRef}2:${colRef}${rowRef})`,
        z: "$#,##0.##",
        t: "n"
      }
    }
  })
  ws["!ref"] = `A1:${cellRef}`

  const wb = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(wb, ws, "Manifest")

  const filename = `${tour.title} — Report.xlsx`

  XLSX.writeFile(wb, filename)
}
