/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { db } from "../firebase"
import { useTheme } from "../theme"
import { displayMoney } from "../utils/format"
import Loading from "../components/Loading"
import numeral from "numeral"

export default function Insurances() {
  const { theme } = useTheme()

  const [loading, setLoading] = React.useState(false)
  const [insurances, setInsurances] = React.useState([])

  React.useEffect(() => {
    loadOptions()
  }, [])

  async function loadOptions() {
    try {
      setLoading(true)
      const insurances = []
      const insurancesRef = db.collection("insurances")
      const insuranceDocs = await insurancesRef.get()
      insuranceDocs.forEach(insuranceDoc => {
        insurances.push(insuranceDoc.data())
      })
      setInsurances(insurances)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  function prettifyMoney(x) {
    return displayMoney(Math.floor(x / 100) * 100)
  }

  return (
    <>
      <small
        css={{ display: "block", maxWidth: "30rem", marginBottom: "1rem" }}>
        Listed below are the insurance pricing options FBE offers for purchase
        during registration. Tours can be configured to instead provide an
        insurance link for purchase separate from FBE registration.
      </small>
      {insurances.map(insurance => {
        let showAges = false
        if (insurance.levels) {
          showAges = !!insurance.levels.find(
            x => x.age_min > 0 || x.age_max < 120
          )
        }
        return (
          <details
            key={insurance.id}
            css={{
              maxWidth: "30rem",
              padding: "0.5rem",
              borderRadius: 3,
              border: `1px solid transparent`,
              "@media (hover: hover)": {
                ":hover": {
                  borderColor: theme.borderColor
                }
              }
            }}>
            <summary css={{ cursor: "pointer" }}>
              <h5 css={{ margin: 0, display: "inline-block" }}>
                {insurance.name}
              </h5>
            </summary>
            <table css={{ width: "100%", padding: "1rem" }}>
              <thead>
                <tr>
                  {insurance.levels ? <th>Trip Cost</th> : null}
                  {showAges ? <th>Age</th> : null}
                  <th>Plan Cost</th>
                </tr>
              </thead>
              <tbody>
                {insurance.levels ? (
                  insurance.levels.map((level, index) => (
                    <tr key={index}>
                      <td>
                        {prettifyMoney(level.min)}–{prettifyMoney(level.max)}
                      </td>
                      {showAges && (
                        <td>
                          {level.age_min}–{level.age_max}
                        </td>
                      )}
                      <td>{displayMoney(level.price)}</td>
                    </tr>
                  ))
                ) : insurance.percentage ? (
                  <tr>
                    <td>
                      {numeral(insurance.percentage).format("0.0[0]%")} of tour
                      and insured addons subtotal.
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </details>
        )
      })}
    </>
  )
}
