import React from "react"
import Field from "../components/Field"
import Input from "../components/Input"
import Alert from "../components/Alert"
import Button from "../components/Button"

export default function AccessCodeForm({ onSubmit }) {
  const [accessCode, setAccessCode] = React.useState("")
  const [submitting, setSubmitting] = React.useState(false)
  const [error, setError] = React.useState(null)

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      setSubmitting(true)
      await onSubmit(accessCode)
    } catch (error) {
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <p>
        If you have a tour leader access code you can register for free. Enter
        and submit it below to apply it to your trip!
      </p>
      <Field label="Access Code">
        <Input
          name="tour_leader_access_code"
          type="text"
          placeholder="Enter your code..."
          onChange={e => setAccessCode(e.target.value)}
          value={accessCode}
          required
        />
      </Field>
      <Button type="submit" size="large" kind="fill" disabled={submitting}>
        {submitting ? "Applying..." : "Apply Code"}
      </Button>
      {error && <Alert error={error} />}
    </form>
  )
}
