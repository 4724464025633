/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { db } from "../firebase"
import { useFormik } from "formik"
import { useStore } from "../store"
import Field from "../components/Field"
import Toggle from "../components/Toggle"

export default function AdminEmailsForm() {
  const { user, adminConfig, setAdminConfig } = useStore()

  const formik = useFormik({
    initialValues: {
      send_daily_digest_email: !!adminConfig.send_daily_digest_email,
      send_weekly_digest_email: !!adminConfig.send_weekly_digest_email,
      send_deposit_receipt_email: !!adminConfig.send_deposit_receipt_email,
      bcc_registration_emails: !!adminConfig.bcc_registration_emails,
      bcc_invoice_emails: !!adminConfig.bcc_invoice_emails
    },
    onSubmit: async values => {
      await db
        .collection("admins")
        .doc(user.email)
        .update(values)
      setAdminConfig(adminConfig => ({ ...adminConfig, ...values }))
    }
  })

  React.useEffect(() => {
    // prettier-ignore
    if (
      formik.values.send_daily_digest_email !== adminConfig.send_daily_digest_email || 
      formik.values.send_weekly_digest_email !== adminConfig.send_weekly_digest_email ||
      formik.values.send_deposit_receipt_email !== adminConfig.send_deposit_receipt_email ||
      formik.values.bcc_registration_emails !== adminConfig.bcc_registration_emails ||
      formik.values.bcc_invoice_emails !== adminConfig.bcc_invoice_emails
    ) {
      formik.submitForm()
    }
  }, [formik.values, formik.submitForm])

  return (
    <form onSubmit={formik.handleSubmit} css={{ maxWidth: "30rem" }}>
      <Field label="Daily Digest Emails">
        <Toggle
          value={true}
          name="send_daily_digest_email"
          checked={formik.values.send_daily_digest_email}
          onChange={formik.handleChange}>
          Receive an email update on all activity in the past day. Sent every
          morning at 7am EST.
        </Toggle>
      </Field>
      <Field label="Weekly Digest Emails">
        <Toggle
          value={true}
          name="send_weekly_digest_email"
          checked={formik.values.send_weekly_digest_email}
          onChange={formik.handleChange}>
          Receive an email update on all activity in the past week. Sent every
          Monday morning at 7am EST.
        </Toggle>
      </Field>
      <Field label="Deposit Receipt Emails">
        <Toggle
          value={true}
          name="send_deposit_receipt_email"
          checked={formik.values.send_deposit_receipt_email}
          onChange={formik.handleChange}>
          Receive a deposit receipt email whenever a new registration is made.
        </Toggle>
      </Field>
      <Field label="Registration Emails">
        <Toggle
          value={true}
          name="bcc_registration_emails"
          checked={formik.values.bcc_registration_emails}
          onChange={formik.handleChange}>
          Receive all confirmation emails sent to registrants by{" "}
          <code>BCC</code>.
        </Toggle>
      </Field>
      <Field label="Invoice Emails">
        <Toggle
          value={true}
          name="bcc_invoice_emails"
          checked={formik.values.bcc_invoice_emails}
          onChange={formik.handleChange}>
          Receive all invoice emails sent to registrants by <code>BCC</code>.
        </Toggle>
      </Field>
    </form>
  )
}
