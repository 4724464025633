import React from "react"
import Select from "react-select"
import { db } from "../firebase"
import { useTheme } from "../theme"
import { getReactSelectStyles } from "./Styles"

export default function DepartureSelect({ name, required, ...props }) {
  const { theme } = useTheme()

  const [loading, setLoading] = React.useState(true)
  const [options, setOptions] = React.useState([])
  const [value, setValue] = React.useState(null)

  React.useEffect(() => {
    loadOptions()
  }, [])

  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(value ? value.name : null)
    }
  }, [value])

  async function loadOptions() {
    try {
      setLoading(true)
      const insurances = []
      const insurancesRef = db.collection("insurances")
      const insuranceDocs = await insurancesRef.get()
      insuranceDocs.forEach(insuranceDoc => {
        const { id, name } = insuranceDoc.data()
        const insuranceValue = { value: id, label: name }
        insurances.push(insuranceValue)
      })
      setOptions(insurances)
      const value =
        insurances.find(x => x.value == props.defaultValue) ||
        insurances[insurances.length - 1]
      setValue(value)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Select
        {...props}
        isDisabled={loading}
        isLoading={loading}
        options={options}
        value={value}
        onChange={setValue}
        {...getReactSelectStyles(theme)}
      />
      <input
        name={name}
        required={required}
        tabIndex={-1}
        autoComplete="off"
        style={{ position: "absolute", opacity: 0, height: 0 }}
        value={value && value.value}
      />
    </>
  )
}
