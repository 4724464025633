/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import pluralize from "pluralize"
import { Link } from "react-router-dom"
import Tag from "../components/Tag"
import Alert from "../components/Alert"
import Button from "../components/Button"
import Field from "../components/Field"
import { displayFullName } from "../utils/format"
import RegistrantSelect from "../components/RegistrantSelect"
import { useTheme } from "../theme"
import { db } from "../firebase"

export default function MoveRegistrantForm({ tour, registrant, onSubmit }) {
  const { theme } = useTheme()

  const [selectedId, setSelectedId] = React.useState(null)
  const [selectedRegistrant, setSelectedRegistrant] = React.useState(null)
  const [selectedPrimary, setSelectedPrimary] = React.useState(null)
  const [selectedSubregistrants, setSelectedSubregistrants] = React.useState([])

  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)

  const alreadyMerged =
    selectedRegistrant?.registrant_id == registrant.id ||
    selectedRegistrant?.id == registrant.id

  React.useEffect(() => {
    loadSelectedSubregistrants(selectedId)
  }, [selectedId])

  async function loadSelectedSubregistrants(selectedId) {
    try {
      setError(null)
      setLoading(true)
      setSelectedPrimary(null)
      setSelectedRegistrant(null)
      setSelectedSubregistrants([])
      let selectedPrimary = null
      let selectedRegistrant = null
      let selectedSubregistrants = []
      if (selectedId) {
        const registrantRef = db.collection("registrants").doc(selectedId)
        const registrantDoc = await registrantRef.get()
        if (registrantDoc.exists) {
          selectedRegistrant = registrantDoc.data()
        }
        if (selectedRegistrant.registrant_id) {
          // load selected registrant’s parent primary registration
          const primaryRef = db
            .collection("registrants")
            .doc(selectedRegistrant.registrant_id)
          const primaryDoc = await primaryRef.get()
          if (primaryDoc.exists) {
            selectedPrimary = primaryDoc.data()
          }
        } else {
          // load selected registrant’s sub-registrations
          const subregistrantsRef = db
            .collection("registrants")
            .where("registrant_id", "==", selectedRegistrant.id)
          const subregistrantDocs = await subregistrantsRef.get()
          subregistrantDocs.forEach(subregistrantDoc => {
            selectedSubregistrants.push(subregistrantDoc.data())
          })
        }
      }
      setSelectedPrimary(selectedPrimary)
      setSelectedRegistrant(selectedRegistrant)
      setSelectedSubregistrants(selectedSubregistrants)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      setError(null)
      setSubmitting(true)
      const registrantIds = [
        selectedRegistrant.id,
        ...selectedSubregistrants.map(x => x.id)
      ]
      await onSubmit(registrantIds)
    } catch (error) {
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field label="Traveler">
        <RegistrantSelect
          placeholder="Search manifest..."
          tourId={tour.id}
          onChange={setSelectedId}
          noOptionsMessage={({ inputValue }) =>
            inputValue
              ? `No travelers found for “${inputValue}”`
              : "Enter a name or email to search travelers in the manifest"
          }
        />
      </Field>
      {loading ? (
        <small>Checking...</small>
      ) : alreadyMerged ? (
        <Alert
          error={`${selectedRegistrant.name_first} is already a part of this group.`}
        />
      ) : selectedPrimary ? (
        <small
          css={{
            color: theme.accentText,
            span: { position: "relative", top: -1 }
          }}>
          <Tag>Note</Tag>
          Adding {selectedRegistrant.name_first} to this group will remove them
          from{" "}
          <Link to={`/registrations/${selectedPrimary.id}`}>
            {displayFullName(selectedPrimary)}
          </Link>
          ’s group.
        </small>
      ) : selectedSubregistrants.length ? (
        <small
          css={{
            color: theme.accentText,
            span: { position: "relative", top: -1 }
          }}>
          <Tag>Note</Tag>
          Adding {selectedRegistrant.name_first}’s registration to this group
          will also add{" "}
          {selectedSubregistrants.map(x => displayFullName(x)).join(", ")} (
          {pluralize("sub-registrations", selectedSubregistrants.length)}) to
          this group.
        </small>
      ) : (
        <small>&nbsp;</small>
      )}
      <Button
        type="submit"
        kind="fill"
        size="large"
        disabled={
          !selectedRegistrant || submitting || loading || error || alreadyMerged
        }>
        {submitting
          ? "Submitting"
          : `Move ${pluralize(
              "traveler",
              1 + selectedSubregistrants.length
            )} to this group`}
      </Button>
      {error && <Alert error={error} />}
    </form>
  )
}
