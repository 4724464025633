/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import ReactDOM from "react-dom"
import * as Icon from "react-feather"
import { animated, useSpring } from "react-spring/web.cjs"
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock"
import { useTheme } from "../theme"

export default function Panel({
  backdrop = true,
  open = false,
  title,
  children,
  onClose
}) {
  const { theme } = useTheme()

  const scrollRef = React.useRef()
  React.useEffect(() => {
    if (open) {
      disableBodyScroll(scrollRef.current)
    } else {
      enableBodyScroll(scrollRef.current)
    }
    return () => {
      enableBodyScroll(scrollRef.current)
    }
  }, [open])

  const backdropStyle = useSpring({
    opacity: open ? 0.9 : 0,
    config: { duration: 100 }
  })

  const panelStyle = useSpring({
    transform: open ? "translateX(0%)" : "translateX(100%)",
    config: { duration: 100 }
  })

  return ReactDOM.createPortal(
    <>
      {backdrop && (
        <animated.div
          onClick={onClose}
          style={backdropStyle}
          css={{
            zIndex: 1000,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.backgroundColor,
            pointerEvents: open ? "auto" : "none"
          }}
        />
      )}
      <animated.div
        style={panelStyle}
        css={{
          zIndex: 1001,
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          width: "50vw",
          minWidth: "40rem",
          maxWidth: "50rem",
          background: theme.backgroundColor,
          borderLeft: "1px solid " + theme.borderColor,
          "@media (max-width: 50rem)": {
            minWidth: "auto",
            width: "100%"
          }
        }}>
        <div
          ref={scrollRef}
          css={{
            height: "100vh",
            padding: "2rem",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
            "@media (max-width: 50rem)": {
              paddingBottom: "50vh"
            }
          }}>
          <button onClick={onClose}>
            <Icon.X color={theme.primaryText} size="1.5rem" />
          </button>
          <div css={{ maxWidth: "36rem", margin: "0 auto" }}>{children}</div>
        </div>
      </animated.div>
    </>,
    document.body
  )
}
