/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import Alert from "../components/Alert"
import Button from "../components/Button"
import Input from "../components/Input"
import Loading from "../components/Loading"
import { db } from "../firebase"
import { useStore } from "../store"

export default function AdminUsersForm() {
  const { user, adminConfig } = useStore()

  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [adminEmails, setAdminEmails] = React.useState([])

  const [inputEmail, setInputEmail] = React.useState("")
  const [submitting, setSubmitting] = React.useState(false)

  React.useEffect(() => {
    loadAdmins()
  }, [])

  async function loadAdmins() {
    try {
      setError(null)
      setLoading(true)
      const adminEmails = []
      const adminsRef = db.collection("admins")
      const adminDocs = await adminsRef.get()
      adminDocs.forEach(adminDoc => {
        const email = adminDoc.id
        adminEmails.push(email)
      })
      setAdminEmails(adminEmails)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  async function addEmail(email) {
    try {
      setError(null)
      setSubmitting(true)
      if (adminEmails.includes(email)) {
        throw new Error("Email already exists")
      }
      await db.collection("admins").doc(email).set({
        algolia_api_key: adminConfig.algolia_api_key,
        send_daily_digest_email: false,
        send_weekly_digest_email: false,
        bcc_registration_emails: false,
        bcc_invoice_emails: false
      })
      setAdminEmails(emails => emails.concat(email))
      setInputEmail("")
    } catch (error) {
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  async function removeEmail(email) {
    try {
      setError(null)
      setSubmitting(true)
      await db.collection("admins").doc(email).delete()
      setAdminEmails(emails => emails.filter(x => x !== email))
    } catch (error) {
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error && !adminEmails.length) {
    return <Alert error={error} />
  }

  return (
    <ul css={{ margin: 0, padding: 0, listStyle: "none", maxWidth: "30rem" }}>
      {adminEmails.map(email => (
        <li
          key={email}
          css={{
            margin: "0.3rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <h5 css={{ margin: 0 }}>{email}</h5>
          &nbsp;
          {email !== user.email && (
            <Button
              size="small"
              kind="outline"
              disabled={submitting}
              onClick={() => removeEmail(email)}>
              Remove
            </Button>
          )}
        </li>
      ))}
      <form
        css={{
          display: "flex",
          alignItems: "stretch"
        }}
        onSubmit={async e => {
          e.preventDefault()
          await addEmail(inputEmail)
        }}>
        <Input
          type="email"
          name="email"
          value={inputEmail}
          onChange={e => {
            setError(null)
            setInputEmail(e.target.value)
          }}
        />
        <Button
          size="small"
          kind="outline"
          type="submit"
          css={{ marginLeft: "0.3rem" }}
          disabled={submitting || error}>
          Add
        </Button>
        {error && <Alert error={error} />}
      </form>
    </ul>
  )
}
