/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import storage from "store"
import { Redirect } from "react-router-dom"
import Loading from "../components/Loading"
import Button from "../components/Button"
import Alert from "../components/Alert"
import firebase from "../firebase"
import { useStore } from "../store"

export default function SignInConfirm() {
  const { authenticating, user } = useStore()

  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (!authenticating && !user) {
      signIn()
    }
  }, [authenticating, user])

  async function signIn() {
    setError(null)
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let email = storage.get("emailForSignIn")
      if (!email) {
        email = window.prompt("Please provide your email for confirmation")
      }
      try {
        await firebase.auth().signInWithEmailLink(email, window.location.href)
      } catch (error) {
        setError(error)
      } finally {
        storage.remove("emailforSignIn")
      }
    } else {
      setError("Signin link is invalid or malformed.")
    }
  }

  if (user) {
    return <Redirect to="/" />
  }

  if (error) {
    return (
      <div
        css={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}>
        <Alert error={error} />
        <br />
        <Button size="small" kind="outline" onClick={signIn}>
          Try Again
        </Button>
      </div>
    )
  }

  return <Loading />
}
