/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "../theme"

export default function Checkbox({ children, ...rest }) {
  const { theme } = useTheme()
  return (
    <label
      css={{
        width: "100%",
        display: "flex",
        cursor: "pointer",
        opacity: rest.disabled ? 0.5 : 1,
        "& + &": {
          marginTop: "0.5rem"
        }
      }}>
      <input
        {...rest}
        type="checkbox"
        css={{
          position: "absolute",
          opacity: 0.0000001
        }}
      />
      <div
        css={{
          flexGrow: 0,
          flexShrink: 0,
          position: "relative",
          height: "1.25rem",
          width: "1.25rem",
          borderRadius: 3,
          marginRight: "0.5rem",
          border: "1px solid " + theme.borderColor,
          backgroundColor: theme.backgroundColor,
          "::after": {
            position: "absolute",
            content: "''",
            display: "none",
            left: "0.3rem",
            top: "0rem",
            width: "0.5rem",
            height: "0.9rem",
            border: "solid " + theme.foregroundColor,
            borderWidth: "0 0.2rem 0.2rem 0",
            transform: "rotate(45deg)"
          },
          "input:active + &": {
            backgroundColor: theme.underlayColor
          },
          "input:checked + &": {
            backgroundColor: theme.accentText
          },
          "input:checked + &::after": {
            display: "block"
          }
        }}
      />
      <div>{children}</div>
    </label>
  )
}
