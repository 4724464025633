/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "../theme"

export default function Toggle({ children, ...rest }) {
  const { theme } = useTheme()

  return (
    <label
      css={{
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer"
      }}>
      <input
        {...rest}
        type="checkbox"
        css={{
          position: "absolute",
          visibility: "hidden"
        }}
      />
      <div
        css={{
          flexShrink: 0,
          position: "relative",
          display: "inline-block",
          width: "2.5rem",
          height: "1.5rem",
          marginRight: "0.5rem",
          borderRadius: "1rem",
          border: "1px solid " + theme.borderColor,
          "::after": {
            content: '""',
            top: 2,
            left: 2,
            position: "absolute",
            width: "calc(1.5rem - 6px)",
            height: "calc(1.5rem - 6px)",
            borderRadius: "50%",
            border: "1px solid " + theme.borderColor,
            background: theme.foregroundColor,
            transition: "transform 150ms",
            willChange: "transform"
          },
          "input:active + &": {
            background: theme.underlayColor
          },
          "input:checked + &": {
            background: theme.accentText
          },
          "input:checked + &::after": {
            transform: "translateX(1rem)"
          }
        }}
      />
      <div>{children}</div>
    </label>
  )
}
