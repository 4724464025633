/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "../theme"
import Tooltip from "./Tooltip"
import Alert from "./Alert"
import * as Icon from "react-feather"

export default function Field({ label, tooltip, children, error, ...rest }) {
  const { theme } = useTheme()
  return (
    <div css={{ display: "block", margin: "1rem 0" }} {...rest}>
      <h6
        css={{ marginTop: 0, marginBottom: "0.2rem", color: theme.accentText }}>
        {label}{" "}
        {tooltip ? (
          <Tooltip label={tooltip}>
            <span>
              <Icon.Info size="0.9em" />
            </span>
          </Tooltip>
        ) : null}
      </h6>
      <div>{children}</div>
      {error && <Alert error={error} />}
    </div>
  )
}
