/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import {
  Configure,
  connectInfiniteHits,
  connectSearchBox,
  InstantSearch
} from "react-instantsearch-dom"
import { Helmet } from "react-helmet"
import Button from "../components/Button"
import Input from "../components/Input"
import PanelRoute from "../components/PanelRoute"
import Select from "../components/Select"
import TourCard from "../components/TourCard"
import { db } from "../firebase"
import TourForm from "../forms/TourForm"
import useSyncSearchToURL from "../hooks/useSyncSearchToURL"
import { serializeDate } from "../utils/form"
import { useTheme } from "../theme"
import { useStore } from "../store"

const SearchBox = connectSearchBox(props => {
  useSyncSearchToURL(props.currentRefinement, props.refine)

  return (
    <Input
      {...props}
      value={props.currentRefinement}
      onChange={e => props.refine(e.target.value)}
    />
  )
})

const Hits = connectInfiniteHits(props => {
  return (
    <>
      {props.hits.length ? (
        <ul
          css={{
            margin: 0,
            padding: 0,
            listStyle: "none"
          }}>
          {props.hits.map(hit => (
            <li
              key={hit.id}
              css={{
                ":not(:hover) a": {
                  borderColor: "transparent"
                }
              }}>
              <TourCard tour={hit} />
            </li>
          ))}
        </ul>
      ) : (
        <p>No tours found.</p>
      )}
      {props.hasMore && (
        <Button size="large" kind="outline" onClick={() => props.refineNext()}>
          Load more
        </Button>
      )}
    </>
  )
})

export default function ToursBrowse() {
  const { searchClient } = useStore()
  const { theme } = useTheme()

  const [createdTour, setCreatedTour] = React.useState(null)

  const [status, setStatus] = React.useState("")

  let filters = []
  if (status) {
    filters.push(`inactive:${status}`)
  }

  return (
    <>
      <Helmet>
        <title>Tours | Faith Based Expeditions</title>
      </Helmet>
      <br />
      <header
        css={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between"
        }}>
        <h1 css={{ marginBottom: 0 }}>Tours</h1>
        <Button size="small" kind="fill" to={`/tours/new`}>
          Create Tour
        </Button>
      </header>
      <InstantSearch searchClient={searchClient} indexName="fbe_tours">
        <Configure filters={filters.join(" AND ")} />
        <header
          css={{
            zIndex: 1,
            position: "sticky",
            top: 0,
            padding: "1rem 0",
            display: "flex",
            alignItems: "center",
            background: theme.backgroundColor,
            "@media (max-width: 1000px)": {
              top: "3rem",
              padding: "0.5rem 0"
            }
          }}>
          &nbsp;
          <Select value={status} onChange={e => setStatus(e.target.value)}>
            <option value="">All</option>
            <option value="false">Active</option>
            <option value="true">Inactive</option>
          </Select>
          &nbsp;
          <SearchBox
            type="search"
            placeholder="Search name, church, or leader..."
          />
        </header>
        <ul css={{ margin: 0, padding: 0, listStyle: "none" }}>
          {createdTour && <TourCard tour={createdTour} />}
          <Hits />
        </ul>
      </InstantSearch>
      <PanelRoute path="/tours/new" backTo="/tours" title="New Tour">
        {({ close }) => (
          <>
            <Helmet>
              <title>Create Tour | Faith Based Expeditions</title>
            </Helmet>
            <h2>Create Tour</h2>
            <TourForm
              prompt="Create Tour"
              onSubmit={async (
                tourForm,
                offeringsForm,
                tourCostsForm,
                accessCodeForm
              ) => {
                const batch = db.batch()

                const tourRef = db.collection("tours").doc()
                const tourData = {
                  ...tourForm,
                  id: tourRef.id,
                  inactive: false,
                  created: Date.now(),
                  modified: null,
                  tickets_sold: 0
                }
                batch.set(tourRef, tourData)

                offeringsForm.forEach(offeringForm => {
                  const offeringRef = tourRef.collection("offerings").doc()
                  const offeringData = {
                    ...offeringForm,
                    id: offeringRef.id,
                    tour_id: tourRef.id,
                    created: Date.now(),
                    modified: null
                  }
                  batch.set(offeringRef, offeringData)
                })

                const tourCostsRef = db.collection("tour_costs").doc(tourRef.id)
                const tourCostsData = {
                  ...tourCostsForm,
                  id: tourRef.id,
                  tour_id: tourRef.id,
                  created: Date.now(),
                  modified: null
                }
                batch.set(tourCostsRef, tourCostsData)

                const freeAccessCodeRef = db
                  .collection("access_codes")
                  .doc(tourRef.id)
                batch.set(freeAccessCodeRef, accessCodeForm)

                await batch.commit()

                setCreatedTour(tourData)
                close()
              }}
            />
          </>
        )}
      </PanelRoute>
    </>
  )
}
