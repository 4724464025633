const { displayAge } = require("./format")

exports.getInsuranceData = (
  registrant = {}, // registrant object
  subtotal = 0, // subtotal on invoice prior to insurance and processing fees
  insurance // insurance object or null
) => {
  if (insurance && subtotal > 0) {
    if (insurance.levels) {
      const registrantAge = Math.min(120, displayAge(registrant))
      const insuranceLevel = insurance.levels.find(
        x =>
          x.min <= subtotal &&
          x.max >= subtotal &&
          x.age_min <= registrantAge &&
          x.age_max >= registrantAge
      )
      if (insuranceLevel) {
        return {
          price: insuranceLevel.price,
          cost: insuranceLevel.cost
        }
      } else {
        console.error(
          `Insurance not found for registrant (age ${registrantAge}, subtotal ${subtotal}).`,
          registrant
        )
      }
    } else if (typeof insurance.percentage === "number") {
      return {
        cost: Math.round(subtotal * insurance.percentage), // insurance revenue is passed on, so this zeroes out the calculated profit
        price: Math.round(subtotal * insurance.percentage)
      }
    }
  }

  return { price: 0, cost: 0 }
}
