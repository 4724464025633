/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import Button from "../components/Button"
import { functions } from "../firebase"
import downloadManifest from "../download/downloadManifest"

export default function DownloadManifestButton({
  tourCode,
  accessCode,
  onError,
  onSuccess,
  ...rest
}) {
  const [loading, setLoading] = React.useState(false)
  return (
    <Button
      size="small"
      kind="outline"
      {...rest}
      onClick={async () => {
        try {
          setLoading(true)
          let getManifestData = functions.httpsCallable("getManifestData")
          let result = await getManifestData({
            tour_code: tourCode,
            access_code: accessCode
          })
          await downloadManifest(result.data.tour, result.data.registrants)
          if (onSuccess) {
            onSuccess()
          }
        } catch (error) {
          if (onError) {
            onError(error)
          } else {
            window.alert(error)
          }
        } finally {
          setLoading(false)
        }
      }}>
      {loading ? "Generating..." : "Download Manifest"}
    </Button>
  )
}
