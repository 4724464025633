/** @jsx jsx */
import { jsx } from "@emotion/core"
import pluralize from "pluralize"
import moment from "moment"
import React from "react"
import * as Icon from "react-feather"
import { RED } from "../colors"
import Alert from "../components/Alert"
import Avatar from "../components/Avatar"
import Button from "../components/Button"
import Field from "../components/Field"
import Tooltip from "../components/Tooltip"
import TourSelect from "../components/TourSelect"
import { db } from "../firebase"
import { useTheme } from "../theme"
import {
  displayDateRange,
  displayFullName,
  displayMoney
} from "../utils/format"

export default function SwitchTourForm({
  registrants,
  tour,
  extensions,
  onSubmit
}) {
  const { theme } = useTheme()

  const [insurances, setInsurances] = React.useState([])
  const [switchTourId, setSwitchTourId] = React.useState(null)
  const [switchTour, setSwitchTour] = React.useState(null)
  const [switchInsurance, setSwitchInsurance] = React.useState(null)
  const [switchOfferings, setSwitchOfferings] = React.useState([])
  const [switchExtensions, setSwitchExtensions] = React.useState([])

  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)

  React.useEffect(() => {
    loadSwitchData(switchTourId)
  }, [switchTourId])

  async function loadSwitchData(switchTourId) {
    try {
      setLoading(true)
      setError(null)
      setInsurances(null)
      setSwitchTour(null)
      setSwitchOfferings([])
      setSwitchExtensions([])
      let insurances = []
      let switchTour = null
      let switchInsurance = null
      let switchOfferings = []
      let switchExtensions = []
      if (switchTourId) {
        const tourRef = db.collection("tours").doc(switchTourId)
        const tourDoc = await tourRef.get()
        switchTour = tourDoc.data()

        const insurancesRef = db.collection("insurances")
        const insuranceDocs = await insurancesRef.get()
        insuranceDocs.forEach(insuranceDoc => {
          const insuranceData = insuranceDoc.data()
          if (switchTour.insurance_id == insuranceDoc.id) {
            switchInsurance = insuranceData
          }
          insurances.push(insuranceData)
        })

        const offeringDocs = await tourRef.collection("offerings").get()
        offeringDocs.forEach(offeringDoc => {
          switchOfferings.push(offeringDoc.data())
        })

        const extensionDocs = await tourRef.collection("extensions").get()
        extensionDocs.forEach(extensionDoc => {
          switchExtensions.push(extensionDoc.data())
        })
      }
      setInsurances(insurances)
      setSwitchTour(switchTour)
      setSwitchInsurance(switchInsurance)
      setSwitchOfferings(switchOfferings)
      setSwitchExtensions(switchExtensions)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const switchTourUpcomingOfferings = switchOfferings.filter(x => {
    return moment.utc(x.tour_start_date).isAfter()
  })

  const switchTourUpcomingExtensions = switchExtensions.filter(x => {
    return !x.inactive && moment.utc(x.start_date).isAfter()
  })

  const isValid = !switchTour?.inactive && !!switchTourUpcomingOfferings.length

  const registrationChanges = React.useMemo(() => {
    if (
      registrants &&
      switchTour &&
      switchTourUpcomingOfferings &&
      switchTourUpcomingExtensions
    ) {
      return registrants.map(registrant => {
        let nextOffering
        if (switchTourUpcomingOfferings.length) {
          nextOffering = switchTourUpcomingOfferings[0]
        }

        let nextAirtravel =
          registrant.airtravel_yes && nextOffering?.air_price > 0

        let nextExtension
        if (switchTourUpcomingExtensions.length && registrant.extension_id) {
          let prevExtension = extensions.find(
            x => x.id == registrant.extension_id
          )
          if (prevExtension) {
            nextExtension = switchTourUpcomingExtensions.find(
              x => (x.timing = prevExtension.timing)
            )
          }
        }

        return {
          registrant,
          changes: {
            airtravel_yes: nextAirtravel,
            tour_id: switchTour.id,
            offering_id: nextOffering?.id,
            tour_start_date: nextOffering?.tour_start_date,
            tour_end_date: nextOffering?.tour_end_date,
            tour_departure: nextOffering?.tour_departure,
            tour_price: nextAirtravel
              ? nextOffering?.air_price
              : nextOffering?.land_price,
            extension_id: nextExtension?.id || null,
            extension_name: nextExtension?.title || null,
            extension_price: nextExtension?.price || 0
          }
        }
      })
    }
  }, [
    registrants,
    switchTour,
    switchTourUpcomingOfferings,
    switchTourUpcomingExtensions
  ])

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      setError(null)
      setSubmitting(true)
      await onSubmit(switchTourId, registrationChanges)
    } catch (error) {
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field label="New Tour">
        <TourSelect
          placeholder="Search tour name, church, leader, ..."
          onChange={setSwitchTourId}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? `No tour found for “${inputValue}”` : "Search tours"
          }
        />
      </Field>
      {loading ? (
        <p>Loading...</p>
      ) : !switchTour ? (
        <p>
          Use the field above to search for a new tour. All changes to traveler
          itinerary will be displayed below for confirmation.
        </p>
      ) : switchTour.id == tour.id ? (
        <p>
          {pluralize("Registrations", registrants.length)} already part of this
          tour. Please select another tour.
        </p>
      ) : switchTour.inactive ? (
        <p>This tour is inactive. Please select another tour.</p>
      ) : switchTourUpcomingOfferings.length < 1 ? (
        <p>
          No upcoming offerings for this tour found. Please select another tour.
        </p>
      ) : registrationChanges ? (
        <div>
          <p>
            Here are the changes that will be applied to each registration.
            Please review these changes before confirming the tour change.
          </p>
          {registrationChanges.map(({ registrant, changes }) => (
            <div
              key={registrant.id}
              css={{
                margin: "1rem 0",
                padding: "1rem",
                borderRadius: 3,
                border: `1px solid ${theme.borderColor}`,
                p: {
                  margin: "0.5rem 0",
                  fontSize: "0.875rem"
                }
              }}>
              <header
                css={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem"
                }}>
                <Avatar user={registrant} />
                <div css={{ paddingLeft: "1rem" }}>
                  <h5 css={{ margin: 0 }}>{displayFullName(registrant)}</h5>
                  <small>{registrant.email}</small>
                </div>
              </header>
              {registrant.airtravel_yes != changes.airtravel_yes ? (
                <p>
                  Travel change:{" "}
                  <Strike>{registrant.airtravel_yes ? "Air" : "Land"}</Strike>{" "}
                  <Highlight>
                    {changes.airtravel_yes ? "Air" : "Land"}
                  </Highlight>{" "}
                  {!changes.airtravel_yes && (
                    <Tooltip
                      label={`No air travel option found for ${switchTour.title}`}>
                      <span>
                        <Icon.Info size="0.9em" />
                      </span>
                    </Tooltip>
                  )}
                </p>
              ) : null}
              {changes.airtravel_yes &&
              registrant.tour_departure != changes.tour_departure ? (
                <p>
                  Departure change: <Strike>{registrant.tour_departure}</Strike>{" "}
                  <Highlight>{changes.tour_departure}</Highlight>{" "}
                  <Tooltip
                    label={`This is the group air departure location for ${switchTour.title}`}>
                    <span>
                      <Icon.Info size="0.9em" />
                    </span>
                  </Tooltip>
                </p>
              ) : null}
              {tour.tour_leaders.join(", ") !==
              switchTour.tour_leaders.join(", ") ? (
                <p>
                  Leader change: <Strike>{tour.tour_leaders.join(", ")}</Strike>{" "}
                  <Highlight>{switchTour.tour_leaders.join(", ")}</Highlight>{" "}
                  <Tooltip
                    label={
                      switchTour.tour_leaders.length > 1
                        ? `These are the tour leaders for ${switchTour.title}`
                        : `${switchTour.tour_leaders[0]} is the tour leader for ${switchTour.title}`
                    }>
                    <span>
                      <Icon.Info size="0.9em" />
                    </span>
                  </Tooltip>
                </p>
              ) : null}
              {registrant.tour_start_date != changes.tour_start_date ||
              registrant.tour_end_date != changes.tour_end_date ? (
                <p>
                  Date change:{" "}
                  <Strike>
                    {displayDateRange(
                      registrant.tour_start_date,
                      registrant.tour_end_date
                    )}
                  </Strike>{" "}
                  <Highlight>
                    {displayDateRange(
                      changes.tour_start_date,
                      changes.tour_end_date
                    )}
                  </Highlight>{" "}
                  <Tooltip
                    label={`${displayDateRange(
                      changes.tour_start_date,
                      changes.tour_end_date
                    )} are the start and end date for ${switchTour.title}`}>
                    <span>
                      <Icon.Info size="0.9em" />
                    </span>
                  </Tooltip>
                </p>
              ) : null}
              {!registrant.tour_leader_yes &&
              registrant.tour_price != changes.tour_price ? (
                <p>
                  Price change:{" "}
                  <Strike>{displayMoney(registrant.tour_price)}</Strike>{" "}
                  <Highlight>{displayMoney(changes.tour_price)}</Highlight>{" "}
                  <Tooltip
                    label={`${displayMoney(
                      changes.tour_price
                    )} is the price of ${
                      changes.airtravel_yes ? "air" : "land"
                    } travel for ${switchTour.title}`}>
                    <span>
                      <Icon.Info size="0.9em" />
                    </span>
                  </Tooltip>
                </p>
              ) : null}
              {registrant.extension_id != changes.extension_id ? (
                <p>
                  Extension change:{" "}
                  <Strike>
                    {registrant.extension_name} –{" "}
                    {displayMoney(registrant.extension_price)}
                  </Strike>{" "}
                  {changes.extension_id ? (
                    <Highlight>
                      {changes.extension_name} –{" "}
                      {displayMoney(changes.extension_price)}
                    </Highlight>
                  ) : (
                    <Highlight>No extension</Highlight>
                  )}{" "}
                  <Tooltip
                    label={
                      changes.extension_id
                        ? `Closest extension match for ${switchTour.title}`
                        : `No matching extension found for ${switchTour.title}`
                    }>
                    <span>
                      <Icon.Info size="0.9em" />
                    </span>
                  </Tooltip>
                </p>
              ) : null}
              {registrant.insurance_yes &&
              tour.insurance_id !== switchTour.insurance_id ? (
                <p>
                  Insurance change:{" "}
                  <Strike>
                    {insurances.find(x => x.id == tour.insurance_id)?.name ||
                      "Not included"}
                  </Strike>{" "}
                  <Highlight>
                    {switchInsurance?.name || "Not included"}
                  </Highlight>{" "}
                  <Tooltip
                    label={
                      switchInsurance
                        ? `${switchInsurance?.name} is insurance pricing group for ${switchTour.title}`
                        : `Insurance is not provided through FBE for this tour.`
                    }>
                    <span>
                      <Icon.Info size="0.9em" />
                    </span>
                  </Tooltip>
                </p>
              ) : null}
            </div>
          ))}
          <Button
            type="submit"
            kind="fill"
            size="large"
            disabled={submitting || loading || error || !isValid}>
            {submitting ? "Submitting" : "Confirm tour change"}
          </Button>
        </div>
      ) : null}
      {error && <Alert error={error} />}
    </form>
  )
}

function Strike(props) {
  return (
    <span css={{ color: RED, textDecoration: "line-through" }} {...props} />
  )
}

function Highlight(props) {
  const { theme } = useTheme()
  return <span css={{ color: theme.accentText }} {...props} />
}
