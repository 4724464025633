/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "../theme"
import Button from "../components/Button"
import { Helmet } from "react-helmet"

export default function NotFound() {
  const { theme } = useTheme()

  return (
    <>
      <Helmet>
        <title>Not Found | Faith Based Expeditions</title>
      </Helmet>
      <div
        css={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${theme.patternImage})`
        }}>
        <div css={{ maxWidth: "28rem", textAlign: "center" }}>
          <h1 css={{ margin: 0, fontSize: "6rem" }}>404</h1>
          <h5 css={{ margin: 0, fontSize: "1.5rem" }}>
            Oops, This Page Not Found!
          </h5>
          <p>
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable.
          </p>
          <br />
          <Button to="/" size="small" kind="fill">
            Go To Home
          </Button>
        </div>
      </div>
    </>
  )
}
