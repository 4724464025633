const moment = require("moment")
const { getInsuranceData } = require("./getInsuranceData")

exports.getInvoiceData = (
  registrant,
  tour,
  insurance,
  extensions,
  addons = [], // optional, in order to calculate invoice totals including addons
  payments = [] // optional, in order to calculate lateFees, totalPaid, totalRemaining
) => {
  let tourTotal = 0
  if (
    !registrant.inactive &&
    !registrant.tour_leader_yes &&
    registrant.tour_price
  ) {
    tourTotal = registrant.tour_price
  }

  let extensionTotal = 0
  if (
    !registrant.inactive &&
    !registrant.tour_leader_yes &&
    registrant.extension_id &&
    registrant.extension_price
  ) {
    extensionTotal = registrant.extension_price
  }

  let supplementTotal = 0
  if (
    !registrant.inactive &&
    !registrant.tour_leader_yes &&
    registrant.supplement_yes
  ) {
    supplementTotal += tour.supplement_price
    if (registrant.extension_id) {
      const extension = extensions.find(x => x.id == registrant.extension_id)
      if (extension) {
        supplementTotal += extension.supplement_price
      }
    }
  }

  let depositTotal = 0
  if (!registrant.inactive) {
    if (tour.deposit_amount) {
      depositTotal = tour.deposit_amount
    }
    if (registrant.extension_id) {
      const extension = extensions.find(x => x.id == registrant.extension_id)
      if (extension && extension.deposit_amount) {
        depositTotal += extension.deposit_amount
      }
    }
  }

  let addonsTotal = 0
  let insuredAddonsCharge = 0 // track this separately since not all addons may be included in insurance costs
  if (!registrant.inactive && addons.length) {
    addons.forEach(addon => {
      if (addon.registrant_id == registrant.id) {
        addonsTotal += addon.price
        if (tour.insurance_id && addon.insurance_yes) {
          insuredAddonsCharge += addon.price
        }
      }
    })
  }

  let earlyDiscount = 0
  if (
    !registrant.inactive &&
    !registrant.tour_leader_yes &&
    tour.early_registration_discount_amount
  ) {
    // Fallback to current date so user can preview invoice before actual registration
    let registrationDate = registrant.created || Date.now()
    if (registrationDate < tour.early_registration_end_date) {
      earlyDiscount += tour.early_registration_discount_amount
    }
  }

  let subtotalDue =
    tourTotal + extensionTotal + supplementTotal + addonsTotal - earlyDiscount

  const insuredSubtotal =
    tourTotal +
    extensionTotal +
    supplementTotal +
    insuredAddonsCharge -
    earlyDiscount

  let insuranceTotal = 0
  if (!registrant.inactive && registrant.insurance_yes) {
    if (tour.insurance_price) {
      insuranceTotal = tour.insurance_price
    } else {
      const insuranceData = getInsuranceData(
        registrant,
        insuredSubtotal,
        insurance
      )
      insuranceTotal = insuranceData.price
    }
  }

  let processingFee = 0
  if (registrant.processing_fee) {
    processingFee = registrant.processing_fee
  }

  let totalPaid = 0
  payments.forEach(payment => {
    if (payment.registrant_id == registrant.id) {
      totalPaid += payment.amount
    }
  })

  let totalDue = subtotalDue + insuranceTotal + processingFee

  let totalRemaining = totalDue - totalPaid

  let dueDate = moment
    .utc(registrant.tour_start_date)
    .subtract(100, "days") // due 100 days before start date
    .valueOf()

  let lateFee = 0
  // if (
  //   !registrant.inactive &&
  //   // add 7-day grace period
  //   moment.utc(dueDate).add(7, "days").isAfter("01/20/2020") &&
  //   // dont automatically apply late fee to invoices due before 2.0 launch date.
  //   payments.find(payment => {
  //     return (
  //       payment.registrant_id == registrant.id &&
  //       moment.utc(payment.created).isAfter(dueDate)
  //     )
  //   })
  // ) {
  //   lateFee = 9500
  //   totalDue += lateFee
  //   totalRemaining += lateFee
  // }

  return {
    tourTotal,
    extensionTotal,
    supplementTotal,
    depositTotal,
    addonsTotal,
    earlyDiscount,
    subtotalDue,
    insuredSubtotal,
    insuranceTotal,
    processingFee,
    lateFee,
    totalDue,
    totalPaid,
    totalRemaining,
    dueDate
  }
}
