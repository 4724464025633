/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"

export default function Loading(props) {
  return (
    <div
      css={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
      {...props}>
      <p>Loading...</p>
    </div>
  )
}
