import React from "react"
import CreatableSelect from "react-select/creatable"
import { db } from "../firebase"
import { useTheme } from "../theme"
import { getReactSelectStyles } from "./Styles"

export default function DepartureSelect({ name, required, ...props }) {
  const { theme } = useTheme()

  const [loading, setLoading] = React.useState(true)
  const [options, setOptions] = React.useState([])
  const [value, setValue] = React.useState(null)

  React.useEffect(() => {
    loadOptions()
  }, [])

  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(value ? value.name : null)
    }
  }, [value])

  async function loadOptions() {
    try {
      setLoading(true)
      const leaders = []
      const leadersRef = db.collection("tour_leaders")
      const leaderDocs = await leadersRef.get()
      leaderDocs.forEach(leaderDoc => {
        const leaderData = leaderDoc.data()
        const leaderValue = { value: leaderData.name, label: leaderData.name }
        leaders.push(leaderValue)
      })
      setOptions(leaders)
      if (props.defaultValue) {
        const leaderValue = leaders.find(x => x.value == props.defaultValue)
        setValue(leaderValue)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function createOption(inputValue) {
    // prettier-ignore
    if (!window.confirm(`Are you sure you want to create a new tour leader “${inputValue}”?`)) { 
      return
    }

    try {
      setLoading(true)
      const leaderRef = db.collection("tour_leaders").doc()
      const leaderData = {
        id: leaderRef.id,
        name: inputValue,
        created: Date.now(),
        modified: null
      }
      await leaderRef.set(leaderData)
      const leaderValue = { value: leaderData.name, label: leaderData.name }
      setOptions(options => options.concat(leaderValue))
      setValue(leaderValue)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <CreatableSelect
        {...props}
        isClearable
        isDisabled={loading}
        isLoading={loading}
        options={options}
        value={value}
        onChange={setValue}
        onCreateOption={createOption}
        {...getReactSelectStyles(theme)}
      />
      <input
        name={name}
        required={required}
        tabIndex={-1}
        autoComplete="off"
        style={{ position: "absolute", opacity: 0, height: 0 }}
        value={value && value.value}
      />
    </>
  )
}
