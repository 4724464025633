import React from "react"
import Field from "./Field"
import Input from "./Input"

export default function PasswordInput(props) {
  return (
    <Field label="Password">
      <Input required name="password" type="password" {...props} />
      <small>
        Please choose a password so you can login and access your invoice and
        payment details with {props?.email}.
      </small>
    </Field>
  )
}
