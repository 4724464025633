import algoliasearch from "algoliasearch/lite"
import React from "react"
import firebase from "./firebase"

export const StoreContext = React.createContext({})

export function useStore() {
  return React.useContext(StoreContext)
}

export default function Store({ children }) {
  const [authenticating, setAuthenticating] = React.useState(true)
  const [user, setUser] = React.useState(null)
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [adminConfig, setAdminConfig] = React.useState(null)

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(async user => {
      if (!user) {
        setUser(null)
        setIsAdmin(false)
        setAdminConfig(null)
        setAuthenticating(false)
      } else {
        const adminQuery = await firebase
          .firestore()
          .collection("admins")
          .doc(user.email)
          .get()
        setUser(user)
        setIsAdmin(adminQuery.exists)
        setAdminConfig(adminQuery.data())
        setAuthenticating(false)
      }
    })
  }, [])

  const reloadUser = async () => {
    if (user) {
      await firebase.auth().currentUser.reload()
      const userReloaded = firebase.auth().currentUser
      setUser(userReloaded)
      return userReloaded
    }
  }

  const searchClient = React.useMemo(() => {
    if (adminConfig) {
      return algoliasearch(
        process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        adminConfig.algolia_api_key
      )
    }
  }, [adminConfig])

  return (
    <StoreContext.Provider
      value={{
        authenticating,
        user,
        isAdmin,
        adminConfig,
        setAdminConfig,
        searchClient,
        reloadUser
      }}>
      {children}
    </StoreContext.Provider>
  )
}
