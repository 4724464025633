/** @jsx jsx */
import { jsx } from "@emotion/core"
import moment from "moment"
import range from "lodash/range"
import React from "react"
import Select from "./Select"

const nextYear = new Date().getFullYear() + 1

export default function DatePicker({
  name,
  value,
  onChange,
  required,
  maxYear = nextYear,
  ...rest
}) {
  const options = React.useMemo(() => {
    return {
      months: range(1, 13).map(x => String(x).padStart(2, "0")),
      days: range(1, 32).map(x => String(x).padStart(2, "0")),
      years: range(maxYear, 1900)
    }
  }, [maxYear])

  const [month, setMonth] = React.useState(null)
  const [day, setDay] = React.useState(null)
  const [year, setYear] = React.useState(null)

  React.useEffect(() => {
    const m = moment.utc(value)
    if (m.isValid()) {
      setMonth(m.format("MM"))
      setDay(m.format("DD"))
      setYear(m.format("YYYY"))
    }
  }, [value])

  let date = ""
  if (month && day && year) {
    date = `${month}/${day}/${year}`
  }

  React.useEffect(() => {
    onChange(date)
  }, [date])

  return (
    <div css={{ display: "flex" }} {...rest}>
      <Select value={month} onChange={e => setMonth(e.target.value)}>
        <option disabled selected>
          MM
        </option>
        {options.months.map(x => (
          <option key={x}>{x}</option>
        ))}
      </Select>
      &nbsp;
      <Select value={day} onChange={e => setDay(e.target.value)}>
        <option disabled selected>
          DD
        </option>
        {options.days.map(x => (
          <option key={x}>{x}</option>
        ))}
      </Select>
      &nbsp;
      <Select value={year} onChange={e => setYear(e.target.value)}>
        <option disabled selected>
          YYYY
        </option>
        {options.years.map(x => (
          <option key={x}>{x}</option>
        ))}
      </Select>
      <input
        readOnly
        name={name}
        value={date}
        required={required}
        css={{ opacity: 0, position: "absolute", pointerEvents: "none" }}
      />
    </div>
  )
}
