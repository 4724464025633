/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import Input from "./Input"
import DayPickerInput from "react-day-picker/DayPickerInput"
import { formatDate, parseDate } from "react-day-picker/moment"
import "react-day-picker/lib/style.css"

const currentYear = new Date().getFullYear()
const fromMonth = new Date(currentYear - 120, 0)
const toMonth = new Date(currentYear + 10, 11)

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths()

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  function handleChange(e) {
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  )
}

export default React.forwardRef((props, ref) => {
  const [value, setValue] = React.useState(props.value)

  return (
    <DayPickerInput
      {...props}
      ref={ref}
      format="YYYY-M-D"
      component={ClassInput}
      onDayChange={props.onChange}
      formatDate={formatDate}
      parseDate={parseDate}
      dayPickerProps={
        props.dayPickerProps || {
          month: value,
          toMonth: toMonth,
          fromMonth: fromMonth,
          captionElement: ({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={setValue}
            />
          )
        }
      }
      value={value}
    />
  )
})

class ClassInput extends React.Component {
  ref = React.createRef()

  focus() {
    this.ref.current.focus()
  }

  render() {
    return <Input ref={this.ref} {...this.props} type="text" />
  }
}
