const numeral = require("numeral")
const phone = require("phone-formatter")
const moment = require("moment")

exports.serializeDate = str => {
  if (!str) return null
  const date = moment.utc(str)
  if (!date.isValid()) return null
  return date.valueOf()
}

exports.serializeNumber = str => {
  const num = numeral(str).value()
  return num || 0
}

exports.deserializeDate = num => {
  if (num) return moment.utc(num).format("YYYY-MM-DD")
  return ""
}

exports.serializeMoney = str => {
  const num = numeral(str).value()
  if (!num) return 0
  return parseInt((num * 100).toFixed(0))
}

exports.serializePhone = str => {
  return phone.normalize(str)
}

exports.deserializeMoney = num => {
  if (num) return num / 100
  return 0
}
