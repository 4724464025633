/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { useTheme } from "../theme"

export default function Logo(props) {
  const { theme } = useTheme()

  return (
    <div
      css={{
        width: "4rem",
        padding: "1rem 0.5rem",
        borderRadius: "2rem",
        border: `1px solid ${theme.borderColor}`,
        backgroundColor: theme.backgroundColor
      }}
      {...props}>
      <img
        src={theme.iconImage}
        css={{
          height: "auto",
          width: "100%"
        }}
      />
    </div>
  )
}
