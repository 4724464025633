/** @jsx jsx */
import { jsx } from "@emotion/core"
import moment from "moment"
import * as Icon from "react-feather"
import { Link } from "react-router-dom"
import Avatar from "./Avatar"
import {
  displayDateRange,
  displayFullName,
  displayPhone
} from "../utils/format"
import { useTheme } from "../theme"
import Tag from "./Tag"
import TourImage from "./TourImage"

export default function RegistrantCard({ registrant, tour }) {
  const { theme } = useTheme()

  return (
    <div css={{ position: "relative" }}>
      <Link
        to={`/registrations/${registrant.registrant_id || registrant.id}`}
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 2,
          border: `1px solid ${theme.borderColor}`
        }}
      />
      <div
        css={{
          display: "flex",
          alignItems: "center",
          h5: {
            margin: 0,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          },
          "> div": {
            flexGrow: 0,
            flexShrink: 0,
            padding: "0.5rem"
          }
        }}>
        <div>
          <Avatar user={registrant} size={36} />
        </div>
        <div css={{ width: 350, ":last-child": { width: "100%" } }}>
          <h5 className="num-lines-1" css={{ margin: 0 }}>
            {displayFullName(registrant)}
          </h5>
          <small className="num-lines-1">
            <Tag disabled={registrant.inactive}>
              {registrant.inactive ? "Inactive" : "Active"}
            </Tag>
            &nbsp;
            {registrant.email} / {displayPhone(registrant.phone)}
          </small>
        </div>
        {tour && (
          <div
            css={{
              width: 1,
              flex: "1 !important",
              display: "flex",
              alignItems: "center",
              "@media (max-width: 800px)": {
                display: "none"
              }
            }}>
            <TourImage
              tour={tour}
              css={{
                height: 36,
                display: "block",
                width: "auto",
                marginRight: "0.5rem",
                borderRadius: 3
              }}
            />
            <div css={{ width: 1, flex: 1 }}>
              <h5 className="num-lines-1">
                {tour ? tour.title : "[Tour not found]"}
              </h5>
              <small className="num-lines-1">
                {registrant.tour_start_date && registrant.tour_end_date && (
                  <span>
                    {displayDateRange(
                      registrant.tour_start_date,
                      registrant.tour_end_date
                    )}
                  </span>
                )}
                &nbsp; &nbsp;
                {registrant.tour_departure && (
                  <span>{registrant.tour_departure}</span>
                )}
              </small>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
