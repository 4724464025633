/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import "react-day-picker/lib/style.css"
import MaskInput from "react-text-mask"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import { useTheme } from "../theme"

const Input = React.forwardRef((props, ref) => {
  const { size, mask, ...rest } = props

  const { theme } = useTheme()

  const styles = {
    width: "100%",
    height: size === "large" ? "3rem" : "2.2rem",
    padding: size === "large" ? "0.5rem" : "0.5rem",
    fontSize: size === "large" ? "1.25rem" : "1rem",
    lineHeight: "1.1rem",
    display: "block",
    borderRadius: 3,
    fontFamily: "inherit",
    border: "1px solid " + theme.borderColor,
    appearance: "none",
    backgroundColor: theme.foregroundColor,
    color: theme.primaryText,
    caretColor: theme.accentText,
    ":disabled": {
      backgroundColor: theme.underlayColor
    },
    ":focus": {
      borderColor: theme.accentText
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: `0 0 0 30px ${theme.foregroundColor} inset !important`,
      WebkitTextFillColor: `${theme.primaryText} !important`,
      border: `1px solid ${theme.borderColor} !important`
    },
    "::placeholder": {
      color: theme.hintText
    },
    "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0
    },
    "[type=number]": {
      MozAppearance: "textfield"
    }
  }

  if (mask) {
    return (
      <MaskInput ref={ref} css={styles} mask={mask} guide={false} {...rest} />
    )
  }

  if (rest.type === "date") {
    return (
      <input
        ref={ref}
        css={styles}
        data-date-format="mm/dd/yyyy"
        placeholder="mm/dd/yyyy"
        {...rest}
      />
    )
  }

  return <input ref={ref} css={styles} {...rest} />
})

Input.phoneMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] // prettier-ignore

Input.currencyMask = createNumberMask({
  allowDecimal: true,
  allowNegative: true
})

export default Input
